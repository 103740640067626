import { destroy, reset } from "redux-form";
import { api } from "../api";
import localize, { LocKeys } from "../constants/localizations";
import { lodSpecificationConstants } from "../constants/lodSpecificationConstants";
import orderConstants from "../constants/orderConstants";
import { getItemFromLocalStorage, getToken } from "../utils/local-storage.util";
import { refreshToken } from "./authActions";
import { getPagination } from "../utils/table-filter";

/**
 * Create LOD specification
 *
 * SUCCESS:
 * 		1. getLodSpecifications
 * 		2. lodSpecificationActionSuccess && setLodSpecificationSuccessMessage
 * 		3. clearTempLodSpecification
 *
 * @param {*} data - Contains all lod specification form data in a key-value pair
 */
export const createLodSpecification = (
	data,
	cb = () => {},
	isForOfferCreate = false
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/lod-specification`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				if (!isForOfferCreate) {
					dispatch(
						setLodSpecificationSuccessMessage(
							localize(LocKeys.ITEM_CREATED, [
								localize(LocKeys.LOD_SPECIFICATION),
							])
						)
					);

					dispatch(lodSpecificationActionSuccess());
					dispatch(getLodSpecifications({}));
				}

				dispatch(clearTempLodSpecification());
				cb(res.data.result);
			},
			(err) => {
				dispatch(
					lodSpecificationActionFailure(
						localize(LocKeys.ERROR_ITEM_CREATED, [
							localize(LocKeys.LOD_SPECIFICATION),
						])
					)
				);
			}
		);
	};
};

/**
 * Retrieves all LOD specifications
 *
 * SUCCESS:
 *    Populates lodSpecificationList in the lodSpecification state
 *
 * @param {boolean} usePagination
 * @param {number} page
 * @param {string} search
 * @param {number} customer
 * @param {boolean} filterPublic = false
 * @param {number} limit
 * @param {Array<number>} ids = []
 * @returns
 */

export const getLodSpecifications = ({
	usePagination = true,
	filterPublic = false,
	page = 1,
	search = "",
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
	limit,
	ids = [],
	clearSingleLodSpecification = true,
}) => {
	return (dispatch, getState) => {
		const state = getState();

		const tableFilters = state.getIn(["form", "tableFilters", "values"]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});

		let data = {
			page: usePagination ? currentPage : page,
			search: search,
			filterPublic,
			limit: usePagination ? currentLimit : limit,
			usePagination,
			selectedCompany: customer,
			resellerCompanyId,
		};

		if (!search && usePagination) {
			data.search = tableFilters && tableFilters.get("table-search");
		}

		if (ids?.length > 0) {
			data["ids"] = ids;
		}
		const requestOptions = {
			method: "PUT",
			url: `/lod-specification`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: lodSpecificationConstants.GET_LOD_SPECIFICATIONS,
					data: res.data,
				});

				if (clearSingleLodSpecification) {
					dispatch(clearCurrentLodSpecification(null));
					dispatch(clearTempLodSpecification(null));
				}
				dispatch(refreshToken());
			},
			(err) => {
				dispatch(lodSpecificationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves single lod specification based on its ID.
 *
 * @param {*} id - Unique lod specification identifier
 * @param {*} cb - callback function
 * @returns
 */
export const getLodSpecification = ({
	id,
	cb = () => {},
	offerView = false,
	permission = false,
	useForPdf = false,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/lod-specification/${id}${permission ? `?permission=true` : ``}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!offerView) {
					dispatch({
						type: orderConstants.GET_PROJECT_LOD_SPECIFICATION,
						data: res.data,
					});
				} else {
					dispatch({
						type: lodSpecificationConstants.GET_LOD_SPECIFICATION,
						data: res.data,
						useForPdf,
					});
				}
			},
			(err) => {
				cb && cb();
				dispatch(lodSpecificationActionFailure(err.response.data.message));
			}
		);
	};
};

export const getLodSpecificationBasic = ({
	id,
	cb = () => {},
	offerView = false,
	permission = false,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/lod-specification-basic/${id}${
				permission ? `?permission=true` : ``
			}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!offerView) {
					dispatch({
						type: orderConstants.GET_PROJECT_LOD_SPECIFICATION,
						data: res.data,
					});
				} else {
					dispatch({
						type: lodSpecificationConstants.GET_LOD_SPECIFICATION,
						data: res.data,
					});
				}
			},
			(err) => {
				cb && cb();
				dispatch(lodSpecificationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Edit LOD specification endpoint. Used to change the data of a single LOD specification.
 *
 * @param {*} id -  Unique lod specification identifier
 * @param {*} data - Contains all lod specification  form data in a key-value pair
 * @param {*} cb - callback function
 * @returns
 */
export const editLodSpecification = (id, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/lod-specification/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					setLodSpecificationSuccessMessage(
						localize(LocKeys.ITEM_UPDATED, [
							localize(LocKeys.LOD_SPECIFICATION),
						])
					)
				);
				dispatch(clearCurrentLodSpecification());
				dispatch(lodSpecificationActionSuccess());
				cb();
			},
			(err) => {
				dispatch(
					lodSpecificationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [
							localize(LocKeys.LOD_SPECIFICATION),
						])
					)
				);
			}
		);
	};
};

/**
 * Retrieves LOD Specification stats
 *
 * SUCCESS: Populates 'stats' in the lodSpecification state.
 *
 * @returns array of lodSpecification stats
 */
export const getLodSpecificationStats = ({
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
	search,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/lod-specification-stats`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				customer,
				search,
				resellerCompanyId,
			},
		};
		return api(requestOptions).then(
			(res) => {
				let data = res.data;
				dispatch({
					type: lodSpecificationConstants.LOD_SPECIFICATION_STATS,
					data,
				});
			},
			(err) => {
				dispatch(lodSpecificationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * DELETE LOD Specification
 *
 * SUCEESS:
 * 	1. Success message
 *  2. Callback function - getLodSpecifications()
 *
 * @param {number} id - Unique LOD Specification identifier
 * @param {*} cb - callback function
 * @returns
 */
export const deleteLODSpecification = (id, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/lod-specification/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					setLodSpecificationSuccessMessage(
						localize(LocKeys.ITEM_DELETED, [
							localize(LocKeys.LOD_SPECIFICATION),
						])
					)
				);
				dispatch(lodSpecificationActionSuccess());
				cb();
			},
			(err) => {
				dispatch(
					lodSpecificationActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [
							localize(LocKeys.LOD_SPECIFICATION),
						])
					)
				);
			}
		);
	};
};

//---------------------------------END ENDPOINTS----------------------------------------------

//---------------------------------LOD SPECIFICATION STATE FUNCTION-------------------------------------

/**
 * Clear LOD specifciation and reset `lodSpecificationForm` form
 * @returns
 */
export const clearCurrentLodSpecification = (
	formName = "lodSpecificationForm"
) => {
	return (dispatch) => {
		dispatch({ type: lodSpecificationConstants.CLEAR_LOD_SPECIFICATION });
		if (formName) dispatch(reset(formName));
	};
};

/**
 * Set initial component category and lods per discipline inside TEMP LOD SPECIFICATION object
 * @returns
 */
export const setLodSpecification = (disciplines) => {
	return (dispatch) => {
		dispatch({
			type: lodSpecificationConstants.SET_DISCIPLINE_LODS_DESCRIPTION,
			data: { disciplines },
		});
	};
};

/**
 * Update lods,component category per decipline inside TEMP LOD SPECIFICATION object
 * @param {object} data - {componentCategoryId, lodValueId, disciplineId}
 * @returns
 */
export const updateLodSpecification = (data) => {
	return (dispatch) => {
		dispatch({
			type: lodSpecificationConstants.UPDATE_LODS,
			data,
		});
	};
};

/**
 * Clear TEMP LOD SPECIFICATION object and destory createLodSpecificationForm
 * @returns
 */
export const clearTempLodSpecification = (
	formName = "lodSpecificationForm"
) => {
	return (dispatch) => {
		formName && dispatch(destroy(formName));
		dispatch({ type: lodSpecificationConstants.CLEAR_TEMP_LOD_SPECIFICATION });
	};
};
//---------------------------------NEW STATE FUNCTION-------------------------------------
export const setTempSpecificationContent = (data) => {
	return (dispatch) => {
		dispatch({
			type: lodSpecificationConstants.SET_TEMP_SPECIFICATION_CONTENT,
			data,
		});
	};
};

//---------------------------------END NEW STATE FUNCTION-------------------------------------

export const setLodSpecificationSuccessMessage = (data) => {
	return { type: lodSpecificationConstants.SET_SUCCESS_MESSAGE, data };
};

export const lodSpecificationActionSuccess = () => {
	return { type: lodSpecificationConstants.LOD_SPECIFICATION_ACTION_SUCCESS };
};

export const lodSpecificationActionFailure = (error) => {
	return {
		type: lodSpecificationConstants.LOD_SPECIFICATION_ACTION_FAILURE,
		error,
	};
};

export const clearLodSpecificationRequestState = () => {
	return {
		type: lodSpecificationConstants.CLEAR_LOD_SPECIFICATION_REQUEST_STATE,
	};
};
