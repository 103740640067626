import React, { useMemo } from "react";
import { connect } from "react-redux";
import HeaderLayout from "../../../layout/content/HeaderLayout";
import StatusComponent from "../../../../shared/components/status/StatusComponent";
import {
	bimifyConstants,
	statusConstants,
} from "../../../../constants/constants";
import { useParams } from "react-router-dom-v5-compat";

const HeaderModelHistory = ({ name, archivedAt, status }) => {
	const { modelId } = useParams();
	const updatedStatus = useMemo(() => {
		return {
			[statusConstants.DELIVERED]: statusConstants.COMPLETED,
			[statusConstants.NORMALISATION]: statusConstants.ONGOING,
			[statusConstants.QA]: statusConstants.ONGOING,
			[statusConstants.CUSTOMISATION]: statusConstants.ONGOING,
		};
	}, []);

	const returnStatus = useMemo(() => {
		return !archivedAt
			? updatedStatus[status] || status
			: statusConstants.ARCHIVED;
	}, [status, archivedAt, updatedStatus]);
	return (
		<HeaderLayout wrapperClasses={`d-flex px-24`}>
			<div className="d-flex align-items-center justify-content-between w-100">
				<h3>{name}</h3>

				<div>
					<StatusComponent
						status={returnStatus}
						isActive={!archivedAt}
						canUpdateState={false}
						disabled={true}
						id={modelId}
						view={bimifyConstants.JOB}
						isSingleView={true}
					/>
				</div>
			</div>
		</HeaderLayout>
	);
};

const mapStateToProps = (state) => {
	return {
		name: state.getIn(["job", "job", "name"]),
		archivedAt: state.getIn(["job", "job", "archivedAt"]),
		status: state.getIn(["job", "job", "status"]),
	};
};

export default connect(mapStateToProps, {})(HeaderModelHistory);
