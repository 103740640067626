import {
	statusConstants,
	userRolesConstants,
} from "../../../../../../constants/constants";
import { tokenParams } from "../../../../../../utils/local-storage.util";
import {
	isLoggedUserAdmin,
	isLoggedUserBO,
	isLoggedUserCustomizer,
	isLoggedUserNormalizer,
	isLoggedUserPL,
	isLoggedUserQA,
} from "../../../../../../utils/permission/user";

export const canEditJob = () => {
	const role = tokenParams().role.type;

	const roles = [
		userRolesConstants.ADMIN,
		userRolesConstants.BUSINESS_OWNER,
		userRolesConstants.PROJECT_LEAD,
	];

	return roles.includes(role);
};

export const canEditInputNormalisation = () => {
	const role = tokenParams().role.type;

	const roles = [
		userRolesConstants.ADMIN,
		userRolesConstants.BUSINESS_OWNER,
		userRolesConstants.NORMALIZER,
	];

	return roles.includes(role);
};

export const canEditNormalisation = () => {
	const role = tokenParams().role.type;

	let editBimModel = [
		userRolesConstants.ADMIN,
		userRolesConstants.BUSINESS_OWNER,
	];
	editBimModel.push(userRolesConstants.NORMALIZER);

	return editBimModel.includes(role);
};

export const canEditCustomisation = () => {
	const role = tokenParams().role.type;

	let editBimModel = [
		userRolesConstants.ADMIN,
		userRolesConstants.BUSINESS_OWNER,
	];
	editBimModel.push(userRolesConstants.CUSTOMIZER);

	return editBimModel.includes(role);
};

export const canEditQA = () => {
	const role = tokenParams().role.type;

	let editBimModel = [
		userRolesConstants.ADMIN,
		userRolesConstants.BUSINESS_OWNER,
	];
	editBimModel.push(userRolesConstants.QUALITY_CONTROL);

	return editBimModel.includes(role);
};

export const canEditJobStatus = (type) => {
	return (
		isLoggedUserAdmin() ||
		isLoggedUserBO() ||
		isLoggedUserPL() ||
		(isLoggedUserNormalizer() && type === statusConstants.NORMALISATION) ||
		(isLoggedUserCustomizer() && type === statusConstants.CUSTOMISATION) ||
		(isLoggedUserQA() && type === statusConstants.QA)
	);
};
