import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M8.73633 6.3457V13.5255C8.73632 13.6382 8.76598 13.749 8.82234 13.8466C8.8787 13.9443 8.95977 14.0253 9.05736 14.0816L14.7011 17.339C14.7579 17.3719 14.8224 17.3892 14.888 17.3891C14.9536 17.3891 15.0181 17.3718 15.0749 17.3389C15.1317 17.306 15.1788 17.2587 15.2115 17.2017C15.2443 17.1448 15.2615 17.0802 15.2614 17.0145V10.1183L8.73633 6.3457Z"
					fill="#7F909F"
				/>
				<path
					d="M19.5426 19.4961L22.3806 17.8552C22.478 17.7989 22.5589 17.7179 22.6151 17.6204C22.6714 17.5229 22.701 17.4122 22.701 17.2996V6.19448C22.701 6.08187 22.6714 5.97125 22.6151 5.87374C22.5589 5.77623 22.478 5.69525 22.3806 5.63895L12.7765 0.0860667C12.6791 0.0296855 12.5685 0 12.456 0C12.3435 0 12.233 0.0296855 12.1356 0.0860667L8.74219 2.04846L18.9823 7.96864V19.1722C18.9825 19.2378 18.9999 19.3022 19.0327 19.3589C19.0655 19.4157 19.1126 19.4628 19.1694 19.4956C19.2261 19.5284 19.2904 19.5457 19.3559 19.5458C19.4214 19.5458 19.4858 19.5287 19.5426 19.4961Z"
					fill="#7F909F"
				/>
				<path
					d="M9.22163 6.62693L15.2639 10.119L18.9839 7.9684L8.73888 2.04883L1.62118 6.16124C1.5237 6.21759 1.44278 6.29867 1.38653 6.3963C1.33028 6.49392 1.30071 6.60467 1.30078 6.71738V17.8238C1.30082 17.9364 1.33044 18.047 1.38668 18.1445C1.44293 18.242 1.52379 18.323 1.62118 18.3793L11.1692 23.8986C11.2839 23.9649 11.414 23.9998 11.5464 23.9998C11.6788 23.9998 11.8089 23.9649 11.9236 23.8986L14.8684 22.1965C14.9082 22.1734 14.9412 22.1403 14.9642 22.1004C14.9871 22.0605 14.9992 22.0152 14.9992 21.9692C14.9992 21.9231 14.9871 21.8779 14.9642 21.838C14.9412 21.7981 14.9082 21.7649 14.8684 21.7418L5.34116 16.2287C5.24377 16.1724 5.16287 16.0914 5.10663 15.9939C5.05038 15.8964 5.02076 15.7857 5.02073 15.6731V8.86923C5.02064 8.75667 5.05015 8.64607 5.10629 8.54855C5.16243 8.45103 5.24321 8.37003 5.34053 8.3137L8.25182 6.62754C8.39917 6.54216 8.5664 6.49714 8.73665 6.49704C8.9069 6.49693 9.07416 6.54173 9.22163 6.62693Z"
					fill="#7F909F"
				/>
			</svg>
		</span>
	);
};
