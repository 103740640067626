import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M20.125 3.25H3.875C2.82031 3.25 2 4.10938 2 5.125V7.625C2 8.32812 2.54688 8.875 3.21094 8.875L3.25 18.25C3.25 19.6562 4.34375 20.75 5.75 20.75H18.25C19.6172 20.75 20.75 19.6562 20.75 18.25V8.875C21.4141 8.875 22 8.32812 22 7.66406V5.125C22 4.10938 21.1406 3.25 20.125 3.25ZM18.25 18.875H5.75C5.39844 18.875 5.125 18.6016 5.125 18.25V8.875H18.875V18.25C18.875 18.6016 18.5625 18.875 18.25 18.875ZM20.125 7H3.875V5.125H20.125V7ZM9.14844 12.625H14.7734C15.3203 12.625 15.75 12.2344 15.75 11.6875C15.75 11.1797 15.3203 10.75 14.8125 10.75H9.14844C8.64062 10.75 8.25 11.1797 8.25 11.6875C8.25 12.2344 8.64062 12.625 9.14844 12.625Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};
