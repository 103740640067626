import { Map, fromJS } from "immutable";
import modelUpdateConstants from "../constants/modelUpdateConstants";
import { v4 as uuid } from "uuid";

const initialState = Map({
	stopBuildingRefetch: false,
	tempModelUpdate: Map({
		name: "",
		building: "",
		buildingName: "",
		inputFormat: "",
		deliveryFormat: "",
		specification: "",
		template: "",
		additionalInfo: "",
	}),
	tempFiles: fromJS([]),
});

export default (state = initialState, action) => {
	/**
	 * UPDATE temp model building
	 *
	 * @param {object} data - data to update (fieldKey, value)
	 *
	 */
	const updateTempModelBuilding = (data) => {
		const { fieldKey, value } = data;

		if (fieldKey === "building") {
			return state
				.setIn(["tempModelUpdate", fieldKey], value)
				.set("stopBuildingRefetch", false);
		}

		return state.setIn(["tempModelUpdate", fieldKey], value);
	};

	/**
	 * SET temp model update (building data)
	 *
	 * @param {object} building - building data for temp model update
	 *
	 */
	const setTempModelUpdate = (building) => {
		const { id, name, lodSpecification, order, levels } = building;
		const { orderDeliveryFormats, revitFile, orderExports } = order;

		let stateDisciplineLevels = [];

		let stateLevels = [];

		levels &&
			levels.length > 0 &&
			levels.forEach((level, i) => {
				const isLast = i + 1 === +levels.length;

				if (isLast) {
					stateLevels.push({
						id: level?.buildingLevel?.id,
						key: `${level?.discipline?.id}_floor__${level?.buildingLevel?.level}`,
						level: level?.buildingLevel?.level,
						file: null,
						tempFileId: null,
						valid: true,
						path: "",
						elevation: level?.buildingLevel?.elevation || null,
						thickness: level?.buildingLevel?.thickness || null,
					});
				}

				if (
					(i > 0 && level?.discipline.id !== levels[i - 1]?.discipline.id) ||
					isLast
				) {
					stateLevels =
						stateLevels &&
						stateLevels.sort((a, b) => {
							return b.level - a.level;
						});

					stateDisciplineLevels.push({
						discipline: isLast
							? level?.discipline.id
							: levels[i - 1]?.discipline.id,
						levels: stateLevels,
					});

					stateLevels = [];
				}
				// ---------------------------------------------------

				stateLevels.push({
					id: level?.buildingLevel?.id,
					key: `${level?.discipline?.id}_floor__${level?.buildingLevel?.level}`,
					level: level?.buildingLevel?.level,
					file: null,
					tempFileId: null,
					valid: true,
					path: "",
					elevation: level?.buildingLevel?.elevation || null,
					thickness: level?.buildingLevel?.thickness || null,
				});
			});

		const exports = [];

		orderExports &&
			orderExports.forEach((item) => {
				exports.push({
					exportId: item.export.id,
					id: item.export?.parent ? item.export?.parent?.id : item.export.id,
				});
			});

		const data = {
			name: "",
			inputFormat: "",
			building: id,
			buildingName: name,
			specification: lodSpecification?.id || null,
			template: revitFile?.id || null,
			deliveryFormat: orderDeliveryFormats?.[0]?.deliveryFormat?.id || null,
		};

		return state
			.set("tempModelUpdate", fromJS(data))
			.setIn(["tempModelUpdate", "exports"], exports)
			.setIn(
				["tempModelUpdate", "disciplineLevels"],
				fromJS(stateDisciplineLevels)
			);
	};

	/**
	 * UPDATE temp model exports
	 *
	 * @param {object} data - data to update (id, exportId, isSelected)
	 *
	 */
	const updateTempModelExports = (data) => {
		const { id, exportId, isSelected } = data;

		const exports = state.getIn(["tempModelUpdate", "exports"]) || [];
		const findIndex = exports.findIndex((item) => item.id === id);

		if (findIndex !== -1) {
			if (!isSelected) {
				const tempFilterExports = exports.filter((item) => +item.id !== +id);

				return state.setIn(["tempModelUpdate", "exports"], tempFilterExports);
			} else {
				return state
					.setIn(
						["tempModelUpdate", "exports", findIndex, "exportId"],
						exportId
					)
					.setIn(
						["tempModelUpdate", "exports", findIndex, "valid"],
						exportId ? true : false
					);
			}
		}

		let tempExports = [
			{
				id: id,
				exportId: exportId,
				price: null,
				valid: exportId ? true : false,
			},
		];
		if (exports.length > 0) {
			tempExports = tempExports.concat(exports);
		}

		return state.setIn(["tempModelUpdate", "exports"], tempExports);
	};

	/**
	 * SET temp model update files
	 *
	 * @param {Array} files - files data
	 *
	 */
	const setTempModelUpdateFiles = (files) => {
		const tempFiles = state.get("tempFiles")?.toJS() || [];

		files &&
			files.forEach((file) =>
				tempFiles.push({
					name: file.name,
					id: uuid(),
					file,
				})
			);

		return state.set("tempFiles", fromJS(tempFiles));
	};

	/**
	 * UPDATE file per level in model update
	 *
	 * @param {object} data - data to update (levelKey, fileId, disciplineId)
	 *
	 */
	const setModelUpdateFilePerLevel = (data) => {
		const { levelKey, fileId, disciplineId } = data;

		const disciplineLevels =
			state.getIn(["tempModelUpdate", "disciplineLevels"])?.toJS() || [];

		const disciplineLevel =
			disciplineLevels &&
			disciplineLevels.find(
				(disciplineLevel) => +disciplineLevel.discipline === +disciplineId
			);

		const levels = disciplineLevel?.levels;
		const disciplineLevelIndex = disciplineLevels.indexOf(disciplineLevel);
		const levelIndex =
			levels && levels.findIndex((level) => level.key === levelKey);

		return state.setIn(
			[
				"tempModelUpdate",
				"disciplineLevels",
				disciplineLevelIndex,
				"levels",
				levelIndex,
				"tempFileId",
			],
			fileId
		);
	};

	/**
	 * SET model update file id
	 *
	 * @param {object} data - data to update (fileId, tempFileId, disciplineId)
	 *
	 */
	const setModelUpdateFileId = (data) => {
		const { fileId, tempFileId, disciplineId } = data;

		const disciplineLevels =
			state.getIn(["tempModelUpdate", "disciplineLevels"])?.toJS() || [];

		const disciplineLevel =
			disciplineLevels &&
			disciplineLevels.find(
				(disciplineLevel) => +disciplineLevel.discipline === +disciplineId
			);

		const levels = disciplineLevel?.levels;
		const disciplineLevelIndex = disciplineLevels.indexOf(disciplineLevel);
		const levelIndex =
			levels && levels.findIndex((level) => level.tempFileId === tempFileId);

		return state.setIn(
			[
				"tempModelUpdate",
				"disciplineLevels",
				disciplineLevelIndex,
				"levels",
				levelIndex,
				"file",
			],
			fileId
		);
	};

	switch (action.type) {
		case modelUpdateConstants.UPDATE_TEMP_MODEL_BUILDING:
			return updateTempModelBuilding(action.data);

		case modelUpdateConstants.UPDATE_TEMP_MODEL_EXPORTS:
			return updateTempModelExports(action.data);

		case modelUpdateConstants.SET_TEMP_MODEL_UPDATE:
			return setTempModelUpdate(action.data);

		case modelUpdateConstants.SET_TEMP_MODEL_UPDATE_FILES:
			return setTempModelUpdateFiles(action.data);

		case modelUpdateConstants.SET_MODEL_UPDATE_FILE_PER_LEVEL:
			return setModelUpdateFilePerLevel(action.data);

		case modelUpdateConstants.SET_MODEL_UPDATE_FILE_ID:
			return setModelUpdateFileId(action.data);

		case modelUpdateConstants.UPDATE_STOP_BUILDING_REFETCH:
			return state.set("stopBuildingRefetch", action.data);

		case modelUpdateConstants.CLEAR_TEMP_MODEL_UPDATE:
			return state
				.set("stopBuildingRefetch", false)
				.set("tempModelUpdate", initialState.get("tempModelUpdate"))
				.set("tempFiles", initialState.get("tempFiles"));

		default:
			return state;
	}
};
