import React from "react";
import InputLabel from "@mui/material/InputLabel";
import QuestionIcon from "../../shared/icons/misc/QuestionIcon";
import ComponentTooltip from "../../shared/components/component-tooltip/ComponentTooltip";

/**
 * Component for simple text field
 */

export default ({
	tooltipCustomClasses = "",
	disableAnimation = true,
	shrink = false,
	required = false,
	label,
	labelAppend = undefined,
	infoContent,
	note,
	id,
}) => {
	return (
		<InputLabel
			htmlFor={id}
			disableAnimation={disableAnimation}
			shrink={shrink}
			classes={{
				root: `label
					${required ? "label--required" : ""}
				`,
				focused: "label--focused",
			}}
		>
			{infoContent ? (
				<ComponentTooltip
					content={infoContent}
					wrapperClasses={"label__tooltip"}
					tooltipCustomClasses={tooltipCustomClasses}
				>
					<>
						{label} {labelAppend && labelAppend()}
						{required && <span className="label--required__asterisk">*</span>}
						<QuestionIcon iconClass="ml-xs icon--info icon--target-fill" />
					</>
				</ComponentTooltip>
			) : (
				<>
					{label} {labelAppend && labelAppend()}
					{required && <span className="label--required__asterisk">*</span>}
				</>
			)}
			{note && <span className="label__note">{note}</span>}
		</InputLabel>
	);
};
