import React from "react";
import localize, { LocKeys } from "../../../constants/localizations";

/**
 * Used to write out standardized form buttons
 *
 * @param {Object.<string, any>} props
 * @param {string} [props.cancelLabel="Cancel"] - Label written out in cancel button, default: Cancel
 * @param {string} [props.submitLabel="Submit"] - Label written out in submit button, default: Save
 * @param {string} [props.submitType="submit"] - Submit button type, Default: submit
 * @param {boolean} [props.submitDisabled = false] - Submit button disabled state, Default: false
 * @param {function} props.onCancel - Function triggered on button click
 * @param {function} props.onSubmit - Function triggered on button click
 *
 * @return {React.FC} Form buttons component
 */
const FormSubmitButtons = ({
	onSubmit,
	onCancel,
	cancelLabel = localize(LocKeys.CANCEL),
	submitLabel = localize(LocKeys.SAVE),
	submitType = "submit",
	submitDisabled = false,
	wrapperClasses = "button-contents__wrapper",
	//TODO: rename showRightButton -> showSubmitButton
	showRightButton = true,
	onCustomAction = null,
	customActionButtonLabel = null,
	cancelClassName = "",
	submitClassName = "",
	loading = false,
}) => {
	if (!onCancel) {
		return "";
	}
	return (
		<div className={`d-flex justify-content-end ${wrapperClasses}`}>
			{onCustomAction && (
				<button
					type="button"
					onClick={onCustomAction && onCustomAction}
					className={`ml-m btn btn--secondary`}
				>
					{customActionButtonLabel}
				</button>
			)}

			<button
				type="button"
				onClick={onCancel && onCancel}
				className={`btn btn--secondary-error ${cancelClassName}`}
			>
				{cancelLabel}
			</button>

			{showRightButton && (
				<button
					type={submitType}
					onClick={onSubmit && onSubmit}
					disabled={submitDisabled}
					className={`ml-m btn btn--primary ${submitClassName} ${
						loading ? "btn--loading btn--loading--active" : ""
					}`}
				>
					{submitLabel}
				</button>
			)}
		</div>
	);
};

export default FormSubmitButtons;
