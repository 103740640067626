import React from "react";
import localize, { LocKeys } from "../../../constants/localizations";
import FileInputButton from "../../../components/fields/FileInputButton";
import { fileSizes } from "../../../constants/constants";

const CustomStepNavigation = ({
	buttonAlignment = "end",
	hideLeftButton = false,
	hideRightButton = false,
	cancelButtonAction,
	leftButtonAction,
	leftButtonLabel = localize(LocKeys.BACK),
	rightButtonLabel = localize(LocKeys.NEXT),
	rightButtonDisabled = false,
	rightButtonClasses = "btn--secondary", // for when the button is used to trigger create insted of nextPage
	leftButtonClasses = "btn--secondary",
	rightButtonLoading = false,
	showCancel = false,
	showUploadFile = false,
	handleUploadFiles = () => {},
}) => {
	let wrapperClasses = `justify-content-${buttonAlignment} ${
		buttonAlignment === "center" ? "" : "py-24"
	}`;

	return (
		<div
			className={`w-100 mt-auto d-flex flex-column flex-tablet-row ${wrapperClasses}`}
		>
			{showUploadFile && (
				<FileInputButton
					buttonWrapperClasses="btn btn--secondary btn--upload btn--icon btn--icon--n500 btn--icon--before mr-m"
					buttonLabel={localize(LocKeys.UPLOAD_FILES)}
					customOnChange={handleUploadFiles}
					id={`file_input_update_model`}
					allowedSize={fileSizes.GB_40}
				/>
			)}
			{showCancel && (
				<button
					type="button"
					onClick={cancelButtonAction}
					className={`btn border-none btn--secondary-error w-100 w-tablet-auto mr-m`}
				>
					{localize(LocKeys.CANCEL)}
				</button>
			)}
			{!hideLeftButton && (
				<button
					type="button"
					onClick={leftButtonAction}
					className={`btn border-none mr-m ${leftButtonClasses} w-100 w-tablet-auto`}
				>
					{leftButtonLabel}
				</button>
			)}
			{!hideRightButton && (
				<button
					disabled={rightButtonDisabled}
					type="submit"
					className={`
					  btn
					w-100 w-tablet-auto
					mt-regular mt-tablet-0
					${rightButtonClasses}
					${rightButtonLoading ? "btn--loading btn--loading--active" : ""}
				`}
				>
					{rightButtonLabel}
				</button>
			)}
		</div>
	);
};

export default CustomStepNavigation;
