import React, { useCallback, useEffect, useMemo } from "react";
import { Field } from "redux-form/immutable";
import localize, {
	LocKeys,
} from "../../../../../../../constants/localizations";
import CustomAutocomplete from "../../../../../../fields/CustomAutocomplete";
import IconWrapper from "../../../../../../../shared/icons/IconWrapper";
import {
	getDeliveryFormatIcon,
	getInputFormatIcon,
} from "../../../../../../../utils/getTypeIcon";
import {
	getDeliveryFormat,
	getInputFormats,
	getLodSpecifications,
	getRevitTemplates,
	updateTempModelBuilding,
} from "../../../../../../../actions";
import { connect } from "react-redux";

const TypeComponent = (props) => {
	const {
		//Redux props
		customer,
		resellerCompanyId,
		initialValues,
		lodSpecifications,
		inputFormats,
		deliveryFormats,
		revitTemplateList,
		//Redux actions
		getDeliveryFormat,
		getInputFormats,
		getLodSpecifications,
		getRevitTemplates,
		//Redux State Actions
		updateTempModelBuilding,
	} = props;

	useEffect(() => {
		getDeliveryFormat();
		getInputFormats();
		getLodSpecifications({
			usePagination: false,
			filterPublic: true,
			customer: customer,
			resellerCompanyId,
		});
		getRevitTemplates({ usePagination: false, customer, resellerCompanyId });
	}, [
		customer,
		getDeliveryFormat,
		getInputFormats,
		getLodSpecifications,
		getRevitTemplates,
		resellerCompanyId,
	]);

	const setValue = useCallback(
		(fieldKey, value) => {
			updateTempModelBuilding(fieldKey, value);
		},
		[updateTempModelBuilding]
	);

	const inputFormatOptions = useMemo(() => {
		const options = [];

		if (inputFormats && inputFormats.size > 0) {
			inputFormats &&
				inputFormats.map((inputFormat, i) => {
					let subHeader = (
						<div
							className={`dropdown-subheader body-font--small  semi  ${+i === 0 ? "" : "dropdown-subheader--border"}`}
						>
							<div className="d-flex align-items-center w-100">
								<IconWrapper
									size="24"
									wrapperClasses="mr-s"
									icon={getInputFormatIcon(inputFormat.get("type"))}
								/>

								<div className="text-overflow-ellipsis">
									{inputFormat.get("type")}
								</div>
							</div>
						</div>
					);

					const versions = inputFormat?.get("children");

					versions &&
						versions.map((item, i) => {
							return options.push({
								subHeader,
								label: item.get("type"),
								value: item.get("id"),
							});
						});

					return options;
				});
		}
		return options;
	}, [inputFormats]);

	const deliveryFormatOptions = useMemo(() => {
		const options = [];
		if (deliveryFormats && deliveryFormats.size > 0) {
			deliveryFormats.map((format, i) => {
				let subHeader = (
					<div
						className={`dropdown-subheader body-font--small  semi  ${+i === 0 ? "" : "dropdown-subheader--border"}`}
					>
						<div className="d-flex align-items-center w-100">
							<div>
								<IconWrapper
									size="24"
									wrapperClasses="mr-s"
									icon={getDeliveryFormatIcon(format.get("type"))}
								/>
							</div>
							<div className="text-overflow-ellipsis">{format.get("type")}</div>
						</div>
					</div>
				);

				const children = format?.get("children");

				children &&
					children.map((item) => {
						options.push({
							subHeader,
							label: item.get("type"),
							value: item.get("id"),
						});

						return options;
					});

				return options;
			});
		}

		return options;
	}, [deliveryFormats]);

	const lodSpecificationOptions = useMemo(() => {
		const options = [];
		if (lodSpecifications && lodSpecifications.size > 0) {
			lodSpecifications.map((lodSpecification) => {
				return options.push({
					value: lodSpecification.get("id"),
					label: lodSpecification.get("name"),
				});
			});
		}
		return options;
	}, [lodSpecifications]);

	const templateOptions = useMemo(() => {
		const options = [];

		revitTemplateList &&
			revitTemplateList.map((template) => {
				return options.push({
					label: template.get("name"),
					value: template.get("id"),
				});
			});

		return options;
	}, [revitTemplateList]);

	return (
		<div className="row row--6p-gutters pt-regular">
			<div className="col col-100 col-desktop-25">
				<Field
					name="inputFormat"
					id="inputFormat"
					classField="mb-24"
					label={localize(LocKeys.INPUT_FORMAT)}
					placeholder={localize(LocKeys.SELECT_INPUT_FORMAT) + "..."}
					required={true}
					dropdownHeight="350px"
					showPlaceholder={true}
					customOnChange={(value) => {
						setValue("inputFormat", value);
					}}
					disableClearable={true}
					component={CustomAutocomplete}
					buildingList={true}
					groupBy={true}
					options={inputFormatOptions}
					initialValue={initialValues?.inputFormat || null}
				/>
			</div>
			<div className="col col-100 col-desktop-25">
				<Field
					name="deliveryFormat"
					id="deliveryFormat"
					dropdownHeight="350px"
					classField="mb-24"
					disableClearable={true}
					groupBy={true}
					label={localize(LocKeys.DELIVERY_FORMAT)}
					placeholder={localize(LocKeys.SELECT_DELIVERY_FORMAT) + "..."}
					required={true}
					showPlaceholder={true}
					customOnChange={(value) => {
						setValue("deliveryFormat", value);
					}}
					component={CustomAutocomplete}
					buildingList={true}
					options={deliveryFormatOptions}
					initialValue={initialValues?.deliveryFormat || null}
				/>
			</div>

			<div className="col col-100 col-desktop-25">
				<Field
					name="specification"
					id="specification"
					classField="mb-24"
					dropdownHeight="350px"
					label={localize(LocKeys.LOD_SPECIFICATION)}
					placeholder={localize(LocKeys.SELECT_LOD_SPECIFICATION) + "..."}
					required={true}
					showPlaceholder={true}
					customOnChange={(value) => {
						setValue("specification", value);
					}}
					component={CustomAutocomplete}
					buildingList={true}
					disableClearable={true}
					options={lodSpecificationOptions}
					initialValue={initialValues?.specification || null}
				/>
			</div>
			<div className="col col-100 col-desktop-25">
				<Field
					name="template"
					id="template"
					classField="mb-24"
					dropdownHeight="350px"
					label={localize(LocKeys.TEMPLATE_LABEL)}
					placeholder={localize(LocKeys.SELECT_TEMPLATE) + "..."}
					required={true}
					showPlaceholder={true}
					customOnChange={(value) => {
						setValue("template", value);
					}}
					buildingList={true}
					component={CustomAutocomplete}
					disableClearable={true}
					options={templateOptions}
					initialValue={initialValues?.template || null}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		inputFormats: state.getIn(["administration", "inputFormat"]),
		revitTemplateList: state.getIn(["template", "revitTemplateList"]),
		lodSpecifications: state.getIn([
			"lodSpecification",
			"lodSpecificationList",
		]),

		deliveryFormats: state.getIn(["administration", "deliveryFormats"]),
		customer: state.getIn(["global", "customer"]),
		resellerCompanyId: state.getIn(["global", "reseller"]),
		initialValues: {
			inputFormat:
				state.getIn(["modelUpdate", "tempModelUpdate", "inputFormat"]) || "",
			deliveryFormat: state.getIn([
				"modelUpdate",
				"tempModelUpdate",
				"deliveryFormat",
			]),
			specification: state.getIn([
				"modelUpdate",
				"tempModelUpdate",
				"specification",
			]),
			template: state.getIn(["modelUpdate", "tempModelUpdate", "template"]),
		},
	};
};

export default connect(mapStateToProps, {
	getDeliveryFormat,
	getInputFormats,
	getLodSpecifications,
	getRevitTemplates,
	updateTempModelBuilding,
})(TypeComponent);
