import React from "react";
import SectionHeader from "../../orders/single-view/parts/SectionHeader";

const SectionEdit = ({ title, children }) => {
	return (
		<div className="mt-48 pl-48 pr-24 bg-primary--lighter">
			{title && (
				<SectionHeader
					title={title}
					wrapperClasses="mb-regular py-24 border-b-1 border-p300"
				/>
			)}

			{children}
		</div>
	);
};

export default SectionEdit;
