import React from "react";
import { bimifyConstants } from "../../../constants/constants";
import IconWrapper from "../../../shared/icons/IconWrapper";
import CustomerIcon from "../../../shared/icons/misc/CustomerIcon";
import UserIcon from "../../../shared/icons/misc/UserIcon";

import MixedIcon from "../../../shared/icons/inputFormats/MixedIcon";
import BimifyIcon from "../../../shared/icons/misc/BimifyIcon";
import LodSpecificationIcon from "../../../shared/icons/navigation/LodSpecificationIcon";
import {
	getBuildingTypeIcon,
	getInputFormatIcon,
} from "../../../utils/getTypeIcon";
import TooltipWithHtml from "../../../shared/htmlTooltip/TooltipWithHtml";

export default ({
	size,
	view,
	wrapperClasses = "",
	iconSize = 24,
	type = null,
	isPublic = false,
	buildings = [],
}) => {
	const allAreEqual = (array) => {
		const result = array.every((element) => {
			if (element === array[0]) {
				return true;
			}
			return false;
		});

		return result;
	};
	const getBuildingInputFormat = () => {
		const inputFormats = [];
		buildings?.length > 0 &&
			buildings.forEach((building) => {
				inputFormats.push(building?.inputFormat?.type);
			});
		const isAllEqual = allAreEqual(inputFormats);

		if (isAllEqual) {
			return getInputFormatIcon(inputFormats[0]);
		} else {
			return <MixedIcon iconClass={`d-flex icon--target-fill fill--n300`} />;
		}
	};

	const renderFileTypeImage = () => {
		switch (view) {
			case bimifyConstants.USER:
				return <UserIcon iconClass={`d-flex`} />;
			case bimifyConstants.CUSTOMER:
				return <CustomerIcon iconClass={`d-flex`} />;
			case bimifyConstants.OFFER:
				return getBuildingInputFormat();
			case bimifyConstants.BUILDING:
				return getBuildingTypeIcon(type);

			case bimifyConstants.LOD_SPECIFICATION:
				return isPublic ? (
					<BimifyIcon iconClass={`d-flex`} />
				) : (
					<LodSpecificationIcon
						iconClass={`d-flex icon--target-fill fill--n300`}
					/>
				);
			//JOB
			default:
				return getInputFormatIcon(type);
		}
	};

	return (
		<div
			className={`icon-cell  ${wrapperClasses} ${
				size ? `icon-cell--${size}` : ""
			}`}
		>
			{view === bimifyConstants.BUILDING || view === bimifyConstants.JOB ? (
				<TooltipWithHtml tooltipContent={type} tooltipPlacement="right">
					<IconWrapper size={iconSize} icon={renderFileTypeImage()} />
				</TooltipWithHtml>
			) : (
				<IconWrapper size={iconSize} icon={renderFileTypeImage()} />
			)}
		</div>
	);
};
