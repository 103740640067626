import React from "react";
import { alertTypeConstants, fileSizes } from "../../constants/constants";
import localize, { LocKeys } from "../../constants/localizations";
import { useFileInput } from "../../hooks/useFileInput";
import InfoBlock from "../../shared/components/InfoBlock/InfoBlock";
import IconWrapper from "../../shared/icons/IconWrapper";
import ImageInputFormatIcon from "../../shared/icons/inputFormats/ImageInputFormatIcon";
import CustomLabel from "./CustomLabel";
import FileList from "./FileList";
import FileIcon from "../../shared/icons/files/FileIcon";

const renderDefaultContent = ({
	id,
	accept,
	onChange,
	multipleFilesAllowed,
	disabled,
	customSupportedText = null,
}) => {
	const isForImage = accept && accept.includes("image");
	return (
		<div className="w-100 d-flex flex-wrap align-items-center justify-content-center flex-column">
			<IconWrapper
				icon={
					isForImage ? (
						<ImageInputFormatIcon iconClass="d-flex icon--target-fill fill--n300" />
					) : (
						<FileIcon iconClass="d-flex icon--target-fill fill--n300" />
					)
				}
				size={48}
			/>
			<div className="pt-24 pb-regular">
				{localize(LocKeys.DRAG_AND_UPLOAD_FILES)}
			</div>
			<div>
				<input
					id={id ? id : "file-input"}
					hidden
					type="file"
					name={id ? id : "file-input"}
					accept={accept ? accept : "*"}
					multiple={multipleFilesAllowed}
					onChange={onChange}
					disabled={disabled ? disabled : false}
				/>
				<label
					htmlFor={id ? id : "file-input"}
					className={`btn btn--secondary btn--small   ${
						disabled ? "btn--disabled" : " "
					}`}
				>
					{localize(LocKeys.UPLOAD_FROM_COMPUTER)}
				</label>
			</div>
			<div className="body-font--small color-n300 pt-m">
				{!!customSupportedText
					? customSupportedText
					: `${localize(LocKeys.SUPPORTED_FILE_FORMAT)} (.png, .jpg)`}
			</div>
		</div>
	);
};

const FileInput = ({
	id,
	dropzone = true,
	small = false,
	label,
	required,
	labelClasses = "",
	disabled = false,
	customOnChange,
	multipleFilesAllowed = true,
	customError = false,
	customErrorLabel = "",
	showFileList = true,
	children,
	accept = null,
	allowedSize = fileSizes.GB_5,
	customErrorInvalidType = "",
	customSupportedText = null,
	renderContent = renderDefaultContent,
}) => {
	const {
		invalidType,
		labelId,
		isDragging,
		selected,
		filesTooLarge,
		removeFile,
		handleKeyPress,
		dragEnter,
		dragOver,
		dragLeave,
		fileDrop,
		onChange,
		setInvalidType,
	} = useFileInput({
		id,
		customOnChange,
		allowedSize,
		accept: accept ? accept : "*",
	});

	const maxFileSizeLabel =
		allowedSize >= fileSizes.GB
			? (allowedSize / fileSizes.GB).toFixed(2) + " GB"
			: allowedSize >= fileSizes.MB
				? (allowedSize / fileSizes.MB).toFixed(2) + " MB"
				: (allowedSize / fileSizes.kB).toFixed(2) + " kB";

	return (
		<div className="d-flex flex-column h-100">
			{label && <CustomLabel label={label} required={required} />}

			<div className="h-100">
				<label
					tabIndex={0}
					id={labelId}
					htmlFor={id ? id : "file-input"}
					className={`file-input
						${labelClasses ? labelClasses : ""}
						${disabled ? "file-input--disabled" : ""}
						${small ? "file-input--small" : ""}
						${isDragging ? "file-input--dragging " : ""}
						${dropzone && !!!children ? "file-input--dropzone" : ""}
						`}
					onKeyUp={handleKeyPress}
					onDragOver={dragOver}
					onDragEnter={dragEnter}
					onDragLeave={dragLeave}
					onDrop={fileDrop}
				>
					{dropzone &&
						renderContent({
							id,
							accept,
							onChange,
							multipleFilesAllowed,
							disabled,
							customSupportedText,
						})}
					{!!children && children}
					{!dropzone && (
						<input
							id={id ? id : "file-input"}
							hidden
							type="file"
							name={id ? id : "file-input"}
							multiple={multipleFilesAllowed}
							onChange={onChange}
							disabled={disabled ? disabled : false}
						/>
					)}
				</label>
			</div>
			{showFileList && <FileList handleRemove={removeFile} files={selected} />}
			{filesTooLarge && (
				<div className="w-100 pt-regular">
					<InfoBlock
						title={localize(LocKeys.ERROR)}
						type={alertTypeConstants.ERROR}
						wrapperClasses="w-100"
					>
						{localize(LocKeys.FILES_SIZE_EXCEEDED, [maxFileSizeLabel])}
					</InfoBlock>
				</div>
			)}
			{invalidType && (
				<div className="w-100 pt-regular">
					<InfoBlock
						title={localize(LocKeys.ERROR)}
						type={alertTypeConstants.ERROR}
						wrapperClasses="w-100"
						onClose={() => setInvalidType(false)}
					>
						{customErrorInvalidType || localize(LocKeys.INVALID_FILE_TYPE)}
					</InfoBlock>
				</div>
			)}
			{customError && (
				<div className="w-100 pt-regular">
					<InfoBlock
						title={localize(LocKeys.ERROR)}
						type={alertTypeConstants.ERROR}
						wrapperClasses="w-100"
					>
						{customErrorLabel}
					</InfoBlock>
				</div>
			)}
		</div>
	);
};

export default FileInput;
