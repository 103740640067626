import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import FormSubmitButtons from "../../../../../../shared/components/FormComponents/FormSubmitButtons";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { useParams } from "react-router-dom-v5-compat";
import ExportsComponent from "../../../../../../shared/components/specification/ExportsComponent";
import {
	resetJobExports,
	setJobExports,
	updateJob,
} from "../../../../../../actions";

const JobExportsEdit = ({
	isModelHistory = false,
	initialValues,
	onClose,
	//Actions redux
	setJobExports,
	updateJob,
	resetJobExports,
	...props
}) => {
	const { jobId } = useParams();
	const [loading, setLoading] = useState(false);

	const onSubmit = () => {
		try {
			const tempExports = props.tempExports && props.tempExports;
			setLoading(true);

			//Export versions

			let exports = [];
			tempExports.forEach((item) => {
				exports.push(item.exportId);
			});
			const body = {
				exports: exports,
			};

			updateJob({
				jobId,
				data: body,
				step: 1,
				cb: () => {
					setLoading(false);
					onClose && onClose();
				},
				isModelHistory,
			});
		} catch (e) {
			console.log("Job exports edit error: ", e);
			return;
		}
	};
	const handleClose = () => {
		resetJobExports();
		onClose();
	};

	const setExports = useCallback(
		(data) => {
			setJobExports(data);
		},
		[setJobExports]
	);

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<ExportsComponent
				rowClass="mb-0"
				hidePrice={true}
				label={localize(LocKeys.EXPORTS)}
				updateSelected={(data) => setExports(data)}
				initialSelected={initialValues?.get("exports")?.toJS()}
				initialValues={initialValues}
				required={true}
				formName={"editJobExportForm"}
			/>

			<FormSubmitButtons
				wrapperClasses="w-100 py-24 mt-24"
				onCancel={handleClose}
				loading={loading}
				submitDisabled={loading}
			/>
		</form>
	);
};

const mapStateToProps = (state) => {
	const exports = [];
	let initialValues = {};

	const tempExports = state.getIn(["job", "tempJob", "exports"]);

	//EXPORT
	tempExports &&
		tempExports.forEach((tempExport) => {
			exports.push(tempExport.id);
			initialValues[`exportVersion_${tempExport.id}`] = tempExport.exportId;
		});
	initialValues["exports"] = exports;

	return {
		initialValues,
		tempExports: tempExports,
	};
};

export default connect(mapStateToProps, {
	setJobExports,
	updateJob,
	resetJobExports,
})(
	reduxForm({
		form: "jobExportsEditForm",
		destroyOnUnmount: false,
		enableReinitialize: true,
		touchOnBlur: false,
	})(JobExportsEdit)
);
