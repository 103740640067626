const companyConstants = {
	SET_FULL_ADDRESS: "SET_FULL_ADDRESS",
	GET_COMPANIES: "GET_COMPANIES",
	GET_GLOBAL_RESELLER_COMPANIES: "GET_GLOBAL_RESELLER_COMPANIES",
	GET_GLOBAL_CLIENT_COMPANIES: "GET_GLOBAL_CLIENT_COMPANIES",
	GET_BIMIFY_COMPANY: "GET_BIMIFY_COMPANY",
	GET_COMPANY: "GET_COMPANY",
	GET_COMPANY_COUNTRIES: "GET_COMPANY_COUNTRIES",
	GET_SUPPLIER: "GET_SUPPLIER",
	FIELD_VALUE: "FIELD_VALUE",
	SET_TEMP_IMAGE: "SET_TEMP_IMAGE",
	CLEAR_COMPANY_TEMP: "CLEAR_COMPANY_TEMP",
	CLEAR_COMPANY: "CLEAR_COMPANY",
	SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
	C_ACTION_SUCCESS: "C_ACTION_SUCCESS",
	C_ACTION_FAILURE: "C_ACTION_FAILURE",
	ACTION_GET_FAILURE: "ACTION_GET_FAILURE",
	C_REQUEST_DONE: "C_REQUEST_DONE",
	CLEAR_COMPANY_REQUEST_STATE: "CLEAR_COMPANY_REQUEST_STATE",
	GET_SUPPLIER_ADMINS: "GET_SUPPLIER_ADMINS",
	GET_ADMINS: "GET_ADMINS",
	COMPANY_STATS: "COMPANY_STATS",
	GET_CLINET_PL: "GET_CLINET_PL",
	GET_SUPPLIER_PL: "GET_SUPPLIER_PL",
	GET_COMPANY_TYPES: "GET_COMPANY_TYPES",
	GET_COMPANY_ROLES: "GET_COMPANY_ROLES",
	SET_TEMP_COMPANY_VALUE: "SET_TEMP_COMPANY_VALUE",
	SET_TEMP_COMPANY: "SET_TEMP_COMPANY",
	CLEAR_TEMP_COMPANY: "CLEAR_TEMP_COMPANY",
	GET_RESELLER_ADMINS: "GET_RESELLER_ADMINS",
	GET_RESELLER_PL: "GET_RESELLER_PL",
	RESET_GENERAL_INFO: "RESET_GENERAL_INFO",
	RESET_BILLING_DETAILS: "RESET_BILLING_DETAILS",
	RESET_ADMIN_INFO: "RESET_ADMIN_INFO",
};

export default companyConstants;
