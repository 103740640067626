import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12 2C6.45312 2 2 6.49219 2 12C2 17.5469 6.45312 22 12 22C17.5078 22 22 17.5469 22 12C22 6.49219 17.5078 2 12 2ZM12 20.125C7.50781 20.125 3.875 16.4922 3.875 12C3.875 7.54688 7.50781 3.875 12 3.875C16.4531 3.875 20.125 7.54688 20.125 12C20.125 16.4922 16.4531 20.125 12 20.125ZM12 13.875C12.5078 13.875 12.9375 13.4844 12.9375 12.9375V7.9375C12.9375 7.42969 12.5078 7 12 7C11.4531 7 11.0625 7.42969 11.0625 7.9375V12.9375C11.0625 13.4844 11.4531 13.875 12 13.875ZM12 15.2031C11.2969 15.2031 10.75 15.75 10.75 16.4141C10.75 17.0781 11.2969 17.625 12 17.625C12.6641 17.625 13.2109 17.0781 13.2109 16.4141C13.2109 15.75 12.6641 15.2031 12 15.2031Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};
