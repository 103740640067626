export const jobConstants = {
	GET_JOBS: "GET_JOBS",
	SET_TEMP_FIELD_VALUE: "SET_TEMP_FIELD_VALUE",
	SET_LEVELS: "SET_LEVELS",
	SET_FILE_PER_LEVEL: "SET_FILE_PER_LEVEL",
	SET_BUILDING_ID: "SET_BUILDING_ID",
	SET_LODS: "SET_LODS",
	UPDATE_LODS: "UPDATE_LODS",
	CLEAR_TEMP_JOB: "CLEAR_TEMP_JOB",
	SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
	JOB_ACTION_SUCCESS: "JOB_ACTION_SUCCESS",
	JOB_ACTION_FAILURE: "JOB_ACTION_FAILURE",
	CLEAR_JOB_REQUEST_STATE: "CLEAR_JOB_REQUEST_STATE",
	SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
	JOB_STATS: "JOB_STATS",
	SET_EXPORTS: "SET_EXPORTS",
	GET_JOB_STATUS: "GET_JOB_STATUS",
	SET_JOB_DESCRIPTION: "SET_JOB_DESCRIPTION",
	GET_JOB: "GET_JOB",
	SET_TEMP_JOB: "SET_TEMP_JOB",
	GET_COMMENTS: "GET_COMMENTS",
	SET_INIT_JOB_DESCRIPTION: "SET_INIT_JOB_DESCRIPTION",
	GET_JOB_FILES: "GET_JOB_FILES",
	GET_JOB_STATUS_FILE: "GET_JOB_STATUS_FILE",
	CHANGE_FILE_URL: "CHANGE_FILE_URL",
	CHANGE_FORGE_VIEWER_URN: "CHANGE_FORGE_VIEWER_URN",
	UPDATE_BIMIFY_PROGRESS: "UPDATE_BIMIFY_PROGRESS",
	CLEAR_JOB: "CLEAR_JOB",
	GET_JOB_SPECIFICATION: "GET_JOB_SPECIFICATION",
	UPDATE_TEMP_JOB_ESTIMATION: "UPDATE_TEMP_JOB_ESTIMATION",
	RESET_TEMP_JOB_ESTIMATION: "RESET_TEMP_JOB_ESTIMATION",
	RESET_TEMP_JOB: "RESET_TEMP_JOB",
	RE_CALCULATE_TEMP_JOB_SPECIFICATION: "RE_CALCULATE_TEMP_JOB_SPECIFICATION",
	ADD_TEMP_ASSIGNED_USER: "ADD_TEMP_ASSIGNED_USER",
	REMOVE_TEMP_ASSIGNED_USER: "REMOVE_TEMP_ASSIGNED_USER",
	SET_TEMP_ASSIGNED_USERS: "SET_TEMP_ASSIGNED_USERS",
	UPDATE_TEMP_ASSIGNED_USER: "UPDATE_TEMP_ASSIGNED_USER",
	RESET_TEMP_ASSIGNED_USERS: "RESET_TEMP_ASSIGNED_USERS",
	CLEAR_TEMP_ASSIGNED_USERS: "CLEAR_TEMP_ASSIGNED_USERS",
	GET_ASSIGNED_USERS: "GET_ASSIGNED_USERS",
	ADD_TEMP_DELIVERY_DATE: "ADD_TEMP_DELIVERY_DATE",
	GET_DELIVERY_DATE: "GET_DELIVERY_DATE",
	GET_JOB_COMPANIES: "GET_JOB_COMPANIES",
	GET_JOB_USERS: "GET_JOB_USERS",
	UPDATE_ASYNC_LOAD_JOBS: "UPDATE_ASYNC_LOAD_JOBS",
	GET_JOB_VIEWER: "GET_JOB_VIEWER",
	CLEAR_JOB_ESTIMATION: "CLEAR_JOB_ESTIMATION",
	SET_EXPORT_ESTIMATIONS: "SET_EXPORT_ESTIMATIONS",
	UPDATE_TEMP_EXPORT_ESTIMATIONS: "UPDATE_TEMP_EXPORT_ESTIMATIONS",
	RE_CALCULATE_TEMP_EXPORT_ESTIMATIONS: "RE_CALCULATE_TEMP_EXPORT_ESTIMATIONS",
	SET_SERVICE_ESTIMATIONS: "SET_SERVICE_ESTIMATIONS",
	UPDATE_TEMP_SERVICE_ESTIMATIONS: "UPDATE_TEMP_SERVICE_ESTIMATIONS",
	RE_CALCULATE_TEMP_SERVICE_ESTIMATIONS:
		"RE_CALCULATE_TEMP_SERVICE_ESTIMATIONS",
	RESET_TEMP_SERVICE_ESTIMATION: "RESET_TEMP_SERVICE_ESTIMATION",
	RESET_TEMP_EXPORT_ESTIMATION: "RESET_TEMP_EXPORT_ESTIMATION",
	UPDATE_TEMP_JOB_ESTIMATION_BIMIFY_MODEL_SAVING:
		"UPDATE_TEMP_JOB_ESTIMATION_BIMIFY_MODEL_SAVING",
	RESET_JOB_EXPORTS: "RESET_JOB_EXPORTS",
};
