import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import localize, { LocKeys } from "../../../../../constants/localizations";
import CustomSkeleton from "../../../../../shared/components/skeleton/CustomSkeleton";
import ComponentCategoryView from "../../../../lod-specification/crud/parts/ComponentCategoryView";
import ComponentCategoryContentForOffer from "../../../../lod-specification/crud/parts/ComponentCategoryViewForOffer";
import CustomAccordion from "../../../../../shared/CustomAccordion/CustomAccordion";
import { getDisciplineComponentCategoryLodContent } from "../../../../../actions";

/**
 * Single specification offer view - render discipline, component categories and lods
 */
const SingleSpecification = (props) => {
	const {
		disciplines,
		disciplineComponentCategoryLods,
		isForOffer,
		getDisciplineComponentCategoryLodContent,
	} = props;
	const skeletonItems = new Array(6).fill(0);

	const renderAccordionTitle = (category, lod, isNone) => {
		return (
			<div
				className={`row row--no-gutters w-100 align-items-center pl-32 pr-regular ${
					isNone ? "color-n300" : ""
				}`}
			>
				<div className="col col-50 pl-0 pr-0">{category}</div>
				<div className={`${isNone ? "" : "col col-50 pr-0 pl-regular"}`}>
					{isNone ? localize(LocKeys.NONE) : `${localize(LocKeys.LOD)} ${lod}`}
				</div>
			</div>
		);
	};

	const renderComponentCategoryView = (data) => {
		const { content, discipline, componentCategory } = data;
		if (content && isForOffer) {
			return <ComponentCategoryContentForOffer content={content} />;
		} else {
			return (
				<ComponentCategoryView
					discipline={discipline}
					componentCategory={componentCategory}
				/>
			);
		}
	};

	const onExpand = (isExpanded, params) => {
		if (isForOffer) {
			return;
		}

		const { disciplineId, componentCategoryId, lod } = params;
		if (isExpanded) {
			getDisciplineComponentCategoryLodContent(
				disciplineId,
				componentCategoryId,
				lod
			);
		}
	};

	return disciplineComponentCategoryLods &&
		disciplines &&
		disciplineComponentCategoryLods?.length > 0
		? disciplines?.valueSeq().map((discipline, index) => {
				const filterDisciplineComponentCategoryLods =
					disciplineComponentCategoryLods.filter(
						(item) => item?.discipline?.id === discipline.get("id")
					);

				return (
					<div className="w-100" key={index}>
						<div className="w-100 ">
							{filterDisciplineComponentCategoryLods[0]?.discipline?.type ? (
								<div className="bg-n100 w-100 py-m border-b-1 border-n150  pl-regular">
									<h5 className="color-n500 uppercase">
										{filterDisciplineComponentCategoryLods[0]?.discipline.type}
									</h5>
								</div>
							) : (
								<div className="w-100 border-b-1 border-n150">
									<Typography style={{ width: "100%" }} variant="h4">
										<CustomSkeleton />
									</Typography>
								</div>
							)}

							<div className="border-b-1 border-n150 pt-m">
								<div className="row row--no-gutters w-100 align-items-center pb-s pl-32 pr-20">
									<div className="col col-50 pl-0 pr-0">
										<h5 className="small color-n300">
											{localize(LocKeys.COMPONENT_NAME)}
										</h5>
									</div>
									<div className="col col-50 pl-0 pr-0">
										<h5 className="small color-n300">
											{localize(LocKeys.LOD)}
										</h5>
									</div>
								</div>
							</div>

							{
								//render all component categories with specific content(3D, 2D, summary, categories) for selected lod
								filterDisciplineComponentCategoryLods &&
								filterDisciplineComponentCategoryLods.length > 0
									? filterDisciplineComponentCategoryLods.map(
											(disciplineComponentCategoryLod, i) => {
												const discipline =
													disciplineComponentCategoryLod?.discipline;
												const componentCategory =
													disciplineComponentCategoryLod?.componentCategory;
												const lod = disciplineComponentCategoryLod?.lod;

												const content =
													disciplineComponentCategoryLod?.content || null;

												const isNone =
													disciplineComponentCategoryLod.lod.type === "0";

												return (
													<div
														key={i}
														className={`w-100 border-b-1 border-n150 ${
															isNone ? "bg-inactive" : ""
														}  `}
													>
														<CustomAccordion
															bgColor={!isNone ? "bg-n100" : ""}
															//	titleColor={!isNone ? "color-blue--dark" : ""}
															wrapperClasses={`accordion--none w-100 accordion-default`}
															titleContent={renderAccordionTitle(
																disciplineComponentCategoryLod
																	?.componentCategory.type,
																disciplineComponentCategoryLod?.lod.type,
																isNone
															)}
															customOnChange={(isExpanded) => {
																onExpand(isExpanded, {
																	disciplineId: discipline.id,
																	componentCategoryId: componentCategory.id,
																	lod,
																});
															}}
														>
															{isNone ? (
																""
															) : (
																<div className="w-100 border-t-1 border-n150">
																	{renderComponentCategoryView({
																		content,
																		discipline,
																		componentCategory,
																	})}
																</div>
															)}
														</CustomAccordion>
													</div>
												);
											}
										)
									: skeletonItems.map((_, index) => {
											return (
												<div className="w-100" key={index}>
													<div className="w-100 border-b-1 border-n150">
														<div className="d-flex w-100 row row--6p-gutters align-items-center pl-32 pr-20">
															<div className="col col-50">
																<Typography
																	style={{ width: "100%" }}
																	variant="h4"
																>
																	<CustomSkeleton />
																</Typography>
															</div>
															<div className="col col-50">
																<Typography
																	style={{ width: "100%" }}
																	variant="h4"
																>
																	<CustomSkeleton />
																</Typography>
															</div>
														</div>
													</div>
												</div>
											);
										})
							}
						</div>
					</div>
				);
			})
		: skeletonItems.map((_, index) => {
				return (
					<div className="w-100" key={index}>
						<div className="bg-n100 w-100  border-b-1 border-n150">
							<Typography style={{ width: "100%" }} variant="h4">
								<CustomSkeleton />
							</Typography>
						</div>
						<div className="border-b-1 border-n150 pt-m">
							<div className="d-flex w-100 row row--6p-gutters align-items-center pb-s">
								<div className="col col-25">
									<Typography style={{ width: "100%" }} variant="h4">
										<CustomSkeleton />
									</Typography>
								</div>
								<div className="col col-15">
									<Typography style={{ width: "100%" }} variant="h4">
										<CustomSkeleton />
									</Typography>
								</div>
								<div className="col col-60">
									<Typography style={{ width: "100%" }} variant="h4">
										<CustomSkeleton />
									</Typography>
								</div>
							</div>
						</div>
						<div className="border-b-1 border-n150">
							<div className="d-flex w-100 row row--6p-gutters align-items-center py-regular">
								<div className="col col-25">
									<Typography style={{ width: "100%" }} variant="h4">
										<CustomSkeleton />
									</Typography>
								</div>
								<div className="col col-15">
									<Typography style={{ width: "100%" }} variant="h4">
										<CustomSkeleton />
									</Typography>
								</div>

								<div className="col col-60">
									<Typography style={{ width: "100%" }} variant="h4">
										<CustomSkeleton />
									</Typography>
								</div>
							</div>
						</div>
					</div>
				);
			});
};

const mapStateToProps = (state) => {
	return {
		disciplines: state.getIn(["administration", "disciplines"]),
	};
};
export default connect(mapStateToProps, {
	getDisciplineComponentCategoryLodContent,
})(SingleSpecification);
