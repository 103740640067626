import React, { useEffect, useState } from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { ExpandMoreSharp } from "@mui/icons-material";
import clsx from "clsx";
import localize, { LocKeys } from "../../constants/localizations";
import CustomLabel from "./CustomLabel";
import { getStatusIconWrapper } from "../../shared/components/status/getStatusIcon";
import { statusConstants } from "../../constants/constants";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
	paper: {
		borderRadius: 6,
		marginTop: 3,
	},
}));

const CustomStatusSelect = ({
	input,
	initialValue,
	options,
	customOnChange,
	required,
	disabled,
	showPlaceholder,
	label,
	isFilter = false,
	openDropdown = false,
	classField = "",
	canUpdateState = true,
}) => {
	const [val, setVal] = useState(initialValue);
	const [open, setOpen] = useState(openDropdown);

	useEffect(() => {
		setVal(initialValue);
	}, [initialValue]);

	const handleChange = (e) => {
		e.stopPropagation();
		const newValue = e.target.value;

		canUpdateState && setVal(newValue);
		customOnChange && customOnChange(newValue);
	};

	const handleClose = (e) => {
		e.stopPropagation();
		setOpen(false);
	};
	const handleOpen = () => {
		setOpen(true);
	};

	const classes = useStyles();

	const menuProps = {
		classes: {
			paper: classes.paper,
		},
		disableScrollLock: true,
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left",
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left",
		},
	};

	const renderValue = (value) => {
		if (!value) {
			return (
				showPlaceholder && (
					<div className="w-24p">{localize(LocKeys.ALL)}...</div>
				)
			);
		}

		return (
			<div className="d-flex align-items-center w-100">
				{getStatusIconWrapper(value, "mr-s")}
				<div className="text-overflow-ellipsis">
					{value === statusConstants.QA
						? value.toUpperCase()
						: value.charAt(0).toUpperCase() + value.slice(1)}
				</div>
			</div>
		);
	};

	let children = [];

	if (!options || options.length === 0) {
		children = [
			<MenuItem
				key={"no-options"}
				classes={{
					root: "dropdown-item",
					selected: "dropdown-item--selected",
				}}
				value={null}
				disabled
			>
				{localize(LocKeys.NO_OPTIONS_AVAILABLE)}
			</MenuItem>,
		];
	} else {
		children = options.map((option) => {
			return (
				<MenuItem
					key={option.value}
					classes={{
						root: `dropdown-item ${
							option.disabled ? "dropdown-item--disabled d-none-imp" : ""
						}`,
						selected: "dropdown-item--selected",
					}}
					value={option.value}
					disabled={option.disabled}
				>
					{option.label}
				</MenuItem>
			);
		});
		if (isFilter) {
			children = [
				<MenuItem
					key={"clear-selection"}
					classes={{
						root: "dropdown-item dropdown-item--clear",
						selected: "dropdown-item--selected--clear",
					}}
					value={""}
				>
					{localize(LocKeys.CLEAR_SELECTION)}
				</MenuItem>,
			].concat(children);
		}
	}

	return (
		<FormControl variant="standard" className={clsx(`form__input`, classField)}>
			{label && <CustomLabel label={label} required={required} />}
			<Select
				{...input}
				value={val}
				displayEmpty={true}
				disabled={disabled}
				open={open}
				onChange={(event) => handleChange(event)}
				onClose={(event) => handleClose(event)}
				onOpen={handleOpen}
				disableUnderline
				children={children ? children : null}
				renderValue={(value) => renderValue(value)}
				IconComponent={
					disabled
						? () => null
						: (props) => (
								<div className={`icon__status icon__status--${val}`}>
									<ExpandMoreSharp {...props} />
								</div>
							)
				}
				MenuProps={menuProps}
				classes={{
					root: `field__root field__root--status ${
						disabled ? "px-24" : ""
					} field__root--status--${val} `,
					icon: "field__select__icon",
				}}
			/>
		</FormControl>
	);
};

export default CustomStatusSelect;
