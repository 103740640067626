import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="25"
				height="24"
				viewBox="0 0 25 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					opacity="0.2"
					d="M12.5 22C10.5222 22 8.58879 21.4135 6.9443 20.3147C5.29981 19.2159 4.01809 17.6541 3.26121 15.8268C2.50433 13.9996 2.3063 11.9889 2.69215 10.0491C3.078 8.10929 4.03041 6.32746 5.42894 4.92894C6.82746 3.53041 8.60929 2.578 10.5491 2.19215C12.4889 1.8063 14.4996 2.00433 16.3268 2.76121C18.1541 3.51809 19.7159 4.79981 20.8147 6.4443C21.9135 8.08879 22.5 10.0222 22.5 12C22.497 14.6513 21.4425 17.1931 19.5678 19.0678C17.6931 20.9425 15.1513 21.997 12.5 22ZM12.5 4.5C11.0166 4.5 9.5666 4.93987 8.33323 5.76398C7.09986 6.58809 6.13856 7.75943 5.57091 9.12988C5.00325 10.5003 4.85473 12.0083 5.14411 13.4632C5.4335 14.918 6.14781 16.2544 7.1967 17.3033C8.2456 18.3522 9.58197 19.0665 11.0368 19.3559C12.4917 19.6453 13.9997 19.4968 15.3701 18.9291C16.7406 18.3614 17.9119 17.4001 18.736 16.1668C19.5601 14.9334 20 13.4834 20 12C19.998 10.0115 19.2072 8.10499 17.8011 6.6989C16.395 5.2928 14.4885 4.50199 12.5 4.5Z"
					fill="#F79400"
				/>
				<path
					d="M22.5 12H20C19.998 10.0115 19.2072 8.10498 17.8011 6.69889C16.395 5.2928 14.4885 4.50199 12.5 4.5V2C15.1513 2.00298 17.6931 3.0575 19.5678 4.93222C21.4425 6.80694 22.497 9.34875 22.5 12Z"
					fill="#F79400"
				/>
			</svg>
		</div>
	);
};
