import buildingConstants from "../constants/buildingConstants";
import { api } from "../api";
import history from "../history";
import axios from "axios";
import localize, { LocKeys } from "../constants/localizations";
import { getItemFromLocalStorage, getToken } from "../utils/local-storage.util";
import { change, destroy } from "redux-form";
import { getGeocode } from "use-places-autocomplete";
import { setDisciplineLevels } from "./jobActions";
import { statusConstants } from "../constants/constants";
import { getPagination, getQuery } from "../utils/table-filter";

/**
 * Retrieves all buildings
 *
 * SUCCESS:
 *    1. Populates buildings.buildingsList with a building array
 *    2. Populates buildings.buildingsMeta with the response meta info (limit, total, offset, page)
 *
 * ERROR: Triggers buildingActionFailure()
 *
 * @param {Object} filters
 * @param {boolean} usePagination
 * @param {number} filters.page
 * @param {number} filters.limit
 * @param {string} searchQuery
 * @param {object} filters.searchFilters - object that contains status and trashed attributes
 * @param {number} customer -
 * @returns http response with array of buildings
 */

export const getBuildings = ({
	usePagination = true,
	page = 1,
	searchQuery = "",
	searchFilters = null,
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
	limit,
	clearSingleBuilding = false,
	isForSelectField = false,
	cbSelectField = () => {},
	isForBuildingList = true,
}) => {
	return (dispatch, getState) => {
		const state = getState();

		const buildingQueryParameter = state.getIn([
			"queryParameter",
			"buildingQueryParameter",
		]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { filters, search = "" } = getQuery({
			queryParameter: buildingQueryParameter,
			searchFilters,
			searchQuery,
		});
		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});

		const data = {
			page: usePagination ? currentPage : page,
			search: usePagination ? search : searchQuery,
			limit: usePagination ? currentLimit : limit,
			filters: usePagination ? filters : searchFilters,
			usePagination,
			selectedCompany: customer,
			resellerCompanyId,
		};

		if (isForSelectField) {
			data.filters = null;
			data.search = searchQuery;
		}

		const requestOptions = {
			method: "PUT",
			url: `/building`,
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
			data,
		};

		return api(requestOptions).then(
			(res) => {
				if (!!isForSelectField) {
					cbSelectField(res.data?.result || []);
				} else {
					dispatch({ type: buildingConstants.GET_BUILDINGS, data: res.data });
					if (clearSingleBuilding) {
						dispatch({ type: buildingConstants.CLEAR_BUILDING });
					}

					isForBuildingList &&
						dispatch(
							getBuildingStats({
								customer,
								resellerCompanyId,
								filters,
								search,
							})
						);
				}
			},
			(err) => {
				if (!err.isCancel) {
					if (err.response?.data?.message) {
						dispatch(buildingActionFailure(err.response?.data?.message));
					}
				}
			}
		);
	};
};

/**
 * Retrieves Building Stats
 *
 * SUCCESS: Populates 'stats' in the building state.
 *
 * @returns array of building stats
 */
export const getBuildingStats = ({
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
	filters,
	search,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: "/building-stats",
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
			data: {
				customer,
				filters,
				search,
				resellerCompanyId,
			},
		};

		return {
			response: api(requestOptions).then(
				(res) => {
					let data = res.data;
					dispatch({ type: buildingConstants.BUILDING_STATS, data });
				},
				(err) => {
					dispatch(buildingActionFailure(err.response?.data?.message));
				}
			),
		};
	};
};

/**
 * CREATE building
 *
 * SUCCESS:
 * 		1. getBuildings
 * 		2. buildingActionSuccess && setBuildingSuccessMessage
 * 		3. clearTempBuilding
 *
 * @param {*} data - Contains all building form data in a key-value pair
 *
 * @returns
 *
 */
export const createBuilding = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/building`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getBuildings({}));
				dispatch(
					setBuildingSuccessMessage(
						localize(LocKeys.ITEM_CREATED, [localize(LocKeys.BUILDING)])
					)
				);

				dispatch(clearTempBuilding());
			},
			(err) => {
				dispatch(
					buildingActionFailure(
						localize(LocKeys.ERROR_ITEM_CREATED, [localize(LocKeys.BUILDING)])
					)
				);
			}
		);
	};
};

/**
 * EDIT building
 *
 *  * SUCCESS:
 * 		1. getBuilding(id)
 * 		2. buildingActionSuccess && setBuildingSuccessMessage
 *
 * @param {*} data - Contains all building form data in a key-value pair
 * @param {*} buildingId - unique building identifier
 * @returns
 */
export const editBuilding = (data, buildingId, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/building/${buildingId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getBuilding({ id: buildingId }));
				dispatch(
					setBuildingSuccessMessage(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.BUILDING)])
					)
				);

				cb();
			},
			(err) => {
				dispatch(
					buildingActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.BUILDING)])
					)
				);
			}
		);
	};
};

/**
 * Used to retrieve a single building based on the provided id
 *
 * SUCCESS:
 *    1. Populates buildings.building with the response building object
 *    2. Triggers setBuildingAddress() with the stringified address value from the building object
 *
 * ERROR: Triggers buildingActionFailure()
 *
 * @param {number} id - unique building identifier
 * @param {boolean} forJob - true/false - set levels per discipline inside temp. job object in the job state
 * @param {*} cb - callback function
 * @param {boolean} permission - true/false - permission to view the building
 * @returns http response with appropriate building object on success
 */
export const getBuilding = ({
	id,
	forJob = false,
	cb = () => {},
	permission = false,
}) => {
	return (dispatch) => {
		let url = !permission
			? `/building/${id}`
			: `/building/${id}?permission=true`;
		const requestOptions = {
			method: "GET",
			url: url,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (forJob) {
					dispatch(setDisciplineLevels(res.data?.result));
				} else {
					res.data.result?.address?.formattedAddress &&
						getGeocode({
							address: res.data.result.address.formattedAddress,
						}).then((results) => {
							dispatch(setBuildingAddress(JSON.stringify(results)));
						});

					dispatch({
						type: buildingConstants.GET_BUILDING,
						data: res.data,
					});
				}
			},
			(err) => {
				cb();
				dispatch(buildingActionFailure(err.response?.data?.message));
			}
		);
	};
};

/**
 * Get FORGE public access token for read permission
 *
 * SUCCESS: Populates 'building.forgeAuth' in the building state
 *
 * @returns token
 */
export const getForgePublicToken = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/file/revit-forge/oauth/public`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: buildingConstants.GET_FORGE_PUBLIC_TOKEN,
					data: res.data.result,
				});
			},
			(err) => {
				console.log(err.response.data.message);
			}
		);
	};
};

/**
 * Get model translation status from AUTODESK API
 * @param {string} urn - model name (urn type)
 * @param {string} access_token - token authentication
 * @returns
 */

export const getModelTranslationStatus = (urn, access_token) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `https://developer.api.autodesk.com/derivativeservice/v2/regions/eu/manifest/${urn}`,
			headers: {
				Authorization: "Bearer " + access_token,
			},
		};

		return axios(requestOptions).then(
			(res) => {
				return res.data;
			},
			(err) => {
				return err;
			}
		);
	};
};

export const updateModelThumbnail = (urn, buildingId, deliverableId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/job/revit-forge/thumbnail`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				urn,
				buildingId,
				deliverableId,
			},
		};

		return api(requestOptions).then(
			(res) => {
				dispatch(getBuilding({ id: buildingId }));
			},
			(err) => {
				return err;
			}
		);
	};
};

/**
 * Update building status (single & mutlipple buildings)
 *
 * SUCCESS:
 * 	 1. buildingActionSuccess && setBuildingSuccessMessage
 * 	 2. getBuildings
 *
 *
 * @param {Array<number>} ids  -  building unique identifier
 * @param {*} status - building status
 * @param {*} timestamp - date & time
 * @returns
 */
export const updateBuildingStatus = ({
	ids,
	status,
	date = null,
	orderId = null,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/building-status-multiple`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: { status, ids, date },
		};
		return api(requestOptions).then(
			() => {
				!orderId
					? dispatch(getBuildings({}))
					: dispatch(
							getBuildings({
								searchFilters: { orderIds: orderId ? [+orderId] : [] },
							})
						);

				switch (status) {
					case statusConstants.ARCHIVED:
						dispatch(
							setBuildingSuccessMessage(
								localize(LocKeys.ITEM_ARCHIVE_SUCCESS, [
									localize(LocKeys.BUILDING),
								])
							)
						);

						break;

					default:
						dispatch(
							setBuildingSuccessMessage(localize(LocKeys.STATUS_UPDATED))
						);
						break;
				}
			},

			(err) => {
				history.push("/buildings");
				dispatch(buildingActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Archive or activate building - change building status and send to endpoint
 *
 *  SUCCESS: Updates 'buildingsList' in the company state.
 *
 * @param {*} isActive
 * @param {*} ids - Unique building identifiers
 * @returns
 */
export const toggleBuildingStatus = (isActive, ids) => {
	let urlPart = "archive";

	let responseMessage = localize(LocKeys.ITEM_ARCHIVED, [
		localize(LocKeys.BUILDING),
	]);

	if (!isActive) {
		urlPart = "activate";

		responseMessage = localize(LocKeys.ITEM_ACTIVE_SUCCESS, [
			localize(LocKeys.BUILDING),
		]);
	}
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/building-${urlPart}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids: ids },
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getBuildings({}));

				dispatch(setBuildingSuccessMessage(responseMessage));
			},
			(err) => {
				history.push("/buildings");
				dispatch(buildingActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Soft DELETE building
 *
 * SUCCESS: Updates 'buildingsList' in the building state.
 *
 * @param {*} ids - Unique company identifiers
 *
 */
export const deleteBuilding = (ids) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/building-soft-delete`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids: ids },
		};
		return api(requestOptions).then(
			(res) => {
				const filters = {
					status: [statusConstants.ARCHIVED],
				};
				dispatch(getBuildings({ searchFilters: filters }));

				dispatch(
					setBuildingSuccessMessage(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.BUILDING)])
					)
				);
			},
			(err) => {
				history.push("/buildings");
				dispatch(
					buildingActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.BUILDING)])
					)
				);
			}
		);
	};
};

/**
 * Get building specification
 *
 * @param {number} id - unique building identifier
 * @returns
 */
export const getBuildingSpecification = ({ id }) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/building/${id}/specs`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: buildingConstants.GET_BUILDING_SPECIFICATION,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(buildingActionFailure(err.response?.data?.message));
			}
		);
	};
};

/**
 * GET building users
 * @param {number} buildingId - unique building identifier
 * @returns
 */
export const getBuildingUsers = (buildingId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/building-users/${buildingId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: buildingConstants.GET_BUILDING_USERS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(buildingActionFailure(err.response?.data?.message));
			}
		);
	};
};

/**
 * GET building companies
 * @param {number} buildingId - unique building identifier
 * @returns
 */
export const getBuildingCompanies = (buildingId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/building-companies/${buildingId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: buildingConstants.GET_BUILDING_COMPANIES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(buildingActionFailure(err.response?.data?.message));
			}
		);
	};
};

//---------------------------------END ENDPOINTS----------------------------------------------

//---------------------------------BUILDING STATE FUNCTION-------------------------------------

/**
 * Update building LEVEL information
 * @param {number} levelsFrom
 * @param {number} levelsTo
 * @param {number} height
 * @returns
 */
export const updateBuildingLevelInfo = (levelsFrom, levelsTo, height) => {
	return (dispatch) => {
		let data = {
			levelsFrom,
			levelsTo,
			height,
		};

		dispatch({
			type: buildingConstants.UPDATE_BUILDING_LEVELS,
			data,
		});
	};
};

/**
 * Set initial building levels information to temp building state
 * @param {*} formName
 * @returns
 */
export const initialBuildingLevelInfo = (formName) => {
	return (dispatch) => {
		dispatch(change(formName, "levelFrom", -2));
		dispatch(change(formName, "levelTo", 2));
		dispatch(change(formName, "defaultLevelHeight", 3000));

		dispatch({
			type: buildingConstants.INITIAL_BUILDING_LEVELS,
		});
	};
};

/**
 * Update building FLOOR data
 * @param {*} floorKey
 * @param {*} stateKey
 * @param {*} value
 * @param {*} top
 * @returns
 */
export const updateBuildingLevel = (floorKey, stateKey, value, top = false) => {
	return (dispatch) => {
		let data = { floorKey, stateKey, value, top };
		dispatch({
			type: buildingConstants.UPDATE_BUILDING_LEVEL,
			data,
		});
	};
};

/**
 * Clear temp building values
 * @returns
 */
export const clearTempBuilding = (formName = "createBuildingForm") => {
	return (dispatch) => {
		dispatch(destroy(formName));
		dispatch({ type: buildingConstants.CLEAR_TEMP_BUILDING });
	};
};

/**
 * Dynamic setting of building temp state parameters
 * @param {string} stateKey - used to assign the extracted value to the appt. temp state
 * @param {*} value - value to set the temp stateKey to
 */
export const setBuildingTempFieldValue = (stateKey, value) => {
	let isValid = false;

	switch (stateKey) {
		case "size":
			isValid = +value >= 1;
			break;

		default:
			isValid = !!value;
			break;
	}

	let data = {
		stateKey,
		value,
		isValid,
	};

	return {
		type: buildingConstants.SET_STATE_BUILDING_FIELD_VALUE,
		data,
	};
};

/**
 * Set building levels to temp building state
 * @param {*} formName
 * @returns
 */
export const setTempBuildingLevels = (formName) => {
	return (dispatch, getState) => {
		const state = getState();
		let building = state.getIn(["building", "building"]);
		dispatch(change(formName, "levelFrom", building?.get("levelFrom")));
		dispatch(change(formName, "levelTo", building?.get("levelTo")));
		dispatch(
			change(
				formName,
				"defaultLevelHeight",
				Number(building?.get("defaultLevelHeight"))?.toFixed(0)
			)
		);

		dispatch({
			type: buildingConstants.SET_TEMP_BULDING_LEVELS,
		});
	};
};

/**
 * Set building information to temp building state
 * @param {*} formName
 * @returns
 */
export const setTempBuildingInfo = (formName) => {
	return (dispatch, getState) => {
		const state = getState();
		let building = state.getIn(["building", "building"]);
		dispatch(change(formName, "size", building?.get("size")));
		dispatch(change(formName, "name", building?.get("name")));
		dispatch(change(formName, "type", building?.getIn(["type", "id"])));
		dispatch(change(formName, "customer", building?.getIn(["customer", "id"])));

		dispatch({
			type: buildingConstants.SET_TEMP_BULDING_INFO,
		});
	};
};

/**
 * Update forge URN
 * @param {*} data
 * @returns
 */
export const changeBuildingForgeViewerUrn = (data) => {
	return { type: buildingConstants.CHANGE_FORGE_VIEWER_URN, data };
};

/**
 * Update file URL
 * @param {*} data
 * @returns
 */
export const changeBuildingFileViewerUrl = (data) => {
	return { type: buildingConstants.CHANGE_FILE_URL, data };
};

/**
 * Set message - forge file not supported
 * @param {*} data
 * @returns
 */
export const setForgeFileNotSupportedMessage = (data) => {
	return { type: buildingConstants.SET_FORGE_FILE_NOT_SUPPORTED_MESSAGE, data };
};

/**
 * Clear discipline file state
 * @returns
 */
export const clearDisciplineFile = () => {
	return { type: buildingConstants.CLEAR_DISCIPLINE_FILE };
};

export const setBuildingSuccessMessage = (data) => {
	return { type: buildingConstants.BUILDING_SET_SUCCESS_MESSAGE, data };
};

// export const buildingActionSuccess = () => {
// 	return { type: buildingConstants.B_ACTION_SUCCESS };
// };

export const buildingActionFailure = (error) => {
	return { type: buildingConstants.B_ACTION_FAILURE, error };
};

export const setBuildingAddress = (address) => {
	return { type: buildingConstants.SET_FULL_ADDRESS_BUILDING, address };
};

export const clearBuildingRequestState = () => {
	return { type: buildingConstants.CLEAR_BUILDING_REQUEST_STATE };
};

/**
 * UPDATE ASYNC LOAD BUILDINGS
 * @param {Array} list - array of buildings
 * @param {object|Array} values - input values
 * @param {boolean} isForSingleView - true/false - single or multi select
 * @returns
 */
export const updateAsyncLoadBuildings = (list, values, isForSingleView) => {
	return (dispatch) => {
		dispatch({
			type: buildingConstants.UPDATE_ASYNC_LOAD_BUILDINGS,
			data: { list, values, isForSingleView },
		});
	};
};

export const clearBuildingList = () => {
	return (dispatch) => {
		dispatch({
			type: buildingConstants.CLEAR_BUILDING_LIST_STATE,
		});
	};
};

export const clearAllBuildingState = () => {
	return (dispatch) => {
		dispatch({
			type: buildingConstants.CLEAR_ALL_BUILDING_STATE,
		});
	};
};

export const clearBuildingUsers = () => {
	return (dispatch) => {
		dispatch({
			type: buildingConstants.CLEAR_BUILDING_USERS,
		});
	};
};
