import React, { useEffect } from "react";
import { reduxForm } from "redux-form/immutable";
import localize, { LocKeys } from "../../../../constants/localizations";
import { connect } from "react-redux";
import HeaderLayout from "../../../layout/content/HeaderLayout";
import FileUploadBody from "./parts/step2/Step2.0-Body";
import { getDisciplines } from "../../../../actions";
import FileUploadFooter from "./parts/step2/FileUploadFooter";

const FileUpload = ({
	buildingId,
	//Props actions
	previousPage,
	nextPage,
	...props
}) => {
	const { getDisciplines } = props;

	useEffect(() => {
		getDisciplines({});
	}, [getDisciplines]);

	const onSubmit = () => {
		try {
			nextPage && nextPage();
		} catch (error) {
			console.log(error);
			return;
		}
	};

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<HeaderLayout wrapperClasses="px-24 d-flex align-items-center">
				<h3>{localize(LocKeys.FILE_UPLOAD)}</h3>
			</HeaderLayout>

			<FileUploadBody />

			<FileUploadFooter buildingId={buildingId} previousPage={previousPage} />
		</form>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, { getDisciplines })(
	reduxForm({
		form: "buildingUpdateSpecificationForm_2",
		touchOnBlur: false,
		destroyOnUnmount: true,
		enableReinitialize: false,
	})(FileUpload)
);
