import { MobileStepper, Step, StepLabel } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import React, { useState, useEffect, useCallback } from "react";
import { StepConnector } from "@mui/material";
import "./customStepper.scss";

const StepIcon = (props) => {
	const { active, completed, label } = props;

	return (
		<div
			className={`
				stepper__label
				${active ? "stepper__label--active" : ""}
				${completed ? "stepper__label--completed" : ""}
			`}
		>
			<h5 className={`small ${active || completed ? "color-n000" : ""}`}>
				{label}
			</h5>
		</div>
	);
};

const CustomStepper = (props) => {
	const [activeStep, setActiveStep] = useState(
		props.activeStep && props.activeStep.step
	);
	const [completed, setCompleted] = useState(
		props.completed ? props.completed : []
	);
	const [steps, setSteps] = useState(props.steps && props.steps);
	const { goTo, setStepCompleted } = props;

	const handleComplete = React.useCallback(
		(currentStep) => {
			steps.map((mappedStep) => {
				if (mappedStep.step < currentStep) {
					return setStepCompleted(mappedStep.number);
				}
				return null;
			});
		},
		[steps, setStepCompleted]
	);

	const handleStep = useCallback(
		(step) => {
			let goToTemp = steps.find((cpStep) => cpStep.step === step);
			if (goToTemp && goToTemp.step !== activeStep) {
				goTo(goToTemp.number);
				setActiveStep(goToTemp.step);
				handleComplete(goToTemp.step);
			}
		},
		[activeStep, goTo, handleComplete, steps]
	);

	useEffect(() => {
		setSteps(props.steps && props.steps);
		setCompleted(props.completed ? props.completed : []);
		handleStep(props.activeStep && props.activeStep.step);
	}, [props.activeStep, props.completed, handleStep, props.steps]);

	return (
		<>
			<div className="create-project-stepper">
				<div className="d-desktop-none">
					<MobileStepper
						classes={{
							root: "mobile-stepper",
						}}
						steps={steps.length}
						position="static"
						variant="text"
						activeStep={activeStep}
					>
						{steps &&
							steps
								.filter((step) => !step.hide)
								.map((step) => {
									const stepProps = {};
									return (
										<Step
											key={step.title}
											{...stepProps}
											classes={{
												root: "cp-step",
											}}
										>
											<StepLabel
												StepIconComponent={() => (
													<StepIcon
														label={step.step + 1}
														completed={completed.includes(step.number)}
														active={step.step === activeStep}
													/>
												)}
											>
												{step.title}
											</StepLabel>
										</Step>
									);
								})}
					</MobileStepper>
				</div>
				<div className="d-none d-desktop-block">
					<Stepper
						classes={{
							root: "stepper",
						}}
						connector={
							<StepConnector
								classes={{
									root: "connector",
									line: "line",
									completed: "connector--completed",
									active: "connector--active",
								}}
							/>
						}
						activeStep={activeStep}
					>
						{steps &&
							steps
								.filter((step) => !step.hide)
								.map((step) => {
									const stepProps = {};
									return (
										<Step
											key={step.title}
											{...stepProps}
											classes={{
												root: "cp-step",
											}}
										>
											<StepLabel
												StepIconComponent={() => (
													<StepIcon
														label={step.step + 1}
														completed={completed.includes(step.number)}
														active={step.step === activeStep}
													/>
												)}
											>
												<div
													className={`body-font ${
														step.step === activeStep
															? "color-n500"
															: "color-n300"
													}`}
												>
													{step.title}
												</div>
											</StepLabel>
										</Step>
									);
								})}
					</Stepper>
				</div>
			</div>
			<div className="create-project-stepper--helper"></div>
		</>
	);
};

export default CustomStepper;
