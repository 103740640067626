import Axios from "axios";
import {
	getFiles,
	setFileDetails,
	filePreview,
	setPackageTempFile,
	setContentImageTempFile,
	getActivityComments,
	clearActivityComment,
} from ".";
import { api, apiDO } from "../api";
//import { fileSizes } from "../constants/constants";
import textEditorConstants from "../constants/textEditorConstants";
import { getToken } from "../utils/local-storage.util";

/**
 * Initiate multipart upload for upload files on digital ocean
 * @param {object} data
 * @returns {object}
 */
export const initiateMultipartUpload = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: "/file/initiate-multipart-upload",
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};

		return api(requestOptions).then(
			(res) => {
				return res.data.result;
			},
			(err) => {
				console.log(err);
			}
		);
	};
};

/**
 * Get presigned post url for upload files on digital ocean
 * @param {object} data
 * @returns {object} post url with required fields
 */
export const getPresignedUrlParts = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: "/file/get-presigned-url-parts",
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				return res.data.result;
			},
			(err) => {
				console.log(err);
			}
		);
	};
};

/**
 * Upload file parts on digital ocean
 * @param {*} file
 * @param {*} urls
 * @param {*} onUploadProgress
 * @param {*} token
 * @returns
 */
export const uploadParts = (file, urls, onUploadProgress, token) => {
	return async (dispatch) => {
		// const maxSingleFileSize = fileSizes.MAX_SINGLE_FILE_SIZE;
		const maxSingleFileSize = 100 * 1024 * 1024;

		const axios = Axios.create();
		delete axios.defaults.headers.put["Content-Type"];

		const keys = Object.keys(urls);
		const promises = [];

		// keys.map((key) => {
		// 	const index = parseInt(key);
		// 	const start = index * maxSingleFileSize;
		// 	const end = (index + 1) * maxSingleFileSize;
		// 	// const blob =
		// 	// 	index < keys.length ? file.slice(start, end) : file.slice(start);

		// 	const blob =
		// 		index + 1 < keys.length ? file.slice(start, end) : file.slice(start);

		// 	const requestOptions = {
		// 		method: "PUT",
		// 		url: urls[index],
		// 		data: blob,
		// 		cancelToken: token,
		// 	};

		// 	return promises.push(apiDO(requestOptions));
		// });

		let index = 0;

		keys.map(() => {
			const start = Number(index) * Number(maxSingleFileSize);
			const end = (index + 1) * maxSingleFileSize;
			// const blob =
			// 	index < keys.length ? file.slice(start, end) : file.slice(start);

			const blob =
				index + 1 < keys.length ? file.slice(start, end) : file.slice(start);

			const requestOptions = {
				method: "PUT",
				url: urls[index + 1],
				data: blob,
				cancelToken: token,
			};
			index++;
			return promises.push(apiDO(requestOptions));
		});

		const resultParts = await allProgress(promises, onUploadProgress);

		const completed = resultParts.map((part, index) => ({
			ETag: part?.headers?.etag,
			PartNumber: index + 1,
		}));

		return completed;
	};
};

//Parts file upload - progress
const allProgress = async (promises, callback) => {
	let i = 0;
	callback((1 * 100) / promises.length);

	// for (const promise of promises) {
	// 	promise.then(() => {
	// 		i++;
	// 		callback((i * 100) / promises.length);
	// 	});
	// }

	promises.forEach((promise) => {
		promise
			.then(() => {
				i++;
				callback((i * 100) / promises.length);
			})
			.catch((err) => {
				console.log("all progress  error: ", err);
			});
	});

	return await Promise.all(promises);
};

/**
 * Complete multipart upload on digital ocean
 *
 * SUCCESS: call 'setFileDetails' function
 *       1. for orderId, buildingId && disciplineId,jobId get files endpoint
 * 		 2. for jobStatusId get file preview endpoint
 *
 * @param {object} data
 * @param {object} dbFileData
 * @param {Array} extensions
 *
 */
export const completeMultipartUpload = (
	data,
	dbFileData,
	extensions = false,
	preSignedPostUploadUrlBody
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: "/file/complete-multipart-upload",
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};

		return api(requestOptions).then(
			(_) => {
				dispatch(setFileDetails(dbFileData)).then(async (res) => {
					const {
						orderId,
						buildingId,
						disciplineId,
						jobId,
						jobStatusId,
						isForTextEditor = false,
					} = dbFileData;

					if (orderId) {
						dispatch(getFiles({ orderId }));
					}

					if (buildingId && disciplineId) {
						const usePagination = extensions ? false : true;
						dispatch(
							getFiles({
								buildingId,
								disciplineId,
								usePagination,
								extensions,
								limit: 7,
								showNormalised: dbFileData.isNormalised,
							})
						);
					}

					if (jobId) {
						if (jobStatusId) {
							const result = res.data.result;
							dispatch(
								filePreview({ jobId, jobStatusId, fileId: result.id }, true)
							);
						} else {
							dispatch(getFiles({ jobId, limit: 6 }));
						}
					}

					if (isForTextEditor) {
						const presignedPostUrlData = await dispatch(
							getPresignedPostUploadUrl(preSignedPostUploadUrlBody)
						);
						const url = presignedPostUrlData?.url || null;
						const { type } = dbFileData;

						const urlLink = url?.concat(`/${dbFileData.path}`);

						dispatch({
							type: textEditorConstants.SET_FILE_DATA,
							data: { urlLink, type },
						});
					}
				});
			},
			(err) => {
				console.log(err);
			}
		);
	};
};

/**
 * Get presigned post url for upload files on digital ocean
 * @param {object} data
 * @returns {object} post url with required fields
 */
export const getPresignedPostUploadUrl = (data) => {
	let url = "/file/signed-upload-url";

	if (!!data.entity) {
		url = "/file/signed-upload-urls";
	}

	return () => {
		const requestOptions = {
			method: "POST",
			url,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				return res.data.result;
			},
			(err) => {
				console.log(err);
			}
		);
	};
};

/**
 * Upload files on digital ocean
 *
 * SUCCESS: call 'setFileDetails' function
 *       1. for orderId, buildingId && disciplineId,jobId get files endpoint
 * 		 2. for jobStatusId get file preview endpoint
 *
 * @param {string} url
 * @param {object} form
 * @param {object} dbFileData
 * @param {*} onUploadProgress - function
 * @param {boolean} uploadToDB
 * @param {string} token
 * @param {Array} extensions
 * @returns
 */
export const uploadToDigitalOcean = ({
	url,
	//form,
	file,
	dbFileData,
	onUploadProgress,
	uploadToDB = true,
	token = null,
	extensions = false,
	cbDBUpload = () => {},
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url,
			//data: form,
			data: new Blob([file], { type: file.type }),
			onUploadProgress,
			cancelToken: token,
			headers: {
				"x-amz-acl": "public-read",
			},
		};

		return apiDO(requestOptions).then(
			(_) => {
				if (!uploadToDB) {
					return;
				}

				dispatch(setFileDetails(dbFileData, onUploadProgress)).then((res) => {
					const {
						orderId,
						buildingId,
						disciplineId,
						jobId,
						jobStatusId,
						isForPackage = false,
						isForTextEditor = false,
						disciplineComponentCategoryLodDescriptionId = null,
						threeDFile = null,
						twoDFile = null,
						isOrderPdf = false,
						ticketId = null,
						ticketCommentId = null,
						isBimifyTemplate = false,
						isUpdateModel = false,
						packageId = null,
					} = dbFileData;

					if (orderId && !isOrderPdf) {
						dispatch(getFiles({ orderId }));
					}
					const usePagination = extensions ? false : true;

					if (buildingId && disciplineId) {
						if (isUpdateModel) {
							cbDBUpload(res.data.result);
						} else {
							dispatch(
								getFiles({
									buildingId,
									disciplineId,
									usePagination,
									extensions,
									limit: 7,
									showNormalised: dbFileData.isNormalised,
								})
							);
						}
					}

					if (jobId) {
						if (jobStatusId) {
							const result = res.data.result;
							dispatch(
								filePreview({ jobId, jobStatusId, fileId: result.id }, true)
							);
						} else {
							dispatch(getFiles({ jobId, limit: 6 }));
						}
					}

					if (isForPackage) {
						const result = res.data.result;
						dispatch(setPackageTempFile(result));
					}

					if (isForTextEditor) {
						const result = res.data.result;
						const { type } = dbFileData;

						cbDBUpload({ urlLink: result.publicUrl });
						dispatch({
							type: textEditorConstants.SET_FILE_DATA,
							data: { urlLink: result.publicUrl, type: type || null },
						});
					}

					if (ticketId && !ticketCommentId) {
						cbDBUpload();
					}

					if (ticketId && !!ticketCommentId) {
						dispatch(clearActivityComment());
						dispatch(getActivityComments(ticketId));
					}

					if (disciplineComponentCategoryLodDescriptionId) {
						const result = res.data.result;
						dispatch(
							setContentImageTempFile({
								publicUrl: result.publicUrl,
								fileId: result.id,
								id: disciplineComponentCategoryLodDescriptionId,
								threeDFile,
								twoDFile,
							})
						);
					}
					if (orderId && !!isOrderPdf) {
						cbDBUpload();
					}

					if (isBimifyTemplate) {
						// dispatch(getRevitTemplate());
						cbDBUpload();
					}

					if (packageId) {
						dispatch(filePreview({ packageId }, false, cbDBUpload));
					}
				});
			},
			(err) => {
				console.log(err);
			}
		);
	};
};
