import React from "react";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import JobInfoEdit from "../../edit/specification-view/JobInfoEdit";
import { canEditJob } from "../parts/canEdit";
import SectionView from "../../../../../layout/section/SectionView";
import SectionEdit from "../../../../../layout/section/SectionEdit";
import { connect } from "react-redux";

const JobInfo = ({ data, isModelHistory = false }) => {
	const [editView, setEditView] = React.useState(false);
	const canEdit = canEditJob();

	if (editView) {
		return (
			<SectionEdit title={localize(LocKeys.JOB_INFO)}>
				<JobInfoEdit
					isModelHistory={isModelHistory}
					onClose={() => setEditView(!editView)}
				/>
			</SectionEdit>
		);
	}

	return (
		<SectionView
			title={localize(LocKeys.JOB_INFO)}
			canEdit={canEdit}
			handleEdit={() => setEditView(!editView)}
		>
			<div className="border-b-1 border-n150">
				<div className="row row--6p-gutters py-m">
					<div className="col col-33">
						<h5 className="small color-n300">{localize(LocKeys.SERVICE)}</h5>
					</div>
					<div className="col col-33">
						<h5 className="small color-n300">
							{localize(LocKeys.DELIVERY_FORMAT)}
						</h5>
					</div>
					<div className="col col-33">
						<h5 className="small color-n300">
							{localize(LocKeys.TEMPLATE_LABEL)}
						</h5>
					</div>
				</div>
			</div>

			<div className="border-b-1 border-n150">
				<div className="row row--6p-gutters py-regular  align-items-center color-n301">
					<div className="col col-33 align-items-center">{data.service}</div>
					<div className="col col-33">{data.deliveryFormat}</div>
					<div className="col col-33 align-items-center">{data.template}</div>
				</div>
			</div>
		</SectionView>
	);
};

const mapStateToProps = (state) => {
	return {
		data: {
			template: state.getIn(["job", "job", "revitFile", "name"]),
			service:
				state.getIn(["job", "job", "serviceType", "type"]) ||
				localize(LocKeys.CREATE),
			deliveryFormat: state
				.getIn(["job", "job", "jobDeliveryFormats"])
				?.get(0)
				?.getIn(["deliveryFormat", "type"]),
		},
	};
};

export default connect(mapStateToProps, {})(JobInfo);
