import React, { useState } from "react";
import { connect } from "react-redux";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import EditButton from "../../../../../../shared/components/buttons/EditButton";
import SumaryImageEdit from "../edit/SumaryImageEdit";
import IconWrapper from "../../../../../../shared/icons/IconWrapper";
import ImageInputFormatIcon from "../../../../../../shared/icons/inputFormats/ImageInputFormatIcon";
import LodEditor from "../../parts/LodEditor";
import ComponentTooltip from "../../../../../../shared/components/component-tooltip/ComponentTooltip";

/**
 * Render the content image (2D, 3D, etc.)
 * @returns
 */
export const returnContentImage = (value, isForSpecification = false) => {
	if (!!value) {
		return (
			<img src={value} alt={"Default"} className="h-100 w-100 of-contain" />
		);
	}

	return (
		<div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center color-n300">
			<IconWrapper
				icon={
					<ImageInputFormatIcon iconClass="d-flex icon--target-fill fill--n300" />
				}
				size={36}
			/>
			{localize(LocKeys.IMAGE_NOT_AVAILABLE)}
		</div>
	);
};
/**
 * Component for displaying the summary image
 *
 * @param {*} props
 * @returns
 */
const SummaryImage = (props) => {
	const {
		isEditView = false,
		isForSpecification = false,
		selectedLodId = null,
		lods,
		images3D,
		//images2D,
		summaries,
	} = props;
	const [editView, setEditView] = useState({
		edit: false,
		lodId: null,
	});
	const firstColClassName = isForSpecification ? "col-20" : "col-10";
	const secondColClassName = isForSpecification ? "col-16" : "col-18";

	if (editView.edit && editView.lodId) {
		return (
			<SumaryImageEdit
				lodId={editView.lodId}
				onCancel={() => setEditView({ edit: false, lodId: null })}
			/>
		);
	}

	return (
		<>
			{!isForSpecification && (
				<div className="border-b-1 border-n150">
					<div className="d-flex py-xs">
						<div className="d-flex col col-10 align-items-center">
							<h5 className="small color-n300 pl-regular">
								{localize(LocKeys.PARAMETERS)}
							</h5>
						</div>

						{lods &&
							lods.map((lod) => {
								if (+lod.get("type") <= 0) {
									return "";
								}
								return (
									<div key={lod.get("id")} className="col col-18">
										<div className="d-flex align-items-center pl-regular">
											<h5 className="small color-n300">
												{localize(LocKeys.LOD)} {lod.get("type")}
											</h5>

											<EditButton
												wrapperClasses="ml-xs"
												onClick={() =>
													setEditView({ edit: true, lodId: lod.get("id") })
												}
											/>
										</div>
									</div>
								);
							})}
					</div>
				</div>
			)}

			<div className="border-b-1 border-n150">
				<div className="d-flex">
					<div className={`col ${firstColClassName} border-r-1 border-n150`}>
						<div className="px-regular py-m">
							<ComponentTooltip
								content={localize(LocKeys.VISUAL_REPRESENTATION)}
								direction={"top"}
							>
								<div className={`text-overflow-ellipsis`}>
									{localize(LocKeys.VISUAL_REPRESENTATION)}
								</div>
							</ComponentTooltip>
						</div>
					</div>

					{images3D &&
						images3D.valueSeq().map((image) => {
							return (
								<div
									key={image.get("lodId")}
									className={`col ${secondColClassName} max-h-650p`}
								>
									<div
										className={`py-24 w-100 h-100 px-regular ${
											selectedLodId === image.get("lodId")
												? "border-p300 border-r-1 border-t-1 border-l-1 bg-primary--selected"
												: "border-n150 border-r-1"
										} `}
									>
										{returnContentImage(image.get("value"), isForSpecification)}
									</div>
								</div>
							);
						})}
				</div>
			</div>

			{/* <div className="border-b-1 border-n150">
				<div className="d-flex">
					<div className={`col ${firstColClassName} border-r-1 border-n150`}>
						<div className="px-regular py-m">{localize(LocKeys.IMAGE_2D)}</div>
					</div>

					{images2D &&
						images2D.valueSeq().map((image) => {
							return (
								<div
									key={image.get("lodId")}
									className={`col ${secondColClassName} max-h-450p`} // max-h-190p
								>
									<div
										className={`py-24 w-100 h-100 px-regular ${
											selectedLodId === image.get("lodId")
												? "border-p300 border-r-1 border-t-1 border-l-1 bg-primary--selected"
												: "border-n150 border-r-1"
										} `}
									>
										{returnContentImage(image.get("value"), isForSpecification)}
									</div>
								</div>
							);
						})}
				</div>
			</div> */}

			<div className="border-b-1 border-n150">
				<div className="d-flex h-100">
					<div className={`col ${firstColClassName} border-r-1 border-n150`}>
						<div className="px-regular py-m">
							{localize(LocKeys.SUMMARY_LABEL)}
						</div>
					</div>

					{summaries &&
						summaries.valueSeq().map((summary) => {
							return (
								<div
									key={summary.get("lodId")}
									className={`col ${secondColClassName}`}
								>
									<div
										className={`py-s w-100 h-100 px-regular ${
											selectedLodId === summary.get("lodId")
												? `border-p300 ${
														isEditView
															? "border-r-1 border-t-1 border-l-1"
															: "border-1"
													}  bg-primary--selected`
												: "border-n150 border-r-1"
										} `}
									>
										<LodEditor
											isEdit={false}
											lodId={summary?.get("lodId")}
											summary={summary?.get("summary") || ""}
										/>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	const {
		isForSpecification = false,
		images3D,
		images2D,
		summaries,
	} = ownProps;

	return {
		images2D: isForSpecification
			? images2D
			: state.getIn([
					"administration",
					"componentCategoryContent",
					"lodContent",
					"images2D",
				]) || null,
		images3D: isForSpecification
			? images3D
			: state.getIn([
					"administration",
					"componentCategoryContent",
					"lodContent",
					"images3D",
				]) || null,
		summaries: isForSpecification
			? summaries
			: state.getIn([
					"administration",
					"componentCategoryContent",
					"lodContent",
					"summaries",
				]) || null,

		lods: state.getIn(["administration", "lods"]),
	};
};

export default connect(mapStateToProps, {})(SummaryImage);
