import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form/immutable";
import FormSubmitButtons from "../../../../../../shared/components/FormComponents/FormSubmitButtons";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { useParams } from "react-router-dom-v5-compat";
import {
	getBuildingCategories,
	getInputFormats,
	updateJob,
} from "../../../../../../actions";
import CustomAutocomplete from "../../../../../fields/CustomAutocomplete";
import renderTextField from "../../../../../fields/renderTextField";
import IconWrapper from "../../../../../../shared/icons/IconWrapper";
import {
	getBuildingTypeIcon,
	getInputFormatIcon,
} from "../../../../../../utils/getTypeIcon";
import {
	adornmentTypeConstants,
	sizeConstants,
} from "../../../../../../constants/constants";
import ComponentTooltip from "../../../../../../shared/components/component-tooltip/ComponentTooltip";
import QuestionIcon from "../../../../../../shared/icons/misc/QuestionIcon";
import CustomLabel from "../../../../../fields/CustomLabel";
import renderError from "../../../../../fields/renderError";

const BuildingInfoEdit = ({
	isModelHistory = false,
	initialValues,
	onClose,
	//Actions redux
	getInputFormats,
	getBuildingCategories,
	...props
}) => {
	const {
		buildingId,
		tempBuildingInfo,
		buildingTypes,
		inputFormats,
		updateJob,
	} = props;
	const [loading, setLoading] = useState(false);
	const { jobId } = useParams();

	useEffect(() => {
		getBuildingCategories();
		getInputFormats();
	}, [getBuildingCategories, getInputFormats]);

	const onSubmit = (formValues) => {
		try {
			setLoading(true);
			const data = formValues.toJS();

			const body = {
				buildingId: +buildingId,
				buildingTypeId: data?.type,
				buildingSize: data?.size,
				buildingName: data?.name,
				inputFormats: [data?.inputFormat],
				levelsTotal: +data?.levels,
				uniqueLevels: +data?.uniqueLevels,
			};

			updateJob({
				jobId,
				data: body,
				step: 1,
				cb: () => {
					setLoading(false);
					onClose && onClose();
				},
				isModelHistory,
			});
		} catch (error) {
			console.log("Order info edit error: ", error);
			return;
		}
	};

	const handleClose = () => {
		onClose();
	};

	// const setValue = useCallback((fieldKey, value) => {
	// 	//	setStateTempFieldValue(fieldKey, value, null, true);
	// }, []);

	const inputFormatOptions = useMemo(() => {
		const options = [];
		if (inputFormats && inputFormats.size > 0) {
			inputFormats.map((inputFormat, i) => {
				let subHeader = (
					<div
						className={`dropdown-subheader body-font--small  semi  ${+i === 0 ? "" : "dropdown-subheader--border"}`}
					>
						<div className="d-flex align-items-center w-100">
							<IconWrapper
								size="24"
								wrapperClasses="mr-s"
								icon={getInputFormatIcon(inputFormat.get("type"))}
							/>

							<div className="text-overflow-ellipsis">
								{inputFormat.get("type")}
							</div>
						</div>
					</div>
				);

				const versions = inputFormat?.get("children");

				versions &&
					versions.map((item, i) => {
						return options.push({
							subHeader,
							label: item.get("type"),
							value: item.get("id"),
						});
					});

				return options;
			});
		}
		return options;
	}, [inputFormats]);

	const buildingTypeOptions = useMemo(() => {
		const options = [];
		if (buildingTypes && buildingTypes.size > 0) {
			buildingTypes.map((buildingType, i) => {
				let subHeader = (
					<div
						className={`dropdown-subheader body-font--small  semi  ${+i === 0 ? "" : "dropdown-subheader--border"}`}
					>
						<div className="d-flex align-items-center w-100">
							<IconWrapper
								size="24"
								wrapperClasses="mr-s mt-neg-xs"
								icon={getBuildingTypeIcon(buildingType.get("type"))}
							/>

							<div className="text-overflow-ellipsis">
								{buildingType.get("type")}
							</div>
						</div>
					</div>
				);

				const children = buildingType?.get("children");
				children &&
					children.map((item) => {
						return options.push({
							subHeader,
							label: item.get("type"),
							value: item.get("id"),
						});
					});

				return options;
			});
		}
		return options;
	}, [buildingTypes]);

	const levelsValidity = useMemo(
		() => +tempBuildingInfo["levels"] < +tempBuildingInfo["uniqueLevels"],
		[tempBuildingInfo]
	);

	const fields = useMemo(() => ["name", "size", "type", "inputFormat"], []);

	const isValid = useMemo(() => {
		let hasInvalidValue = Object.keys(tempBuildingInfo).some(
			(key) => fields.includes(key) && !tempBuildingInfo[key]
		);

		return hasInvalidValue || levelsValidity;
	}, [tempBuildingInfo, fields, levelsValidity]);

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<div className="border-b-1 border-n150">
				<div className="row row--6p-gutters align-items-center pb-s">
					<div className={`col col-20`}>
						<CustomLabel
							label={
								<h5 className="small">{localize(LocKeys.BUILDING_NAME)}</h5>
							}
							required={true}
						/>
					</div>
					<div className={`col col-20`}>
						<CustomLabel
							label={
								<h5 className="small">{localize(LocKeys.BUILDING_TYPE)}</h5>
							}
							required={true}
						/>
					</div>
					<div className={`col col-15`}>
						<CustomLabel
							label={
								<h5 className="small">{localize(LocKeys.INPUT_FORMAT)}</h5>
							}
							required={true}
						/>
					</div>
					<div className={`col col-15`}>
						<CustomLabel
							label={<h5 className="small">{localize(LocKeys.SIZE)}</h5>}
							required={true}
						/>
					</div>
					<div className={`col col-15`}>
						<CustomLabel
							label={<h5 className="small">{localize(LocKeys.LEVELS)}</h5>}
						/>
					</div>
					<div className={`col $col-15 align-items-center`}>
						<ComponentTooltip
							content={localize(LocKeys.UNIQUE_LEVELS_EXPLANATION)}
							direction={"left"}
						>
							<div className="d-flex align-items-start">
								<h5 className={`text-overflow-ellipsis small mr-xs`}>
									{localize(LocKeys.UNIQUE_LEVELS)}
								</h5>

								<IconWrapper
									size={sizeConstants.M}
									icon={
										<QuestionIcon iconClass="d-flex icon--target-fill fill--n300" />
									}
								/>
							</div>
						</ComponentTooltip>
					</div>
				</div>
			</div>
			<div className="border-b-1 border-n150 py-m">
				<div className="row row--6p-gutters">
					<div className="col col-20">
						<Field
							name={`name`}
							id={`name`}
							placeholder={localize(LocKeys.ENTER_BUILDING_NAME) + "..."}
							required={false}
							// onKeyDown={(value) => {
							// 	setValue(`name`, value);
							// }}
							component={renderTextField}
							disabled={isModelHistory}
							min="0"
							type="text"
							showPlaceholder={true}
						/>
					</div>
					<div className="col col-20">
						<Field
							name="type"
							id="type"
							disabled={isModelHistory}
							dropdownHeight="400px"
							disableClearable={true}
							component={CustomAutocomplete}
							groupBy={true}
							showPlaceholder={true}
							required={true}
							placeholder={localize(LocKeys.SELECT_TYPE) + "..."}
							initialValue={initialValues?.get("type") || null}
							options={buildingTypeOptions}
							//customOnChange={(value) => setValue(`buildingType`, value)}
						/>
					</div>
					<div className="col col-15">
						<Field
							name="inputFormat"
							id="inputFormat"
							placeholder={localize(LocKeys.SELECT_INPUT_FORMAT) + "..."}
							required={true}
							dropdownHeight="400px"
							showPlaceholder={true}
							// customOnChange={(value) => {
							// 	setValue("inputFormat", value);
							// }}
							disableClearable={true}
							component={CustomAutocomplete}
							groupBy={true}
							options={inputFormatOptions}
							initialValue={initialValues.get("inputFormat") || null}
						/>
					</div>
					<div className="col col-15">
						<Field
							name={`size`}
							id={`size`}
							onlyInteger={true}
							component={renderTextField}
							type="number"
							adornmentType={adornmentTypeConstants.METERS}
							min="0"
							placeholder={localize(LocKeys.SIZE) + "..."}
							required={true}
							//onKeyDown={(value) => setValue(`size`, value)}
						/>
					</div>
					<div className="col col-15">
						<Field
							name={`levels`}
							id={`levels`}
							component={renderTextField}
							type="number"
							min="0"
							placeholder={localize(LocKeys.LEVELS) + "..."}
							required={false}
							//	onKeyDown={(value) => setValue(`levels`, value)}
							customError={levelsValidity}
						/>
					</div>
					<div className="col col-15">
						<Field
							name={`uniqueLevels`}
							id={`uniqueLevels`}
							component={renderTextField}
							type="number"
							min="0"
							placeholder={localize(LocKeys.UNIQUE_LEVELS) + "..."}
							required={true}
							//onKeyDown={(value) => setValue(`uniqueLevels`, value)}
							customError={levelsValidity}
						/>
					</div>
				</div>
			</div>
			{levelsValidity && (
				<div className="row row--6p-gutters">
					<div className={`col col-70`}></div>
					<div className={`col col-30`}>
						{renderError(
							false,
							false,
							true,
							localize(LocKeys.CUSTOM_LEVELS_ERROR)
						)}
					</div>
				</div>
			)}
			<FormSubmitButtons
				wrapperClasses="py-24 mt-24"
				onCancel={handleClose}
				submitDisabled={loading || isValid}
				loading={loading}
			/>
		</form>
	);
};

const selector = formValueSelector("jobBuildingInfoEditForm");
const mapStateToProps = (state) => {
	return {
		tempBuildingInfo: {
			name: selector(state, "name"),
			size: selector(state, "size"),
			type: selector(state, "type"),
			inputFormat: selector(state, "inputFormat"),
			levels: selector(state, "levels"),
			uniqueLevels: selector(state, "uniqueLevels"),
		},
		initialValues: {
			inputFormat: state.getIn([
				"job",
				"tempJob",
				"buildingInfo",
				"inputFormat",
			]),
			name: state.getIn(["job", "tempJob", "buildingInfo", "name"]),
			size: state.getIn(["job", "tempJob", "buildingInfo", "size"]),
			type: state.getIn(["job", "tempJob", "buildingInfo", "type"]),
			levels: state.getIn(["job", "tempJob", "buildingInfo", "levels"]),
			uniqueLevels: state.getIn([
				"job",
				"tempJob",
				"buildingInfo",
				"uniqueLevels",
			]),
		},
		inputFormats: state.getIn(["administration", "inputFormat"]),
		buildingTypes: state.getIn(["administration", "types"]),
		buildingId: state.getIn(["job", `job_${1}`, "building", "id"]),
	};
};

export default connect(mapStateToProps, {
	getInputFormats,
	getBuildingCategories,
	updateJob,
})(
	reduxForm({
		form: "jobBuildingInfoEditForm",
		destroyOnUnmount: false,
		enableReinitialize: true,
		touchOnBlur: false,
	})(BuildingInfoEdit)
);
