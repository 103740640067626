import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import { updateStopBuildingRefetch } from "../../../../actions";
import ModelUpdateHeader from "./parts/step1/Step1.0-Header";
import ModelUpdateBody from "./parts/step1/Step1.0-Body";
import ModelUpdateFooter from "./parts/step1/Step1.0-Footer";

const UpdateSpecification = ({ buildingId, nextPage, ...props }) => {
	const { updateStopBuildingRefetch } = props;

	const onSubmit = (values) => {
		try {
			updateStopBuildingRefetch(true);
			nextPage();
		} catch (error) {
			console.log(error);
			return;
		}
	};

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form	`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<ModelUpdateHeader />
			<ModelUpdateBody />
			<ModelUpdateFooter buildingId={buildingId} />
		</form>
	);
};

export default connect(null, { updateStopBuildingRefetch })(
	reduxForm({
		form: "buildingUpdateSpecificationForm_1",
		touchOnBlur: false,
		destroyOnUnmount: true,
		enableReinitialize: true,
	})(UpdateSpecification)
);
