import React, { useState } from "react";
import { CircularProgress, TextField, Autocomplete } from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { colorConstants } from "../../constants/constants";
import renderError from "./renderError";
import CustomLabel from "./CustomLabel";
import clsx from "clsx";
import { useEffect } from "react";

export default ({
	id = "single-autocomplete",
	input,
	options,
	disabled = false,
	initialValue = null,
	placeholder = "",
	meta: { touched, error },
	label = "",
	required = true,
	customOnChange,
	customError = false,
	disableClearable = false,
	customErrorLabel,
	dropdownHeight = "300px",
	size = "w-100",
	disableCloseOnSelect = true,
	asyncFetch = null,
	clearState = false,
	classField = "",
}) => {
	const { onChange } = input;
	const [open, setOpen] = useState(false);
	const [val, setVal] = useState(initialValue);
	const loading = open && options.length === 0;

	useEffect(() => {
		if (clearState) setVal(initialValue);
	}, [initialValue, clearState]);

	// useEffect(() => {
	// 	if (asyncFetch) asyncFetch("", initialValue, true);
	// }, [asyncFetch, initialValue]);

	let errorExist = false;
	if (touched && error) {
		errorExist = true;
	}

	const renderInput = (params) => {
		return (
			<TextField
				{...params}
				variant="standard"
				InputProps={{
					...params.InputProps,
					endAdornment: (
						<div className="pr-m">
							{loading ? <CircularProgress color="inherit" size={16} /> : null}
							{params.InputProps.endAdornment}
						</div>
					),
					classes: {
						root: `strip-native-styles my-neg-xxs ml-m`,
						input: `strip-native-styles
				    `,
					},
				}}
				placeholder={placeholder ? placeholder : label ? label : ""}
			/>
		);
	};

	const getOptionSelected = (option, { value }) => {
		return +option.value === +value;
	};

	const handleChange = (_, value) => {
		setVal(value);

		customOnChange && customOnChange(value);
		onChange && onChange(value);
	};

	const renderOption = (props, option) => {
		return (
			<li {...props} key={option.value}>
				<div className="w-100">{getOptionLabel(option)}</div>
			</li>
		);
	};

	const getOptionLabel = (option) => {
		return option.label || "";
	};

	return (
		<div className={clsx("form__input form__input--select", size, classField)}>
			{label && <CustomLabel id={id} label={label} required={required} />}
			<Autocomplete
				id={id}
				disablePortal
				loading={loading}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				ListboxProps={{
					style: { maxHeight: dropdownHeight },
				}}
				multiple={false}
				renderOption={renderOption}
				getOptionLabel={getOptionLabel}
				isOptionEqualToValue={getOptionSelected}
				disableClearable={disableClearable}
				options={options}
				onInputChange={(e, newInputValue) => {
					if (e?.type === "change")
						asyncFetch && asyncFetch(newInputValue, val);
				}}
				popupIcon={<ExpandMoreSharp style={{ color: colorConstants.N300 }} />}
				renderInput={renderInput}
				value={clearState ? initialValue : val}
				disableCloseOnSelect={disableCloseOnSelect}
				onChange={handleChange}
				classes={{
					root: `field__root overflow-y-auto
					${disabled ? "field__root--disabled" : ""}
					${!!val ? "field__root--complete" : ""}
					${errorExist ? "field__root--error" : ""}`,
					listbox: "dropdown-list",
					option: "dropdown-item",
					popper: "autocomplete__popper",
					input: "autocomplete__input",
				}}
			/>
			{renderError(error, touched, customError, customErrorLabel)}
		</div>
	);
};
