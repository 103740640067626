import React, { useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { deleteLODSpecification, deletePackage } from "../../../../../actions";
import localize, { LocKeys } from "../../../../../constants/localizations";
import EditButton from "../../../../../shared/components/buttons/EditButton";
import HeaderLayout from "../../../../layout/content/HeaderLayout";
import Modal from "../../../../Modal";
import { canEditPackage } from "../../../permission";

const HeaderView = (props) => {
	const {
		name,
		toggleEditView,
		deletePackage,
		lodSpecificationId,
		companyId,
		resellerCompanyId,
		deleteLODSpecification,
	} = props;
	const { packageId } = useParams();
	const history = useHistory();
	const [removeModal, setRemoveModal] = useState(false);

	const toggleRemoveModal = () => {
		setRemoveModal(!removeModal);
	};

	return (
		<>
			<Modal
				show={removeModal}
				title={
					localize(LocKeys.DELETE) +
					" " +
					localize(LocKeys.PACKAGE).toLowerCase()
				}
				onClose={toggleRemoveModal}
			>
				<ReactMarkdown>
					{localize(LocKeys.ARE_YOU_SURE_DELETE, [name])}
				</ReactMarkdown>
				<div className="form-contents__wrapper d-flex justify-content-end mt-32">
					<button
						type="button"
						onClick={() => toggleRemoveModal()}
						className="btn btn--secondary-error"
					>
						{localize(LocKeys.CANCEL)}
					</button>
					<button
						type="button"
						onClick={(e) => {
							deletePackage(packageId, () => {
								deleteLODSpecification(lodSpecificationId);
								history.goBack();
							});
							toggleRemoveModal();
						}}
						className="btn btn--error ml-m"
					>
						{localize(LocKeys.DELETE)}
					</button>
				</div>
			</Modal>
			<HeaderLayout wrapperClasses={`px-48 d-flex align-items-center `}>
				<div className="d-flex align-items-center justify-content-between w-100">
					<h3>{name}</h3>
					{canEditPackage(companyId, resellerCompanyId) && (
						<div className={`d-flex  align-items-center`}>
							<div>
								<button
									onClick={() => toggleRemoveModal()}
									className="btn btn--secondary-error btn--small"
								>
									{localize(LocKeys.DELETE)}
								</button>
							</div>

							<div className="ml-m">
								<EditButton onClick={() => toggleEditView(true)} />
							</div>
						</div>
					)}
				</div>
			</HeaderLayout>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		name: state.getIn(["dashboard", "salesPackage", "name"]),
		companyId: state.getIn(["dashboard", "salesPackage", "company", "id"]),
		resellerCompanyId:
			state.getIn([
				"dashboard",
				"salesPackage",
				"company",
				"resellerCompany",
				"id",
			]) || null,
		lodSpecificationId: state.getIn([
			"dashboard",
			"salesPackage",
			"lodSpecification",
			"id",
		]),
	};
};

export default connect(mapStateToProps, {
	deletePackage,
	deleteLODSpecification,
})(HeaderView);
