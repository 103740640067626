import React, { useEffect, useState } from "react";
import { HeadLayout } from "../../layout/HeadLayout";
import { ContentLayout } from "../../layout/ContentLayout";
import CustomStepper from "../../../shared/components/CustomStepper/CustomStepper";
import localize, { LocKeys } from "../../../constants/localizations";
import UpdateSpecification from "./steps/Step1.0-UpdateSpecification";
import FileUpload from "./steps/Step2.0-FileUpload";
import AdditionalInformation from "./steps/Step3.0-AdditionalInformation";
import { getBuildingJobInfo } from "../../../actions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom-v5-compat";

const ModelUpdateBuilding = (props) => {
	const { getBuildingJobInfo } = props;
	const { buildingId } = useParams();
	const [page, setPage] = useState(1);
	const completedSteps = [];

	useEffect(() => {
		if (buildingId) getBuildingJobInfo(buildingId);
	}, [buildingId, getBuildingJobInfo]);

	const nextPage = () => {
		setPage(page + 1);
	};

	const previousPage = () => {
		setPage(page - 1);
	};

	const goTo = (specificPage = 0) => {
		setPage(specificPage);
	};

	let steps = [
		{
			number: 1,
			step: 0,
			title: localize(LocKeys.UPDATE_SPECIFICATION),
			template: () => (
				<UpdateSpecification nextPage={nextPage} buildingId={buildingId} />
			),
		},

		{
			number: 2,
			step: 1,
			title: localize(LocKeys.FILE_UPLOAD),
			template: () => (
				<FileUpload
					nextPage={nextPage}
					buildingId={buildingId}
					previousPage={previousPage}
				/>
			),
		},
		{
			number: 3,
			step: 2,
			title: localize(LocKeys.ADDITIONAL_INFORMATION),
			template: () => (
				<AdditionalInformation
					buildingId={buildingId}
					previousPage={previousPage}
				/>
			),
		},
	];

	const setStepCompleted = (stepNumber) => {
		completedSteps.push(stepNumber);
	};

	const renderStepper = () => {
		if (!currentPage) {
			return;
		}

		return (
			<CustomStepper
				completed={completedSteps}
				steps={steps}
				activeStep={currentPage}
				goTo={goTo}
				setStepCompleted={setStepCompleted}
			/>
		);
	};

	let currentPage = steps.find((step) => step.number === page);

	const renderView = () => {
		let selectedStep = steps.find((step) => step.number === page);

		return selectedStep.template();
	};
	return (
		<>
			<div className="create-project d-flex flex-column">
				<>
					<HeadLayout
						showHeaderLayout={true}
						wrapperClasses="mb-24 page-header card pos-rel card--border--none card--2 bg-n000"
					>
						<div className="w-100  my-34 ml-regular">{renderStepper()}</div>
					</HeadLayout>
					<div className={`flex-1 pos-rel d-flex flex-column`}>
						<ContentLayout
							wrapperClasses="card card--border--none card--2 bg-n000"
							showContentLayout={true}
							hideChat={true}
						>
							{renderView()}
						</ContentLayout>
					</div>
				</>
			</div>
		</>
	);
};

export default connect(null, { getBuildingJobInfo })(ModelUpdateBuilding);
