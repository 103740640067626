import React from "react";
import { connect } from "react-redux";
import {
	completeMultipartUpload,
	getPresignedPostUploadUrl,
	getPresignedUrlParts,
	initiateMultipartUpload,
	openSnackbar,
	setFilesData,
	updateUploadProgress,
	uploadParts,
	uploadToDigitalOcean,
} from "../../../actions";
import { getCancelSource } from "../../../api";
import FileInputButton from "../../../components/fields/FileInputButton";
import { fileSizes, snackbarTypeConstants } from "../../../constants/constants";
import FileInput from "../TextEditor/Lexical/ui/FileInput";
import { FILE_PREFIX } from "../../../constants/globalConstants";

const UploadFiles = (props) => {
	let filesArray = [];

	const {
		disabled,
		extensions,
		uploadFileData,
		isBuilding,

		//Actions
		completeMultipartUpload,
		getPresignedPostUploadUrl,
		getPresignedUrlParts,
		initiateMultipartUpload,
		openSnackbar,
		setFilesData,
		updateUploadProgress,
		uploadParts,
		uploadToDigitalOcean,
		isNormalised,
	} = props;

	const {
		buttonWrapperClasses,
		buttonLabel = false,
		jobId,
		ticketId = null,
		jobStatusId,
		//
		projectCreatedAt,
		buildingCreatedAt,
		buildingId,
		disciplineId,
		orderId,
		fileNamePrefix,
		isForTextEditor = false,
		textEditorData,
		getTicketCommentId,
	} = uploadFileData;

	const uploadFiles = (files = [], ticketCommentId = null) => {
		if (files.length > 0) {
			openSnackbar(snackbarTypeConstants.FILE_UPLOAD);
			uploadFilesToDigitalOcean(files, ticketCommentId);
		}
	};

	const uploadFilesToDigitalOcean = (files, ticketCommentId = null) => {
		for (const file of files) {
			const startWithPrefix =
				file.name.startsWith(FILE_PREFIX.CUSTOMISATION) ||
				file.name.startsWith(FILE_PREFIX.QA);
			const fileName =
				fileNamePrefix && !startWithPrefix
					? `${fileNamePrefix}${file.name}`
					: file.name;

			let preSignedPostUploadUrlBody = {
				fileName: fileName,
				projectCreatedAt: null,
				buildingCreatedAt: null,
				orderId: null,
				buildingId: null,
				jobId: null,
				jobStatusId: null,
				isNormalised,
			};

			let dbFileData = {
				jobId: null,
				jobStatusId: null,
				orderId: null,
				buildingId: null,
				disciplineId: null,
				file: {
					name: fileName,
					size: file.size,
				},
				path: null,
				isNormalised,
			};

			if (jobId) {
				preSignedPostUploadUrlBody.jobId = jobId;
				dbFileData.jobId = jobId;

				if (jobStatusId) {
					preSignedPostUploadUrlBody.jobStatusId = jobStatusId;
					dbFileData.jobStatusId = jobStatusId;
				}
			}

			if (buildingId && disciplineId) {
				preSignedPostUploadUrlBody.buildingId = buildingId;
				preSignedPostUploadUrlBody.buildingCreatedAt = buildingCreatedAt;
				dbFileData.buildingId = buildingId;
				dbFileData.disciplineId = disciplineId;
			}
			if (orderId) {
				preSignedPostUploadUrlBody.orderId = orderId;
				preSignedPostUploadUrlBody.projectCreatedAt = projectCreatedAt;
				dbFileData.orderId = orderId;
			}

			if (isForTextEditor) {
				dbFileData = {
					file: {
						name: file.name,
						size: file.size,
					},
					isForTextEditor: true,
					type: "video",
				};
				preSignedPostUploadUrlBody = {
					fileName: file.name,
					isForTextEditor: true,
				};
			}

			if (ticketCommentId && ticketId) {
				dbFileData = {
					file: {
						name: file.name,
						size: file.size,
					},
					ticketCommentId,
					ticketId,
				};
				preSignedPostUploadUrlBody = {
					fileName: file.name,
					ticketCommentId,
					ticketId,
				};
			}

			uploadFileToDigitalOcean(file, preSignedPostUploadUrlBody, dbFileData);
		}
	};

	const uploadFileToDigitalOcean = async (
		file,
		preSignedPostUploadUrlBody,
		dbFileData
	) => {
		//const maxSingleFileSize = fileSizes.MAX_SINGLE_FILE_SIZE;
		const maxSingleFileSize = fileSizes.GB_5;
		const id = Math.floor(1000 + Math.random() * 9000);
		file.id = id;
		const fileId = file.id;
		const source = getCancelSource();

		const startWithPrefix =
			file.name.startsWith(FILE_PREFIX.CUSTOMISATION) ||
			file.name.startsWith(FILE_PREFIX.QA);

		const fileName =
			fileNamePrefix && !startWithPrefix
				? `${fileNamePrefix}${file.name}`
				: file.name;

		if (file.size > maxSingleFileSize) {
			// const partSize = 100 * fileSizes.MB;
			const partSize = 100 * 1024 * 1024;
			const multipartData = await initiateMultipartUpload(
				preSignedPostUploadUrlBody
			);

			const uploadId = multipartData.UploadId;
			const path = multipartData.path;
			const parts = Math.ceil(file.size / partSize);
			const data = {
				uploadId,
				path,
				parts,
			};

			const presignedUrlParts = await getPresignedUrlParts(data);
			const numOfParts = Object.keys(presignedUrlParts).length;

			file.numOfUploadedParts = 0;
			file.totalParts = numOfParts;

			filesArray.push({
				id,
				name: fileName,
				size: file.size,
				source,
				value: (1 / numOfParts) * 100,
				numOfUploadedParts: 0,
				totalParts: numOfParts,
			});

			setFilesData(filesArray);
			const uploadedParts = await uploadParts(
				file,
				presignedUrlParts,
				(progress) => {
					filesArray.forEach((file) => {
						if (file.id === fileId) {
							file.value = progress;
						}
					});

					updateUploadProgress(filesArray);
				},
				source.token
			);
			const completeMultipartData = {
				uploadId,
				path,
				parts: uploadedParts,
			};

			dbFileData.path = path;
			await completeMultipartUpload(
				completeMultipartData,
				dbFileData,
				extensions,
				preSignedPostUploadUrlBody
			);
		} else {
			const presignedPostUrlData = await getPresignedPostUploadUrl(
				preSignedPostUploadUrlBody
			);
			dbFileData.path = presignedPostUrlData.fields.key;

			//OUTDATED
			//let form = new FormData();
			// Object.keys(presignedPostUrlData.fields).forEach((key) =>
			// 	form.append(key, presignedPostUrlData.fields[key])
			// );

			//	form.append("file", file);

			filesArray.push({
				id: id,
				name: fileName,
				size: file.size,
				value: 0,
				source: source,
			});

			setFilesData(filesArray);

			uploadToDigitalOcean({
				url: presignedPostUrlData.url,
				//form,
				file,
				dbFileData,
				onUploadProgress: (progressEvent) => {
					let progress = 0;
					if (!!progressEvent.uploadDone) {
						progress = 100;
					} else {
						progress = Math.round(100 * progressEvent.progress);
					}

					filesArray.forEach((file) => {
						if (+file.id === +fileId) {
							file.value = progress;
						}
					});

					updateUploadProgress(filesArray);
				},
				uploadToDB: true,
				token: source.token,
				extensions,
			});
		}
	};

	if (isForTextEditor) {
		return <FileInput {...textEditorData} customOnChange={uploadFiles} />;
	}

	return (
		<FileInputButton
			disabled={disabled}
			buttonWrapperClasses={buttonWrapperClasses}
			buttonLabel={buttonLabel}
			customOnChange={(files) =>
				ticketId
					? getTicketCommentId(files?.length, (ticketCommentId) =>
							uploadFiles(files, ticketCommentId)
						)
					: uploadFiles(files)
			}
			id={`file-input`}
			allowedSize={isBuilding ? fileSizes.GB_40 : fileSizes.GB_5}
		/>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { uploadFileData } = ownProps;
	const { supportedFileFormats = false, disciplineId } = uploadFileData;

	let extensions = false;
	if (supportedFileFormats) {
		const disciplineLevels = state.getIn([
			"job",
			"tempJob",
			"disciplineLevels",
		]);

		const disciplineLevel =
			disciplineLevels &&
			disciplineLevels?.find(
				(disciplineLevel) =>
					+disciplineLevel?.get("discipline") === +disciplineId
			);

		const levels = disciplineLevel?.get("levels");

		levels &&
			levels.valueSeq().forEach((level) => {
				if (!!level?.get("extension")) {
					extensions = level?.get("extension");
				}
			});
	}

	return {
		extensions: extensions ? [extensions] : false,
	};
};

export default connect(mapStateToProps, {
	completeMultipartUpload,
	getPresignedPostUploadUrl,
	getPresignedUrlParts,
	initiateMultipartUpload,
	openSnackbar,
	setFilesData,
	updateUploadProgress,
	uploadParts,
	uploadToDigitalOcean,
})(UploadFiles);
