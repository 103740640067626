import React, { useCallback } from "react";
import HeaderLayout from "../../../layout/content/HeaderLayout";
import localize, { LocKeys } from "../../../../constants/localizations";
import BodyLayout from "../../../layout/content/BodyLayout";
import CustomStepNavigation from "../../../../shared/components/CustomStepper/CustomStepNavigation";
import FooterLayout from "../../../layout/content/FooterLayout";
import { useNavigate } from "react-router-dom-v5-compat";
import ModelUpdateAdditionalInfo from "./parts/step3/ModelUpdateAdditionalInfo";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import { uploadFile } from "../../../../shared/components/UploadFile/uploadFile";
import {
	createJob,
	getPresignedPostUploadUrl,
	uploadToDigitalOcean,
} from "../../../../actions";

const AdditionalInformation = ({ previousPage, buildingId, ...props }) => {
	const navigate = useNavigate();
	const {
		//Redux State
		tempFiles,
		modelUpdate,
		//Redux Actions
		getPresignedPostUploadUrl,
		uploadToDigitalOcean,
		createJob,
	} = props;
	const [loading, setLoading] = React.useState(false);
	const countFile = React.useRef(0);

	const cancel = useCallback(() => {
		navigate(`/buildings/${buildingId}`);
	}, [navigate, buildingId]);

	const uploadFiles = useCallback(
		(buildingId, disciplineLevels, cb) => {
			const files = tempFiles?.toJS() || [];
			countFile.current = 0;

			const filesData = [];
			if (files.length === 0) {
				cb();
			}

			for (const fileItem of files) {
				const { file, id } = fileItem;

				const disciplineLevel = disciplineLevels.find((disciplineLevel) => {
					const findLevel = disciplineLevel.levels.find(
						(level) => level.tempFileId === id
					);

					if (findLevel) {
						return disciplineLevel;
					}

					return null;
				});

				const dbFileData = {
					file: {
						name: file.name,
						size: file.size,
					},
					path: null,
					buildingId,
					disciplineId: disciplineLevel ? disciplineLevel?.discipline : 1,
					isUpdateModel: true,
				};
				const preSignedPostUploadUrlBody = {
					fileName: file.name,
					buildingId,
					disciplineId: disciplineLevel ? disciplineLevel?.discipline : 1,
				};

				//------------------
				uploadFile({
					dbFileData,
					file: file,
					preSignedPostUploadUrlBody,
					uploadToDigitalOcean,
					getPresignedPostUploadUrl,
					cbDBUpload: (result) => {
						countFile.current++;

						if (!!disciplineLevel?.discipline) {
							filesData.push({
								id: id,
								file: result.id,
								discipline: disciplineLevel?.discipline,
							});
						}

						if (countFile.current === files.length) {
							cb(filesData);
						}
					},
				});
			}
		},
		[tempFiles, uploadToDigitalOcean, getPresignedPostUploadUrl]
	);

	const updateModelAction = useCallback(
		(tempBody, filesData) => {
			//Building Level file
			const disciplineBuildingLevelFiles = [];

			const disciplineLevels = tempBody?.disciplineLevels || [];

			disciplineLevels &&
				disciplineLevels.forEach((disciplineLevel) =>
					disciplineLevel?.levels.forEach((level) => {
						const findFile =
							filesData &&
							filesData.find((file) => file.id === level.tempFileId);
						const file = findFile ? findFile?.file || null : null;

						disciplineBuildingLevelFiles.push({
							level: level.id,
							file: file || null,
							discipline: disciplineLevel.discipline,
						});
					})
				);

			//Export versions
			const tempExports = tempBody?.exports || [];
			let exports = [];
			tempExports.forEach((item) => {
				exports.push(item.exportId);
			});

			const body = {
				exports: exports,
				name: tempBody?.name || null,
				building: tempBody?.building,
				deliveryFormats: [tempBody?.deliveryFormat],
				//clientCompany: customer || null,
				disciplineBuildingLevelFiles: disciplineBuildingLevelFiles,
				additionalInfo: tempBody?.additionalInfo || "",
				inputFormats: [tempBody?.inputFormat],
				lodSpecification: tempBody?.specification,
				revitFileId: tempBody?.template,
				isModelUpdate: true,
			};

			createJob({ data: body, cb: cancel, isModelUpdate: true });
		},
		[createJob, cancel]
	);

	const onSubmit = useCallback(() => {
		try {
			const tempModelUpdate = modelUpdate?.toJS() || null;
			if (tempModelUpdate) {
				setLoading(true);
				uploadFiles(
					tempModelUpdate.building,
					tempModelUpdate.disciplineLevels,
					(filesData) => {
						updateModelAction(tempModelUpdate, filesData);
					}
				);
			}
		} catch (error) {
			console.log(error);
			return;
		}
	}, [modelUpdate, uploadFiles, updateModelAction]);

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<HeaderLayout wrapperClasses="px-24 d-flex align-items-center">
				<h3>{localize(LocKeys.ADDITIONAL_INFORMATION)}</h3>
			</HeaderLayout>
			<BodyLayout wrapperClasses="p-24">
				<ModelUpdateAdditionalInfo />
			</BodyLayout>
			<FooterLayout wrapperClasses="px-24">
				<CustomStepNavigation
					showCancel={true}
					cancelButtonAction={() => navigate(`/buildings/${buildingId}`)}
					leftButtonLabel={localize(LocKeys.BACK)}
					rightButtonClasses="btn--success"
					rightButtonDisabled={loading}
					rightButtonLabel={localize(LocKeys.CREATE)}
					rightButtonLoading={loading}
					leftButtonAction={() => previousPage()}
				/>
			</FooterLayout>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		modelUpdate: state.getIn(["modelUpdate", "tempModelUpdate"]),
		tempFiles: state.getIn(["modelUpdate", "tempFiles"]),
		// customer: state.getIn(["global", "customer"]),
	};
};

export default connect(mapStateToProps, {
	getPresignedPostUploadUrl,
	uploadToDigitalOcean,
	createJob,
})(
	reduxForm({
		form: "buildingUpdateSpecificationForm_3",
		touchOnBlur: false,
		destroyOnUnmount: true,
		enableReinitialize: true,
	})(AdditionalInformation)
);
