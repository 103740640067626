import React, { useMemo } from "react";
import {
	bimifyConstants,
	logoTypesConstants,
	sizeConstants,
} from "../../constants/constants";
import BackgroundImageFromLink from "./BackgroundImageFromLink";
// import AvatarIcon from "../../assets/images/icons/avatar-icon.svg";
// import AvatarFillIcon from "../../assets/images/icons/avatar-icon-fill.svg";
// import CompanyIcon from "../../assets/images/icons/company-icon.svg";
// import BimifySupportIcon from "../../assets/images/icons/bimify-support.svg";
import LogoCell from "../../components/DataTable/cells/LogoCell";

/**
 * Logo component
 *
 * @param {string} link
 * @param {string} alt
 * @param {boolean} grayscale - Whether there should be a filter added to the image
 * @param {string} size - size of image/logo, from sizeConstants, Default is SMALL
 * @param {string} wrapperClasses
 * @param {string} type - type of image (shows appropriate placeholder based on this), from logoTypesConstants, default USER
 * @param {boolean} stripped
 *
 * @return {React.FC} Logo component
 */
const Logo = ({
	link = "",
	alt = "",
	grayscale = false,
	size = sizeConstants.SMALL,
	wrapperClasses = "",
	type = logoTypesConstants.USER,
	stripped = false,
	isSentFromBimify = false,
}) => {
	const iconView = useMemo(() => {
		if (type === logoTypesConstants.USER) {
			return bimifyConstants.USER;
		}
		return bimifyConstants.CUSTOMER;
	}, [type]);

	if (!link) {
		return (
			<LogoCell
				wrapperClasses={wrapperClasses}
				view={iconView}
				size={size}
				iconSize={size === sizeConstants.LARGE ? 40 : sizeConstants.SMALL}
			/>
		);
	}

	return (
		<div
			className={`logo__wrapper  
            d-flex align-items-center justify-content-center
            logo__wrapper--${size}
            ${!!link && grayscale ? "logo__wrapper--grayscale" : ""}
            ${!!!link ? "logo__wrapper--placeholder" : ""}
			${stripped ? "logo__wrapper--stripped" : ""}
            ${wrapperClasses}
            `}
		>
			<BackgroundImageFromLink
				imageClass={`logo
						${type === logoTypesConstants.USER ? "logo--cover" : ""}
					`}
				name={alt}
				imageLink={link}
			/>
		</div>
	);
};

export default Logo;
