import React, { useEffect } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form/immutable";
import renderTextField from "../../../components/fields/renderTextField";
import { adornmentTypeConstants } from "../../../constants/constants";
import localize, { LocKeys } from "../../../constants/localizations";

const Level = (props) => {
	const {
		isSecondFloor,
		floor,
		isLowestFloor,
		isHighestFloor,
		formName,
		dispatch,
		hideRightSide = false,
		setValue,
		renderField,
	} = props;

	useEffect(() => {
		if (!hideRightSide) {
			dispatch(change(formName, `thickness-${floor.key}`, floor.thickness));
			dispatch(change(formName, `elevation-${floor.key}`, floor.elevation));
			dispatch(change(formName, `floor-${floor.level}`, floor.name));
		}
	}, [
		dispatch,
		floor.key,
		floor.elevation,
		floor.level,
		floor.name,
		floor.thickness,
		formName,
		hideRightSide,
	]);

	let upperFloor = floor.level >= 0 || floor.level === "Roof";

	let floorBg = "bg-n000";

	if (floor.level === 0) {
		floorBg = "bg-n100";
	}

	if (floor.level < 0) {
		floorBg = "bg-n200";
	}

	return (
		<div className="row row--m-gutters h-100">
			<div className={`col d-flex ${hideRightSide ? "col-100" : "col-60"}`}>
				{isHighestFloor ? (
					<div className={`roof-edges`}>
						<div className={`roof-bg ${floorBg}`}></div>
					</div>
				) : (
					<div
						className={` 
					floor-bg w-100
					${
						upperFloor
							? isSecondFloor
								? "floor-bg--roof floor-bg--upper"
								: "floor-bg--upper"
							: "floor-bg--lower"
					}
					${floorBg}
					${!upperFloor && floor.level === -1 ? "bt-n300" : ""}
					${!upperFloor && isLowestFloor ? "bb-n500" : ""}
				`}
					>
						{upperFloor && (
							<div className="upper-floor-additional-border"></div>
						)}

						{renderField(floor.key)}
					</div>
				)}
			</div>
			{!hideRightSide && (
				<div className="col col-40 d-flex align-items-end">
					<div
						className={`
        floor-elevation__wrapper
        ${floor.level === 0 ? "floor-elevation__wrapper--no-border" : ""}
        pb-xs row row--no-gutters`}
					>
						<div className="col col-20 align-items-end pl-0 pr-0 text-capitalize">
							{floor.level === "Roof" || isHighestFloor
								? localize(LocKeys.ROOF)
								: floor.level}
						</div>
						<div className="col col-40 pr-0">
							<Field
								name={`elevation-${floor.key}`}
								id={`elevation-${floor.key}`}
								component={renderTextField}
								disabled={true}
								min="0"
								type="number"
								adornmentType={adornmentTypeConstants.MILIMETERS}
								onKeyDown={(value) =>
									setValue && setValue(floor.key, "elevation", value)
								}
							/>
						</div>
						<div className="col col-40 pl-m pr-0">
							<Field
								name={`thickness-${floor.key}`}
								id={`thickness-${floor.key}`}
								component={renderTextField}
								disabled={true}
								min="0"
								type="number"
								adornmentType={adornmentTypeConstants.MILIMETERS}
								onKeyDown={(value) =>
									setValue && setValue(floor.key, "thickness", value)
								}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	return { form: ownProps.formName };
};

export default connect(
	mapStateToProps,
	{}
)(
	reduxForm({
		touchOnBlur: false,
		destroyOnUnmount: false,
	})(Level)
);
