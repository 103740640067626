import React, { useCallback, useEffect, useMemo } from "react";
import HeaderLayout from "../../../../../layout/content/HeaderLayout";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { Field } from "redux-form/immutable";
import renderTextField from "../../../../../fields/renderTextField";
import { connect } from "react-redux";
import {
	getBuildingJobInfo,
	getBuildings,
	updateAsyncLoadBuildings,
	updateTempModelBuilding,
} from "../../../../../../actions";
import SingleAutocomplete from "../../../../../fields/SingleAutocomplete";

const ModelUpdateHeader = ({ ...props }) => {
	const {
		//Redux props
		stopBuildingRefetch = false,
		customer,
		resellerCompanyId,
		buildingName,
		buildingId,
		buildings,

		//Redux State Actions
		updateTempModelBuilding,
		updateAsyncLoadBuildings,
		//Redux Actions
		getBuildings,
		getBuildingJobInfo,
	} = props;
	const [clearState, setClearState] = React.useState(true);

	useEffect(() => {
		if (buildingId && !stopBuildingRefetch) getBuildingJobInfo(buildingId);
	}, [buildingId, stopBuildingRefetch, getBuildingJobInfo]);

	const fetchBuildings = useCallback(
		(search, values = null) => {
			setClearState(false);
			getBuildings({
				searchQuery: search,
				searchFilters: null,
				customer,
				resellerCompanyId,
				limit: 15,
				isForSelectField: true,
				cbSelectField: (data) => updateAsyncLoadBuildings(data, values, true),
			});
		},
		[getBuildings, customer, resellerCompanyId, updateAsyncLoadBuildings]
	);

	useEffect(() => {
		if (buildingName && buildingId)
			fetchBuildings("", {
				label: buildingName,
				value: buildingId,
			});
	}, [fetchBuildings, buildingName, buildingId]);

	const setValue = useCallback(
		(fieldKey, value) => {
			updateTempModelBuilding(fieldKey, value);
		},
		[updateTempModelBuilding]
	);

	const buildingOptions = useMemo(() => {
		let buildingOptions = [];
		if (buildings && buildings.size > 0) {
			buildings &&
				buildings.map((building) => {
					let value = building.get("id");
					let label = building.get("name");
					return buildingOptions.push({ value, label });
				});
		}

		return buildingOptions;
	}, [buildings]);

	return (
		<HeaderLayout wrapperClasses="px-24 d-flex">
			<div className="row row--6p-gutters align-items-center">
				<div className="col col-50">
					<h3>{localize(LocKeys.UPDATE_SPECIFICATION)}</h3>
				</div>
				<div className="col col-25">
					<Field
						name="name"
						id="name"
						size={"w-100"}
						required={false}
						component={renderTextField}
						label={localize(LocKeys.JOB_NAME)}
						placeholder={
							localize(LocKeys.ENTER_X, [
								localize(LocKeys.JOB_NAME).toString().toLowerCase(),
							]) + "..."
						}
						showPlaceholder={true}
						onKeyDown={(value) => setValue("name", value)}
					/>
				</div>
				<div className="col col-25">
					<Field
						name="building"
						id="building"
						size={"w-100"}
						component={SingleAutocomplete}
						label={localize(LocKeys.BUILDING)}
						placeholder={localize(LocKeys.SELECT_BUILDING) + "..."}
						showPlaceholder={true}
						disableClearable={true}
						clearState={clearState}
						disableCloseOnSelect={false}
						options={buildingOptions}
						customOnChange={(data) => {
							setClearState(true);
							setValue("building", data.value);
							setValue("buildingName", data.label);
						}}
						initialValue={
							buildingName && buildingId
								? {
										label: buildingName,
										value: buildingId,
									} || null
								: null
						}
						asyncFetch={fetchBuildings}
					/>
				</div>
			</div>
		</HeaderLayout>
	);
};

const mapStateToProps = (state) => {
	return {
		buildings: state.getIn(["building", "asyncLoadBuildings"]),
		buildingId: state.getIn(["modelUpdate", "tempModelUpdate", "building"]),
		buildingName: state.getIn([
			"modelUpdate",
			"tempModelUpdate",
			"buildingName",
		]),
		stopBuildingRefetch: state.getIn(["modelUpdate", "stopBuildingRefetch"]),
		initialValues: {
			name: state.getIn(["modelUpdate", "tempModelUpdate", "name"]),
			building: state.getIn(["modelUpdate", "tempModelUpdate", "building"]),
		},
		customer: state.getIn(["global", "customer"]),
		resellerCompanyId: state.getIn(["global", "reseller"]),
	};
};

export default connect(mapStateToProps, {
	updateTempModelBuilding,
	updateAsyncLoadBuildings,
	getBuildings,
	getBuildingJobInfo,
})(ModelUpdateHeader);
