import { globalConstants } from "../constants/globalConstants";
import {
	removeItemFromLocalStorage,
	setItemToLocalStorage,
} from "../utils/local-storage.util";

/**
 * SET sleceted rows in global state
 *
 * @param {Array} selectedRows
 * @param {string} view - name of the list
 *
 */
export const setSelectedRows = ({ selectedRows, view }) => {
	return (dispatch) => {
		dispatch({
			type: globalConstants.SET_SELECTED_ROWS,
			data: { selectedRows: selectedRows, view: view },
		});
	};
};

/**
 * SET global CUSTOMER/RESELLER in state and local storage
 * @param {*} data
 * @returns
 */
export const setCustomer = (data, isReseller = false) => {
	return (dispatch) => {
		const key = isReseller ? "Reseller" : "Customer";
		if (!data) {
			removeItemFromLocalStorage(key);
		} else {
			setItemToLocalStorage(key, data);
		}

		if (isReseller) {
			dispatch({
				type: globalConstants.SET_GLOBAL_RESELLER,
				data,
			});
		} else {
			dispatch({
				type: globalConstants.SET_CUSTOMER,
				data,
			});
		}
	};
};

/**
 * SET room for global customer chat
 * @param {*} data
 * @returns
 */
export const setRoom = (data) => {
	return (dispatch) => {
		dispatch({
			type: globalConstants.SET_ROOM,
			data,
		});
	};
};
