import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M11.4922 15.2812C11.0625 15.7109 10.3984 15.7109 9.96875 15.2812L7.46875 12.7812C7.03906 12.3516 7.03906 11.6875 7.46875 11.2578C7.89844 10.8281 8.5625 10.8281 8.99219 11.2578L10.75 12.9766L14.9688 8.75781C15.3984 8.32812 16.0625 8.32812 16.4922 8.75781C16.9219 9.1875 16.9219 9.85156 16.4922 10.2812L11.4922 15.2812ZM22 12C22 17.5469 17.5078 22 12 22C6.45312 22 2 17.5469 2 12C2 6.49219 6.45312 2 12 2C17.5078 2 22 6.49219 22 12ZM12 3.875C7.50781 3.875 3.875 7.54688 3.875 12C3.875 16.4922 7.50781 20.125 12 20.125C16.4531 20.125 20.125 16.4922 20.125 12C20.125 7.54688 16.4531 3.875 12 3.875Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};
