import React from "react";
import FileUploadLevels from "./body/FileUploadLevels";
import CustomTabs from "../../../../../../shared/CustomTabs/CustomTabs";
import { connect } from "react-redux";

const FileUploadBody = (props) => {
	const { disciplines } = props;

	let tabs = [];
	if (disciplines) {
		tabs = disciplines
			.map((discipline) => discipline.toJS())
			.map((discipline, index) => {
				return {
					id: index,
					label: discipline.type,
					content: () => (
						<div className="overflow-y-auto page-content--477 bg-n300 pb-24">
							<FileUploadLevels disciplineId={discipline.id} />
						</div>
					),
				};
			});
	}
	return (
		<div className="w-100 pt-regular">
			<CustomTabs tabsClasses={"mb-0"} tabs={tabs} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		disciplines: state.getIn(["administration", "disciplines"]),
	};
};

export default connect(mapStateToProps, {})(FileUploadBody);
