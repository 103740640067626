import React, { useCallback } from "react";
import { connect } from "react-redux";
import ExportsComponent from "../../../../../../../shared/components/specification/ExportsComponent";
import localize, {
	LocKeys,
} from "../../../../../../../constants/localizations";
import { fromJS } from "immutable";
import { updateTempModelExports } from "../../../../../../../actions";

const ExportModelComponent = (props) => {
	const { initialValues, updateTempModelExports } = props;

	const updaateExports = useCallback(
		(data) => {
			updateTempModelExports(data);
		},
		[updateTempModelExports]
	);

	return (
		<ExportsComponent
			rowClass="mb-0"
			hidePrice={true}
			label={localize(LocKeys.EXPORTS)}
			updateSelected={(data) => updaateExports(data)}
			initialSelected={initialValues?.get("exports")}
			initialValues={initialValues}
			required={true}
			formName={"buildingUpdateSpecificationForm_1"}
		/>
	);
};

const mapStateToProps = (state) => {
	const tempExports =
		state.getIn(["modelUpdate", "tempModelUpdate", "exports"]) || [];
	const initialValues = {
		name: state.getIn(["modelUpdate", "tempModelUpdate", "name"]),
	};
	const exports = [];
	//EXPORT
	tempExports &&
		tempExports.forEach((item) => {
			exports.push(item.id);
			initialValues[`exportVersion_${item.id}`] = item.exportId;
		});
	initialValues["exports"] = exports;

	return {
		initialValues: fromJS(initialValues),
	};
};

export default connect(mapStateToProps, { updateTempModelExports })(
	ExportModelComponent
);
