import { tokenParams } from "../../utils/local-storage.util";
import {
	isBimifyReseller,
	isLoggedUserAdmin,
	isLoggedUserBO,
	isReseller,
} from "../../utils/permission/user";

export const canCreatePackage = () =>
	isBimifyReseller() && (isLoggedUserAdmin() || isLoggedUserBO());

export const canEditPackage = (companyId = null, resellerCompanyId = null) => {
	if (isReseller()) {
		const loggedUser = tokenParams();

		return (
			canCreatePackage() &&
			(resellerCompanyId === loggedUser.company?.id ||
				companyId === loggedUser.company?.id)
		);
	}

	return canCreatePackage();
};

export const canOrderFromPackage = () =>
	!(isLoggedUserAdmin() || isLoggedUserBO());
