/**
 *
 * @param {number} number
 * @returns Formatted number I.e. 1,2
 */
export const renderNumberFormat = (number, decimal = 0) => {
	if (!number && number < 0) {
		return "-";
	}
	var parts = Number(number).toFixed(decimal).toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

	return parts.join(",");
};

export const squareAppend = () => {
	return (
		<>
			&nbsp;
			<div className="color-n300">
				m<sup>2</sup>
			</div>
		</>
	);
};
