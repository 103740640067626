import React, { useCallback } from "react";
import AdditionalInfoEditor from "../../../../../orders/single-view/parts/AdditionalInfoEditor";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { connect } from "react-redux";
import { updateTempModelBuilding } from "../../../../../../actions";

const ModelUpdateAdditionalInfo = (props) => {
	const {
		//Redux props
		additionalInfo,
		//Redux State Actions
		updateTempModelBuilding,
	} = props;

	const updateEditorState = useCallback(
		(editorState) => {
			updateTempModelBuilding("additionalInfo", editorState);
		},
		[updateTempModelBuilding]
	);

	return (
		<AdditionalInfoEditor
			placeholder={`${localize(LocKeys.ADDITIONAL_INFORMATION_PLACEHOLDER)}...`}
			additionalInfo={additionalInfo}
			updateEditorState={updateEditorState}
			editView={true}
			isForModelUpdate={true}
			wrapperEditorClasses={`bg-n000 border-n150 border-1 border-radius-regular`}
			scrollerClasses="Lexical-content--515"
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		additionalInfo:
			state.getIn(["modelUpdate", "tempModelUpdate", "additionalInfo"]) || "",
	};
};

export default connect(mapStateToProps, { updateTempModelBuilding })(
	ModelUpdateAdditionalInfo
);
