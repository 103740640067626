import React from "react";
import IconWrapper from "../../icons/IconWrapper";
import OfferIcon from "../../icons/status/OfferIcon";
import OngoingIcon from "../../icons/status/OngoingIcon";
import ArchiveIcon from "../../icons/status/ArchiveIcon";
import CancelledIcon from "../../icons/status/CancelledIcon";
import CustomizeIcon from "../../icons/status/CustomizeIcon";
import NormalizeIcon from "../../icons/status/NormalizeIcon";
import DoneIcon from "../../icons/status/DoneIcon";
import IncompleteIcon from "../../icons/status/IncompleteIcon";
import RequestIcon from "../../icons/status/RequestIcon";
import { statusConstants } from "../../../constants/constants";
import QAIcon from "../../icons/status/QAIcon";
import localize, { LocKeys } from "../../../constants/localizations";
import DeliveredIcon from "../../icons/status/DeliveredIcon";
import LoadingIcon from "../../icons/status/LoadingIcon";
import ReviewedIcon from "../../icons/status/ReviewedIcon";

const Icon = ({ icon, wrapperClasses = "" }) => {
	return <IconWrapper wrapperClasses={wrapperClasses} size={24} icon={icon} />;
};

export const getStatusIcon = (status) => {
	switch (status) {
		case statusConstants.REQUESTED:
			return <RequestIcon iconClass="d-flex icon--target-fill" />;

		case statusConstants.REVIEWED:
			return <ReviewedIcon iconClass="d-flex icon--target-fill fill--green" />;

		case statusConstants.OFFERED:
			return <OfferIcon iconClass="d-flex icon--target-fill fill--blue" />;

		case statusConstants.ACCEPTED:
			return <DoneIcon iconClass="d-flex icon--target-fill fill--green" />;

		case statusConstants.DECLINED:
			return <CancelledIcon iconClass="d-flex icon--target-fill fill--red" />;
		case statusConstants.INCOMPLETE:
			return <IncompleteIcon iconClass="d-flex icon--target-fill fill--red" />;
		case statusConstants.ARCHIVED:
			return <ArchiveIcon iconClass="d-flex" />;

		case statusConstants.NORMALISATION:
			return (
				<NormalizeIcon iconClass="d-flex icon--target-fill fill--primary" />
			);

		case statusConstants.CUSTOMISATION:
			return <CustomizeIcon iconClass="d-flex icon--target-fill fill--blue" />;

		case statusConstants.DONE:
			return <DoneIcon iconClass="d-flex icon--target-fill fill--primary" />;

		case statusConstants.RESOLVED:
		case statusConstants.CATEGORISED:
		case statusConstants.COMPLETED:
			return <DoneIcon iconClass="d-flex icon--target-fill fill--green" />;

		case statusConstants.ACTIVE:
			return <OngoingIcon iconClass="d-flex icon--target-fill fill--green" />;

		case statusConstants.ONGOING:
			return <OngoingIcon iconClass="d-flex icon--target-fill fill--primary" />;

		case statusConstants.QA:
			return <QAIcon iconClass="d-flex icon--target-fill fill--blue" />;

		case statusConstants.DELIVERED:
			return <DeliveredIcon iconClass="d-flex icon--target-fill fill--green" />;

		case statusConstants.UNCATEGORISED:
			return <IncompleteIcon iconClass="d-flex icon--target-fill fill--n400" />;

		case statusConstants.PENDING:
			return <LoadingIcon />;

		default:
			return localize(LocKeys.ALL);
	}
};

export const getStatusIconWrapper = (status, wrapperClasses = "") => {
	const icon = getStatusIcon(status);
	return <Icon wrapperClasses={wrapperClasses} icon={icon} />;
};
