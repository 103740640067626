import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M21.0625 11.0518C20.5156 11.0518 20.125 11.4819 20.125 11.9902C20.125 14.2581 18.2891 16.0567 16.0625 16.0567H7.66406L10.125 13.5934C10.5156 13.2414 10.5156 12.6549 10.125 12.303C9.77344 11.912 9.1875 11.912 8.79688 12.303L4.73438 16.3695C4.38281 16.7214 4.38281 17.3079 4.73438 17.6598L8.79688 21.7263C8.99219 21.9218 9.22656 22 9.5 22C9.73438 22 9.96875 21.9218 10.125 21.7263C10.5156 21.3744 10.5156 20.7879 10.125 20.436L7.66406 17.9335H16.0625C19.3047 17.9335 22 15.2747 22 11.9902C22 11.4819 21.5703 11.0518 21.0625 11.0518ZM7.9375 7.92375H16.2969L13.8359 10.4262C13.4453 10.7781 13.4453 11.3646 13.8359 11.7165C13.9922 11.912 14.2266 11.9902 14.5 11.9902C14.7344 11.9902 14.9688 11.912 15.125 11.7165L19.1875 7.65005C19.5781 7.29814 19.5781 6.71163 19.1875 6.35973L15.125 2.29326C14.7734 1.90225 14.1875 1.90225 13.8359 2.29326C13.4453 2.64516 13.4453 3.23167 13.8359 3.58358L16.2969 6.04692H7.9375C4.65625 6.04692 2 8.74487 2 11.9902C2 12.5376 2.39062 12.9286 2.9375 12.9286C3.44531 12.9286 3.875 12.5376 3.875 11.9902C3.875 9.76149 5.67188 7.92375 7.9375 7.92375Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};
