import React from "react";

/**
 * Head Layout - above Contet layout (Metrics, Steps, Tabs)
 *
 * @param {string} wrapperClasses
 * @param {boolean} showHeaderLayout
 * @param {*} children
 *
 */
export const HeadLayout = ({
	children,
	showHeaderLayout,
	wrapperClasses = "",
}) => {
	return showHeaderLayout ? (
		<div className="row  row--6p-gutters">
			<div className={`col col-100`}>
				<div className={`w-100 d-flex  ${wrapperClasses}`}>{children}</div>
			</div>
		</div>
	) : (
		<>{children}</>
	);
};
