import React from "react";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { canEditJob } from "../parts/canEdit";
import SectionEdit from "../../../../../layout/section/SectionEdit";
import SectionView from "../../../../../layout/section/SectionView";
import JobExportsEdit from "../../edit/specification-view/JobExportsEdit";
import { exportsConstants } from "../../../../../../constants/constants";
import IconWrapper from "../../../../../../shared/icons/IconWrapper";
import { getExportImage } from "../../../../../../shared/components/specification/ExportsComponent";
import { connect } from "react-redux";

const JobExports = (props) => {
	const { jobExports, isModelHistory = false } = props;
	const [editView, setEditView] = React.useState(false);
	const canEdit = canEditJob();

	if (editView) {
		return (
			<SectionEdit title={localize(LocKeys.EXPORTS)}>
				<JobExportsEdit
					isModelHistory={isModelHistory}
					onClose={() => setEditView(!editView)}
				/>
			</SectionEdit>
		);
	}
	return (
		<SectionView
			title={localize(LocKeys.EXPORTS)}
			canEdit={canEdit}
			handleEdit={() => setEditView(!editView)}
		>
			{jobExports &&
				jobExports.map((jobExport, i) => {
					const tempExport = jobExport.get("export");
					const type = tempExport?.get("parent")
						? tempExport?.getIn(["parent", "type"])
						: tempExport.get("type");

					const isIFC = type === exportsConstants.IFC;
					return (
						<div
							key={i}
							className="py-m d-flex align-items-center border-b-1 border-n150"
						>
							<div className="w-100 d-flex align-items-center color-n300">
								<div className="mr-m">
									<IconWrapper size={24} icon={getExportImage(type, false)} />
								</div>
								<div className={`small`}>{type}</div>
								{isIFC && (
									<div className="ml-30 body-font--xs">
										{tempExport.get("type")}
									</div>
								)}
							</div>
						</div>
					);
				})}
		</SectionView>
	);
};

const mapStateToProps = (state) => {
	return {
		jobExports: state.getIn(["job", "job_1", "jobExports"]),
	};
};

export default connect(mapStateToProps, {})(JobExports);
