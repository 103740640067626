import React, { useState } from "react";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import SectionEdit from "../../../../../layout/section/SectionEdit";
import SectionView from "../../../../../layout/section/SectionView";
import JobBIMSpecificationEdit from "../../edit/specification-view/JobBIMSpecificationEdit";
import SingleSpecificationAccordion from "../../../../../orders/single-view/view/specification-summary/SingleSpecificationAccordion";
import { connect } from "react-redux";

const JobBIMSpecification = (props) => {
	const { jobId } = props;
	const [editView, setEditView] = useState(false);

	if (editView) {
		return (
			<SectionEdit title={localize(LocKeys.LOD_SPECIFICATION)}>
				<JobBIMSpecificationEdit onClose={() => setEditView(!editView)} />
			</SectionEdit>
		);
	}

	return (
		<SectionView title={localize(LocKeys.LOD_SPECIFICATION)}>
			<SingleSpecificationAccordion jobId={jobId} />
		</SectionView>
	);
};

const mapStateToProps = (state) => {
	return {
		jobId: state.getIn(["job", `job_${1}`, "id"]),
	};
};

export default connect(mapStateToProps, {})(JobBIMSpecification);
