import { destroy } from "redux-form";
import { api } from "../api";
import dashboardConstants from "../constants/dashboardConstants";
import localize, { LocKeys } from "../constants/localizations";
import orderTempConstants from "../constants/orderTempConstants";
import { getItemFromLocalStorage, getToken } from "../utils/local-storage.util";
import { refreshToken } from "./authActions";

/**
 * Retrieves all Packages
 *
 * SUCCESS:
 *   1. Populates dashbaord.salesPackageList with a packages array
 *
 * @returns http response with array of packages
 */
export const getPackages = ({
	search,
	filters,
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
	limit = 9,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/package`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				search,
				filters,
				selectedCompany: customer,
				resellerCompanyId,
				limit,
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: dashboardConstants.GET_SALES_PACKAGES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(dashboardActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Used to retrieve a single package based on the provided id
 *
 * SUCCESS: Populates 'salesPackage' with the response package object
 *
 * ERROR: Triggers dashboardActionFailure()
 *
 * @param {number} packageId -  package unique identifier
 * @param {boolean} tempPackage - - when true tempPackage will be prefilled with response values
 * @param {boolean} tempOffer - - when true tempOffer will be prefilled with response values
 * @param {function} cb - callback function
 * @returns
 */
export const getPackage = (
	packageId,
	tempPackage = false,
	tempOffer = false,
	cb = () => {}
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/package/${packageId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (tempPackage) {
					dispatch({
						type: dashboardConstants.SET_TEMP_SALES_PACKAGE,
						data: res.data.result,
					});
				}
				if (!!tempOffer) {
					dispatch({
						type: orderTempConstants.SET_STATE_FROM_PACKAGE,
						data: res.data.result,
					});
				}
				dispatch({
					type: dashboardConstants.GET_SINGLE_PACKAGE,
					data: res.data.result,
				});
			},
			(err) => {
				cb();
				dispatch(dashboardActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves package stats
 *
 * SUCCESS: Populates 'stats' in the dashboard state.
 *
 * @returns response with array of package stats
 */
export const getPackageStats = ({
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
	search,
	filters,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: "/package-stats",
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				customer: customer,
				search,
				filters,
				resellerCompanyId,
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: dashboardConstants.GET_PACKAGE_STATS,
					data: res.data,
				});
			},
			(err) => {
				dispatch(dashboardActionFailure(err.response.data.message));
			}
		);
	};
};
/**
 * CREATE PACKAGE
 *
 * SUCCESS:
 * 		1. getPackages
 * 		2. dashboardActionSuccess
 * 		3. clearTempPackage and cb()
 *
 *
 * @param {*} data - Contains all package form data in a key-value pair
 * @param {*} cb - callback function
 * @returns
 */
export const createPackage = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/package`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					dashboardActionSuccess(
						localize(LocKeys.ITEM_CREATED, [localize(LocKeys.PACKAGE)])
					)
				);

				dispatch(clearTempPackage());
				dispatch(refreshToken());
				cb(res.data.result);
			},
			(err) => {
				dispatch(
					dashboardActionFailure(
						localize(LocKeys.ERROR_ITEM_CREATED, [localize(LocKeys.PACKAGE)])
					)
				);
			}
		);
	};
};

/**
 * Update package endpoint. Used to change the data of a single package.
 *
 * SUCCESS:
 * 	 1. dashboardActionSuccess
 * 	 2. clearTempPackage
 * 	 3. getPackage and cb()
 *
 *
 * @param {*} id - Unique package identifier
 * @param {*} data - contains all company form data in a key-value pair
 * @param {*} cb - callback function
 * @returns
 */
export const updatePackage = (id, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/package/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					dashboardActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.PACKAGE)])
					)
				);

				dispatch(getPackage(id, true)).then(() => cb());

				//	dispatch(clearTempPackage("editPackageForm"));
			},
			(err) => {
				dispatch(
					dashboardActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.PACKAGE)])
					)
				);
			}
		);
	};
};

/**
 * DELETE package
 *
 * SUCEESS:
 * 	1. Success message
 *  2. Callback function - getPackages()
 *
 * @param {number} id - Unique package identifier
 * @param {*} cb - callback function
 * @returns
 */
export const deletePackage = (id, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/package/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					dashboardActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.PACKAGE)])
					)
				);
				cb();
			},
			(err) => {
				dispatch(
					dashboardActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.PACKAGE)])
					)
				);
			}
		);
	};
};

/**
 * Re configuration package endpoint. Used to change price per input format.
 *
 * @param {number} packageId - Unique package identifier
 * @param {object} data - contains disciplineComponentCategoryLods object with key-value pairs (componentCategoryId, lodId, disciplineId, lodType)
 * @param {Function} cb - callback function
 * @returns
 */
export const reConfigurationPackage = (packageId, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PATCH",
			url: `/package/${packageId}/re-configuration`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: dashboardConstants.RE_CONFIGURATION_PACKAGE,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(
					dashboardActionFailure(
						localize(LocKeys.ERROR, [localize(LocKeys.PACKAGE)])
					)
				);
			}
		);
	};
};

/**
 * Set Cancel Form Order
 * @returns
 */
export const setCancelFormOrder = (cancelFormOrder) => {
	return (dispatch) => {
		dispatch({
			type: dashboardConstants.SET_CANCEL_FORM_ORDER,
			data: cancelFormOrder,
		});
	};
};

/**
 * Update TEMP PACKAGE field value
 *
 * @param {*} fieldKey - field key
 * @param {*} value
 * @returns
 */
export const setPackageTempFieldValue = (fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: dashboardConstants.SET_TEMP_FIELD_VALUE,
			data: { fieldKey, value },
		});
	};
};

/**
 * SET temp image data inside TEMP PACKAGE
 *
 * @param {object} file - image data
 * @returns
 */
export const setPackageTempImage = (file) => {
	return {
		type: dashboardConstants.SET_TEMP_IMAGE,
		data: file,
	};
};

/**
 * SET temp image URL inside TEMP PACKAGE
 *
 * @param {string} data - image url
 * @returns
 */
export const setPackageTempImageUrl = (data) => {
	return (dispatch) => {
		dispatch({
			type: dashboardConstants.SET_TEMP_IMAGE_URL,
			data,
		});
	};
};

/**
 * SET temp FILE inside TEMP PACKAGE
 *
 * @param {object} data - file data
 * @returns
 */
export const setPackageTempFile = (data) => {
	return (dispatch) => {
		dispatch({
			type: dashboardConstants.SET_TEMP_FILE,
			data,
		});
	};
};

/**
 * Set Export data inside TEMP PACKAGE for checkboxes
 * @param {object} data - export data
 * @returns
 */
export const setTempExports = (data) => {
	return (dispatch) => {
		dispatch({
			type: dashboardConstants.SET_TEMP_EXPORTS,
			data,
		});
	};
};

/*
 * Set temp FILE inside TEMP PACKAGE
 * @param {object} data - file data
 * @returns
 */
export const setPackageTempModelFile = (data) => {
	return (dispatch) => {
		dispatch({
			type: dashboardConstants.SET_TEMP_MODEL_FILE,
			data,
		});
	};
};

/**
 * Clear TEMP PACKAGE data
 *
 * @param {string} formName
 * @returns
 */
export const clearTempPackage = (formName = "createPackageForm") => {
	return (dispatch) => {
		dispatch(destroy(formName));
		dispatch({ type: dashboardConstants.CLEAR_TEMP_PACKAGE });
	};
};

/**
 * Snackbar Action Success
 * @param {string} message
 * @returns
 */
export const dashboardActionSuccess = (message) => {
	return { type: dashboardConstants.DASHBOARD_ACTION_SUCCESS, data: message };
};

/**
 * Snackbar Action Failure
 * @param {string} message
 * @returns
 */
export const dashboardActionFailure = (message) => {
	return { type: dashboardConstants.DASHBOARD_ACTION_FAILURE, data: message };
};

/**
 * CLEAR package request state
 */
export const clearDashboardRequestState = () => {
	return { type: dashboardConstants.CLEAR_DASHBAORD_REQUEST_STATE };
};
