import React from "react";
import SectionHeader from "../../orders/single-view/parts/SectionHeader";

const SectionView = ({
	title,
	canEdit = false,
	handleEdit = () => {},
	children,
	wrapperClasses = "pl-48 pr-24",
	...props
}) => {
	return (
		<div className={`mt-48 ${wrapperClasses}`}>
			<SectionHeader
				title={title}
				wrapperClasses="py-regular border-b-1 border-n150"
				isEdit={canEdit}
				handleEdit={handleEdit}
			/>
			{children}
		</div>
	);
};

export default SectionView;
