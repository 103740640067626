import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M20.7964 17C20.5239 17.4911 20.0242 17.7143 19.5245 17.7143C19.252 17.7143 19.0249 17.6696 18.7977 17.5357L13.4831 14.5V20.5714C13.4831 21.375 12.8018 22 12.0296 22C11.3028 22 10.576 21.375 10.576 20.5714V14.5L5.17057 17.5357C4.94345 17.6696 4.71633 17.7143 4.44379 17.7143C3.94412 17.7143 3.44446 17.4911 3.21734 17C2.80853 16.3304 3.03564 15.4821 3.717 15.0804L9.07703 12L3.717 8.96429C3.03564 8.5625 2.80853 7.71429 3.17192 7C3.48988 6.50893 4.0804 6.24107 4.62548 6.33036C4.80718 6.33036 4.98887 6.41964 5.17057 6.50893L10.576 9.54464V3.42857C10.576 2.66964 11.212 2 12.0296 2C12.8018 2 13.4831 2.66964 13.4831 3.42857V9.54464L18.8432 6.50893C19.0249 6.41964 19.2066 6.33036 19.3883 6.33036C19.9333 6.24107 20.5239 6.50893 20.7964 7C21.1598 7.71429 20.9327 8.5625 20.2513 8.96429L14.9367 12L20.2967 15.0804C20.9781 15.4821 21.2052 16.3304 20.7964 17Z"
					fill="#7F909F"
				/>
			</svg>
		</div>
	);
};
