import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import FormSubmitButtons from "../../../../../../shared/components/FormComponents/FormSubmitButtons";
import CustomAutocomplete from "../../../../../fields/CustomAutocomplete";
import { Field, formValueSelector, reduxForm } from "redux-form/immutable";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { getLodSpecifications, updateJob } from "../../../../../../actions";
import { useParams } from "react-router-dom-v5-compat";

const JobBIMSpecificationEdit = ({ initialValues, onClose, ...props }) => {
	const { jobId } = useParams();
	const { specification, updateJob, getLodSpecifications } = props;
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		getLodSpecifications({ usePagination: false, filterPublic: true });
	}, [getLodSpecifications]);

	const onSubmit = () => {
		try {
			setLoading(true);

			const body = {
				lodSpecification: specification,
			};

			updateJob({
				jobId,
				data: body,
				step: 1,
				cb: () => {
					onClose && onClose();
				},
			});
		} catch (e) {
			return;
		}
	};

	const lodSpecificationOptions = [];
	if (props.lodSpecifications && props.lodSpecifications.size > 0) {
		props.lodSpecifications.map((lodSpecification) => {
			return lodSpecificationOptions.push({
				value: lodSpecification.get("id"),
				label: lodSpecification.get("name"),
			});
		});
	}

	const handleClose = useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<div className="row row--6p-gutters">
				<div className="col col-100 col-desktop-40">
					<Field
						name="specification"
						id="specification"
						classField="mb-24"
						dropdownHeight="400px"
						label={localize(LocKeys.LOD_SPECIFICATION)}
						placeholder={localize(LocKeys.SELECT_LOD_SPECIFICATION) + "..."}
						required={true}
						showPlaceholder={true}
						// customOnChange={(value) => {
						// 	setValue("lodSpecification", value);
						// }}
						component={CustomAutocomplete}
						disableClearable={true}
						options={lodSpecificationOptions}
						initialValue={initialValues.get("specification") || null}
					/>
				</div>
			</div>
			<FormSubmitButtons
				wrapperClasses="w-100 py-24"
				onCancel={handleClose}
				loading={loading}
			/>
		</form>
	);
};

const selector = formValueSelector("jobBIMSpecificationEditForm");
const mapStateToProps = (state) => {
	return {
		initialValues: {
			specification: state.getIn(["job", "tempJob", "lodSpecification"]),
		},
		specification:
			selector(state, "specification") ||
			state.getIn(["job", "tempJob", "lodSpecification"]),
		lodSpecifications: state.getIn([
			"lodSpecification",
			"lodSpecificationList",
		]),
	};
};

export default connect(mapStateToProps, {
	getLodSpecifications,
	updateJob,
})(
	reduxForm({
		form: "jobBIMSpecificationEditForm",
		touchOnBlur: false,
		destroyOnUnmount: false,
		enableReinitialize: true,
	})(JobBIMSpecificationEdit)
);
