import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form/immutable";
import FormSubmitButtons from "../../../../../../shared/components/FormComponents/FormSubmitButtons";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { useParams } from "react-router-dom-v5-compat";
import {
	getDeliveryFormat,
	getRevitTemplates,
	getServiceType,
	updateJob,
} from "../../../../../../actions";
import CustomAutocomplete from "../../../../../fields/CustomAutocomplete";
import IconWrapper from "../../../../../../shared/icons/IconWrapper";
import { getDeliveryFormatIcon } from "../../../../../../utils/getTypeIcon";
import CustomLabel from "../../../../../fields/CustomLabel";
import { statusConstants } from "../../../../../../constants/constants";

const JobInfoEdit = ({
	initialValues,
	onClose,
	//Actions redux
	getDeliveryFormat,
	getRevitTemplates,
	getServiceType,
	...props
}) => {
	const {
		services,
		isModelHistory = false,
		tempJobInfo,
		deliveryFormats,
		revitTemplateList,
		updateJob,
	} = props;
	const [loading, setLoading] = useState(false);
	const { jobId } = useParams();

	useEffect(() => {
		getDeliveryFormat();
		getServiceType();
		getRevitTemplates({ usePagination: false });
	}, [getDeliveryFormat, getRevitTemplates, getServiceType]);

	const onSubmit = (formValues) => {
		try {
			setLoading(true);
			const data = formValues?.toJS();
			const body = {
				deliveryFormats: [data.deliveryFormat],
				status: statusConstants.REQUESTED,
				serviceTypeId: data.service,
				revitFileId: data?.template,
			};

			updateJob({
				jobId,
				data: body,
				step: 1,
				cb: () => {
					setLoading(false);
					onClose && onClose();
				},
				isModelHistory,
			});
		} catch (error) {
			console.log("Order info edit error: ", error);
			return;
		}
	};

	const handleClose = () => {
		//resetOrderView();
		onClose();
	};

	// const setValue = useCallback((fieldKey, value) => {
	// 	//	setStateTempFieldValue(fieldKey, value, null, true);
	// }, []);

	let deliveryFormatOptions = [];
	if (deliveryFormats && deliveryFormats.size > 0) {
		deliveryFormats.map((format, i) => {
			let subHeader = (
				<div
					className={`dropdown-subheader body-font--small  semi  ${+i === 0 ? "" : "dropdown-subheader--border"}`}
				>
					<div className="d-flex align-items-center w-100">
						<div>
							<IconWrapper
								size="24"
								wrapperClasses="mr-s"
								icon={getDeliveryFormatIcon(format.get("type"))}
							/>
						</div>
						<div className="text-overflow-ellipsis">{format.get("type")}</div>
					</div>
				</div>
			);

			const children = format?.get("children");

			children &&
				children.map((item) => {
					deliveryFormatOptions.push({
						subHeader,
						label: item.get("type"),
						value: item.get("id"),
					});

					return deliveryFormatOptions;
				});

			return deliveryFormatOptions;
		});
	}

	const templateOptions = useMemo(() => {
		const options = [];

		revitTemplateList &&
			revitTemplateList.map((template) => {
				return options.push({
					label: template.get("name"),
					value: template.get("id"),
				});
			});

		return options;
	}, [revitTemplateList]);

	const serviceOptions = useMemo(() => {
		const options = [];

		services &&
			services.map((service) => {
				return options.push({
					label: service.get("type"),
					value: +service.get("id"),
				});
			});

		return options;
	}, [services]);

	const fields = useMemo(() => ["deliveryFormat", "template"], []);

	const isValid = useMemo(() => {
		let hasInvalidValue = Object.keys(tempJobInfo).some(
			(key) => fields.includes(key) && !tempJobInfo[key]
		);

		return !hasInvalidValue;
	}, [tempJobInfo, fields]);

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<div className="border-b-1 border-n150">
				<div className="row row--6p-gutters align-items-center pb-s">
					<div className={`col col-33`}>
						<CustomLabel
							label={<h5 className="small">{localize(LocKeys.SERVICE)}</h5>}
							required={true}
						/>
					</div>
					<div className={`col col-33`}>
						<CustomLabel
							label={
								<h5 className="small">{localize(LocKeys.DELIVERY_FORMAT)}</h5>
							}
							required={true}
						/>
					</div>
					<div className={`col col-33`}>
						<CustomLabel
							label={
								<h5 className="small">{localize(LocKeys.TEMPLATE_LABEL)}</h5>
							}
							required={true}
						/>
					</div>
				</div>
			</div>
			<div className="border-b-1 border-n150 py-m mb-24">
				<div className="row row--6p-gutters">
					<div className="col col-33">
						<Field
							name="service"
							id="service"
							placeholder={
								localize(LocKeys.SELECT_X, [
									localize(LocKeys.SERVICE).toString().toLowerCase(),
								]) + "..."
							}
							disableClearable={true}
							required={true}
							showPlaceholder={true}
							// customOnChange={(value) => {
							// 	setValue("deliveryFormat", value);
							// }}
							component={CustomAutocomplete}
							options={serviceOptions}
							initialValue={+initialValues.get("service") || null}
						/>
					</div>
					<div className="col col-33">
						<Field
							name="deliveryFormat"
							id="deliveryFormat"
							dropdownHeight="400px"
							disableClearable={true}
							groupBy={true}
							placeholder={localize(LocKeys.SELECT_DELIVERY_FORMAT) + "..."}
							required={true}
							showPlaceholder={true}
							// customOnChange={(value) => {
							// 	setValue("deliveryFormat", value);
							// }}
							component={CustomAutocomplete}
							options={deliveryFormatOptions}
							initialValue={initialValues.get("deliveryFormat") || null}
						/>
					</div>
					<div className="col col-33">
						<Field
							name="template"
							id="template"
							dropdownHeight="400px"
							placeholder={localize(LocKeys.SELECT_TEMPLATE) + "..."}
							required={true}
							showPlaceholder={true}
							// customOnChange={(value) => {
							// 	setValue("template", value);
							// }}
							component={CustomAutocomplete}
							disableClearable={true}
							options={templateOptions}
							initialValue={initialValues.get("template") || ""}
						/>
					</div>
				</div>
			</div>
			<FormSubmitButtons
				wrapperClasses="py-24"
				onCancel={handleClose}
				submitDisabled={loading || !isValid}
				loading={loading}
			/>
		</form>
	);
};
const selector = formValueSelector("jobInfoEditForm");
const mapStateToProps = (state) => {
	return {
		tempJobInfo: {
			deliveryFormat: selector(state, "deliveryFormat"),
			template: selector(state, "template"),
			service: selector(state, "service"),
		},
		deliveryFormats: state.getIn(["administration", "deliveryFormats"]),
		revitTemplateList: state.getIn(["template", "revitTemplateList"]),
		services: state.getIn(["administration", "serviceType"]),
		initialValues: {
			deliveryFormat:
				state.getIn(["job", "tempJob", "jobInfo", "deliveryFormat"]) ||
				selector(state, "deliveryFormat"),
			service:
				state.getIn(["job", "tempJob", "jobInfo", "service"]) ||
				selector(state, "service"),
			template:
				state.getIn(["job", "tempJob", "jobInfo", "template"]) ||
				selector(state, "template"),
		},
	};
};

export default connect(mapStateToProps, {
	getDeliveryFormat,
	getRevitTemplates,
	updateJob,
	getServiceType,
})(
	reduxForm({
		form: "jobInfoEditForm",
		destroyOnUnmount: false,
		enableReinitialize: true,
		touchOnBlur: false,
	})(JobInfoEdit)
);
