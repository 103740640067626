import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15.6667 11.0556L19.5833 7.11111L18.8056 6.33333L15.6667 9.47222L14.0833 7.88889L13.3056 8.69444L15.6667 11.0556ZM2 21.8889V19.6667H15.3333V21.8889H2ZM16.4444 14.1111C14.9074 14.1111 13.5972 13.5694 12.5139 12.4861C11.4306 11.4028 10.8889 10.0926 10.8889 8.55556C10.8889 7.01852 11.4306 5.70833 12.5139 4.625C13.5972 3.54167 14.9074 3 16.4444 3C17.9815 3 19.2917 3.54167 20.375 4.625C21.4583 5.70833 22 7.01852 22 8.55556C22 10.0926 21.4583 11.4028 20.375 12.4861C19.2917 13.5694 17.9815 14.1111 16.4444 14.1111ZM2 13V10.7778H9C9.12963 11.1852 9.27778 11.5741 9.44444 11.9444C9.61111 12.3148 9.81482 12.6667 10.0556 13H2ZM2 17.4444V15.2222H12.4444C12.8704 15.4815 13.3241 15.6991 13.8056 15.875C14.287 16.0509 14.7963 16.1759 15.3333 16.25V17.4444H2Z"
					fill="#1B2024"
				/>
			</svg>
		</div>
	);
};
