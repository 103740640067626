import React from "react";
import localize, { LocKeys } from "../../../../constants/localizations";
import LodEditor from "../../../administration/lod-specification/lod-content/parts/LodEditor";
import { renderParameterValue } from "../../../administration/lod-specification/lod-content/single-view/view/ParameterCategory";
import { connect } from "react-redux";
import { returnContentImage } from "../../../administration/lod-specification/lod-content/single-view/view/SummaryImage";

const ComponentCategoryContentForOffer = ({ content }) => {
	const {
		threeDFile,
		// twoDFile,
		description,
		parameterCategories,
	} = content;

	return (
		<>
			<div className="d-flex align-items-center border-n150 border-b-1 pl-56 pr-regular py-m">
				<div className="body-font semi">{localize(LocKeys.CONTENT)}</div>
			</div>
			<div className="row row--no-gutters w-100">
				<div className="col col-100 col-desktop-50 border-r-1  border-b-1 border-n150 pl-0 pr-0">
					<div className="pl-56 pr-regular py-m">
						{localize(LocKeys.VISUAL_REPRESENTATION)}
					</div>
				</div>

				<div className="col col-100  col-desktop-50 border-b-1 border-n150 pl-0 pr-0 max-h-650p">
					<div className="py-24 w-100 h-100 px-regular">
						{returnContentImage(threeDFile?.publicUrl, true)}
					</div>
				</div>
			</div>
			{/* <div className="row row--no-gutters w-100">
				<div className="col col-100 col-desktop-50 border-r-1  border-b-1 border-n150 pl-0 pr-0">
					<div className="pl-56 pr-regular py-m">
						{localize(LocKeys.IMAGE_2D)}
					</div>
				</div>

				<div className="col col-100  col-desktop-50 border-b-1 border-n150 pl-0 pr-0 max-h-390p">
					<div className="py-24 w-100 h-100 px-regular">
						{returnContentImage(twoDFile?.publicUrl, true)}
					</div>
				</div>
			</div> */}
			<div className="row row--no-gutters w-100">
				<div className="col col-100 col-desktop-50 border-r-1 border-b-1 border-n150 pl-0 pr-0">
					<div className="pl-56 pr-regular py-m">
						{localize(LocKeys.SUMMARY_LABEL)}
					</div>
				</div>
				<div className="col col-100 pb-32 pb-desktop-0  col-desktop-50 border-b-1 border-n150 pl-0 pr-0">
					<div className="py-s w-100 h-100  px-regular">
						<LodEditor isEdit={false} summary={description || ""} />
					</div>
				</div>
			</div>
			{parameterCategories?.map((parameterCategory, pcIndex) => {
				const parameters = parameterCategory?.parameters || [];
				const canHideCategory = parameters?.length === 0;

				if (!canHideCategory) {
					return (
						<div key={pcIndex}>
							<div className="d-flex align-items-center  border-n150 border-b-1 pl-56 pr-regular py-m">
								<div className="body-font semi">{parameterCategory.name}</div>
							</div>

							{parameters &&
								parameters.map((parameter, index) => {
									return (
										<div key={index} className="row row--no-gutters w-100">
											<div className="col col-100 col-desktop-50 border-r-1  border-b-1 border-n150 pl-0 pr-0">
												<div className="pl-56 pr-regular py-m">
													{parameter?.parameterCategory?.name}
												</div>
											</div>

											<div className="col col-100  col-desktop-50 border-b-1 border-n150 pl-0 pr-0 max-h-390p">
												<div className="py-m  max-h-48p h-48p w-100 h-100 px-regular">
													{renderParameterValue({
														type:
															parameter?.parameterCategory?.inputFieldType
																?.type,
														txtValue: parameter?.txtValue,
														boolValue: parameter?.boolValue,
														isForSpecification: true,
														textLength: 40,
													})}
												</div>
											</div>
										</div>
									);
								})}
						</div>
					);
				} else {
					return "";
				}
			})}
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, {})(ComponentCategoryContentForOffer);
