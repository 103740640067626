import React, { useEffect } from "react";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import CustomBadge from "../../shared/components/CustomBadge/CustomBadge";
import {
	getAllTicketComments,
	incrementCommentsCounter,
	setCommentsCounter,
} from "../../actions";
import { connect } from "react-redux";
import { getSocket } from "../../utils/web-socket";
import { tokenParams } from "../../utils/local-storage.util";
import { useLocation } from "react-router-dom-v5-compat";

const TicketSidebar = ({
	route,
	ticketCommentsCount,
	getAllTicketComments,
	setCommentsCounter,
	incrementCommentsCounter,
}) => {
	const { pathname } = useLocation();
	const isActive = pathname.startsWith(route);

	const userId = tokenParams()?.id;

	useEffect(() => {
		getAllTicketComments();
	}, [getAllTicketComments]);

	useEffect(() => {
		if (userId) {
			const event = `unread-count-${userId}`;
			const eventComment = `ticket-comment`;

			const socket = getSocket();
			socket.connect();

			const listener = ({ count }) => {
				setCommentsCounter(count);
			};
			const listenerComment = ({ notifiedUsers }) => {
				if (notifiedUsers?.includes(Number(userId))) {
					incrementCommentsCounter();
				}
			};

			socket.on(event, listener);
			socket.on(eventComment, listenerComment);

			return () => {
				socket.off(event, listener);
				socket.off(eventComment, listenerComment);
				socket.disconnect();
			};
		}
	}, [userId, setCommentsCounter, incrementCommentsCounter]);

	return (
		<CustomBadge
			infobarPlacement={true}
			invisible={Number(ticketCommentsCount) === 0}
			badgeContent={ticketCommentsCount}
			chatIndicator={true}
			customClasses={`unread-comments-counter ${isActive ? "bg-red" : ""}`}
		>
			<QuestionAnswerOutlinedIcon style={{ fontSize: 32 }} />
		</CustomBadge>
	);
};

const mapStateToProps = (state) => {
	return {
		ticketCommentsCount: state.getIn(["ticketing", "ticketCommentsCount"]),
	};
};

export default connect(mapStateToProps, {
	getAllTicketComments,
	setCommentsCounter,
	incrementCommentsCounter,
})(TicketSidebar);
