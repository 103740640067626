export const getFilters = ({ tableFilters, searchFilters, searchQuery }) => {
	let search = searchQuery;
	let filters = {
		types: searchFilters ? searchFilters?.types : [],
		status: searchFilters?.status,
	};

	if (!searchFilters?.types && !searchFilters?.types?.length > 0) {
		filters.types = (tableFilters && tableFilters.get("types")) || [];
	}
	if (!searchFilters?.status && !searchFilters?.status?.length > 0) {
		filters.status =
			(tableFilters &&
				!!tableFilters?.get("status") && [tableFilters.get("status")]) ||
			[];
	}
	if (!searchQuery) {
		search = tableFilters && tableFilters.get("table-search");
	}

	return { filters, search };
};

export const getTicketFilters = ({
	queryParameter,
	searchFilters,
	searchQuery,
}) => {
	let search = searchQuery;
	let filters = {
		statuses: searchFilters?.statuses,
		priority: searchFilters?.priority,
		orderIds: searchFilters?.orderIds,
		jobIds: searchFilters?.jobIds,
		myTickets: queryParameter?.myTickets?.[0] || false,
		buildingIds: searchFilters?.buildingIds,
	};

	if (!searchFilters?.statuses && !searchFilters?.statuses?.length > 0) {
		filters.statuses = queryParameter.status || [];
	}
	if (!searchFilters?.priority && !searchFilters?.priority?.length > 0) {
		filters.priority = queryParameter.priority || [];
	}
	if (!searchFilters?.orderIds && !searchFilters?.orderIds?.length > 0) {
		filters.orderIds =
			(queryParameter && queryParameter?.offers?.map((item) => item.value)) ||
			[];
	}
	if (!searchFilters?.jobIds && !searchFilters?.jobIds?.length > 0) {
		filters.jobIds =
			(queryParameter && queryParameter?.jobs?.map((item) => item.value)) || [];
	}
	if (!searchFilters?.buildingIds && !searchFilters?.buildingIds?.length > 0) {
		filters.buildingIds =
			(queryParameter &&
				queryParameter?.buildings?.map((item) => item.value)) ||
			[];
	}

	if (!searchQuery) {
		search = queryParameter?.search;
	}

	return { filters, search };
};

export const getManualFilters = ({
	tableFilters,
	statuses,
	searchQuery,
	updateStatus,
}) => {
	let search = searchQuery;
	let status = statuses;

	if (!statuses && updateStatus) {
		status =
			(tableFilters &&
				!!tableFilters?.get("status") &&
				tableFilters.get("status")) ||
			"";
	}

	if (!searchQuery) {
		search = tableFilters && tableFilters.get("table-search");
	}
	return { status, search };
};

export const getQuery = ({ queryParameter, searchFilters, searchQuery }) => {
	let search = searchQuery;
	let filters = {
		types: searchFilters ? searchFilters?.types : [],
		status: searchFilters?.status,
		orderIds: searchFilters?.orderIds,
	};

	if (!searchFilters?.types && !searchFilters?.types?.length > 0) {
		filters.types = queryParameter?.types || [];
	}
	if (!searchFilters?.status && !searchFilters?.status?.length > 0) {
		filters.status = queryParameter?.status || [];
	}
	if (!searchFilters?.orderIds && !searchFilters?.orderIds?.length > 0) {
		filters.orderIds =
			(queryParameter && queryParameter?.offers?.map((item) => item.value)) ||
			[];
	}
	if (!searchQuery) {
		search = queryParameter?.search;
	}

	return { filters, search };
};

export const getPagination = ({ tablePagination, page, limit }) => {
	const currentPage = !!tablePagination?.get("currentPage")
		? tablePagination.get("currentPage")
		: page;

	const currentLimit = !!tablePagination?.get("limit")
		? tablePagination.get("limit")
		: limit;

	return { currentPage, currentLimit };
};

export const getUserFilter = ({
	queryParameter,
	searchFilters,
	searchQuery,
}) => {
	let search = searchQuery;
	let filters = {
		roles: searchFilters ? searchFilters?.roles : [],
		status: searchFilters?.status,
	};

	if (!searchFilters?.roles && !searchFilters?.roles?.length > 0) {
		filters.roles = queryParameter?.roles || [];
	}
	if (!searchFilters?.status && !searchFilters?.status?.length > 0) {
		filters.status = queryParameter?.status || [];
	}
	if (!searchQuery) {
		search = queryParameter?.search;
	}

	return { filters, search };
};

export const getCompanyFilters = ({
	queryParameter,
	searchFilters,
	searchQuery,
	types,
	roles,
	countries,
}) => {
	let search = searchQuery;
	let typesFilter = types;
	let rolesFilter = roles;
	let countriesFilter = countries;
	let filters = {
		status: searchFilters?.status,
	};

	if (!roles && !roles?.length > 0) {
		rolesFilter = queryParameter?.roles || [];
	}
	if (!types && !types?.length > 0) {
		typesFilter = queryParameter?.types || [];
	}
	if (!countries && !countries?.length > 0) {
		countriesFilter = queryParameter?.countries || [];
	}

	if (!searchFilters?.status && !searchFilters?.status?.length > 0) {
		filters.status = queryParameter?.status || [];
	}
	if (!searchQuery) {
		search = queryParameter?.search;
	}

	return { filters, search, countriesFilter, typesFilter, rolesFilter };
};

export const getSymbolFilters = ({
	queryParameter,
	searchFilters,
	searchQuery,
}) => {
	let search = searchQuery;
	let filters = {
		statuses: searchFilters?.statuses,
		disciplineComponentCategoryIds:
			searchFilters?.disciplineComponentCategoryIds,
		familyIds: searchFilters?.familyIds,
	};

	if (
		!searchFilters?.disciplineComponentCategoryIds &&
		!searchFilters?.disciplineComponentCategoryIds?.length > 0
	) {
		filters.disciplineComponentCategoryIds = queryParameter?.components || [];
	}
	if (!searchFilters?.familyIds && !searchFilters?.familyIds?.length > 0) {
		filters.familyIds = queryParameter?.family || [];
	}
	if (!searchFilters?.statuses && !searchFilters?.statuses?.length > 0) {
		filters.statuses = queryParameter?.status || [];
	}
	if (!searchQuery) {
		search = queryParameter?.search;
	}

	return { filters, search };
};

export const getTemplateFilters = ({
	queryParameter,
	searchFilters,
	searchQuery,
}) => {
	let search = searchQuery;
	let filters = {
		statuses: searchFilters?.statuses,
		companyIds: searchFilters?.companyIds,
	};

	if (!searchFilters?.companyIds && !searchFilters?.companyIds?.length > 0) {
		filters.companyIds = queryParameter?.customers || [];
	}
	if (!searchFilters?.statuses && !searchFilters?.statuses?.length > 0) {
		filters.statuses = queryParameter?.status || [];
	}
	if (!searchQuery) {
		search = queryParameter?.search;
	}

	return { statuses: filters.statuses, companyIds: filters.companyIds, search };
};

export const getInitialFilters = ({ queryParameter = null, searchParams }) => {
	return {
		page:
			+queryParameter?.page > 0
				? +queryParameter?.page - 1
				: 0 || +searchParams?.get("page") > 0
					? +searchParams?.get("page") - 1
					: 0,
		search: queryParameter
			? queryParameter?.search || ""
			: searchParams?.get("search") || "",
		status: queryParameter
			? queryParameter?.status || []
			: JSON.parse(searchParams?.get("status")) || [],
	};
};
