import { api } from "../api";
import modelUpdateConstants from "../constants/modelUpdateConstants";
import { getToken } from "../utils/local-storage.util";

/**
 * Get building job info for model update
 *
 * @param {number} buildingId - unique building id
 *
 */
export const getBuildingJobInfo = (buildingId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/building-job-info/${buildingId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(setTempModelUpdate(res.data?.result));
			},
			(err) => {
				console.log(err);
			}
		);
	};
};
//-------------------------STATE ACTIONS-------------------------------------

/**
 * UPDATE temp model building value
 *
 * @param {string} fieldKey - unique field key
 * @param {string} value - value of the field
 *
 */
export const updateTempModelBuilding = (fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: modelUpdateConstants.UPDATE_TEMP_MODEL_BUILDING,
			data: { fieldKey, value },
		});
	};
};

/**
 * SET temp model update (building data)
 *
 * @param {object} building - building data
 *
 */
export const setTempModelUpdate = (building) => {
	return (dispatch) => {
		dispatch({
			type: modelUpdateConstants.SET_TEMP_MODEL_UPDATE,
			data: building,
		});
	};
};

/**
 * UPDATE temp model exports
 *
 * @param {object} data - data to update
 *
 */
export const updateTempModelExports = (data) => {
	return (dispatch) => {
		dispatch({
			type: modelUpdateConstants.UPDATE_TEMP_MODEL_EXPORTS,
			data,
		});
	};
};

/**
 * Update stop building refetch value in state
 *
 * @param {string} value - value to update
 *
 */
export const updateStopBuildingRefetch = (value) => {
	return (dispatch) => {
		dispatch({
			type: modelUpdateConstants.UPDATE_STOP_BUILDING_REFETCH,
			data: value,
		});
	};
};

/**
 * CLEAR temp model update
 *
 */
export const clearTempModelUpdate = () => {
	return (dispatch) => {
		dispatch({
			type: modelUpdateConstants.CLEAR_TEMP_MODEL_UPDATE,
		});
	};
};

/**
 * SET temp model update files
 *
 * @param {Array} files - files data
 *
 */
export const setTempModelUpdateFiles = (files) => {
	return (dispatch) => {
		dispatch({
			type: modelUpdateConstants.SET_TEMP_MODEL_UPDATE_FILES,
			data: files,
		});
	};
};

/**
 * UPDATE file per level in model update
 *
 * @param {string} levelKey - unique level key
 * @param {string} fileId - unique file id
 * @param {number} disciplineId - unique discipline id
 * @returns
 */
export const setModelUpdateFilePerLevel = (levelKey, fileId, disciplineId) => {
	return (dispatch) => {
		dispatch({
			type: modelUpdateConstants.SET_MODEL_UPDATE_FILE_PER_LEVEL,
			data: { levelKey, fileId, disciplineId },
		});
	};
};

/**
 * SET model update file id
 *
 * @param {number} fileId - unique file id
 * @param {string} tempFileId - unique temp file id
 * @param {number} disciplineId - unique discipline id
 * @returns
 */
export const setModelUpdateFileId = (fileId, tempFileId, disciplineId) => {
	return (dispatch) => {
		dispatch({
			type: modelUpdateConstants.SET_MODEL_UPDATE_FILE_ID,
			data: { fileId, tempFileId, disciplineId },
		});
	};
};
