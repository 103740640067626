const modelUpdateConstants = {
	UPDATE_TEMP_MODEL_BUILDING: "UPDATE_TEMP_MODEL_BUILDING",
	SET_TEMP_MODEL_UPDATE: "SET_TEMP_MODEL_UPDATE",
	UPDATE_TEMP_MODEL_EXPORTS: "UPDATE_TEMP_MODEL_EXPORTS",
	UPDATE_STOP_BUILDING_REFETCH: "UPDATE_STOP_BUILDING_REFETCH",
	CLEAR_TEMP_MODEL_UPDATE: "CLEAR_TEMP_MODEL_UPDATE",
	SET_TEMP_MODEL_UPDATE_FILES: "SET_TEMP_MODEL_UPDATE_FILES",
	SET_MODEL_UPDATE_FILE_PER_LEVEL: "SET_MODEL_UPDATE_FILE_PER_LEVEL",
	SET_MODEL_UPDATE_FILE_ID: "SET_MODEL_UPDATE_FILE_ID",
};

export default modelUpdateConstants;
