import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import CustomLevels from "../../../../../../../shared/components/CustomLevels/CustomLevels";
import { Field } from "redux-form/immutable";
import CustomAutocomplete from "../../../../../../fields/CustomAutocomplete";
import localize, {
	LocKeys,
} from "../../../../../../../constants/localizations";
import { fromJS } from "immutable";
import { setModelUpdateFilePerLevel } from "../../../../../../../actions";

const FileUploadLevels = ({ disciplineId, ...props }) => {
	const {
		files,
		levels,
		initialValues,
		setModelUpdateFilePerLevel,
		wrapperClasses = "",
	} = props;

	const fileOptions = useMemo(() => {
		const tempFileOptions = [];
		if (files && files.size > 0) {
			files.map((file) => {
				let value = file.get("id");
				let label = file.get("name");

				return tempFileOptions.push({
					value,
					label,
				});
			});
		}
		return tempFileOptions;
	}, [files]);

	const setValue = useCallback(
		(key, value) => {
			setModelUpdateFilePerLevel(key, value, disciplineId);
		},
		[setModelUpdateFilePerLevel, disciplineId]
	);

	const renderSelectFileField = useCallback(
		(key) => {
			return (
				<Field
					name={key}
					id={key}
					size="w-100"
					showCloseIcon={true}
					disableClearable={false}
					component={CustomAutocomplete}
					placeholder={localize(LocKeys.CHOOSE_FILE) + "..."}
					showPlaceholder={true}
					initialValue={initialValues?.get(key) || null}
					options={fileOptions}
					isKeyStrigify={true}
					customOnChange={(value) => setValue(key, value)}
				/>
			);
		},
		[fileOptions, initialValues, setValue]
	);

	return (
		<CustomLevels
			formName={"buildingUpdateSpecificationForm_2"}
			levels={levels}
			hideRightSide={true}
			wrapperClassesLevel="px-25p"
			wrapperClasses={wrapperClasses}
			renderField={renderSelectFileField}
			buildingModelClasses="pt-48"
		/>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { disciplineId } = ownProps;
	const disciplineLevels = state.getIn([
		"modelUpdate",
		"tempModelUpdate",
		"disciplineLevels",
	]);
	let initialValues = {};

	const disciplineLevelsFormatted =
		disciplineLevels && disciplineLevels?.toJS();

	const singleDisciplineLevels =
		disciplineLevelsFormatted &&
		disciplineLevelsFormatted?.find(
			(disciplineLevel) => +disciplineLevel.discipline === +disciplineId
		);

	const levels = singleDisciplineLevels?.levels || [];

	levels &&
		levels.length > 0 &&
		levels.forEach((level) => {
			initialValues[level.key] = level.tempFileId;
		});

	return {
		levels,
		initialValues: fromJS(initialValues),
		files: state.getIn(["modelUpdate", "tempFiles"]),
	};
};

export default connect(mapStateToProps, { setModelUpdateFilePerLevel })(
	FileUploadLevels
);
