import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="18"
				height="16"
				viewBox="0 0 18 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9.03335 3.83333V0.5H0.700012V15.5H17.3667V3.83333H9.03335ZM4.03335 13.8333H2.36668V12.1667H4.03335V13.8333ZM4.03335 10.5H2.36668V8.83333H4.03335V10.5ZM4.03335 7.16667H2.36668V5.5H4.03335V7.16667ZM4.03335 3.83333H2.36668V2.16667H4.03335V3.83333ZM7.36668 13.8333H5.70001V12.1667H7.36668V13.8333ZM7.36668 10.5H5.70001V8.83333H7.36668V10.5ZM7.36668 7.16667H5.70001V5.5H7.36668V7.16667ZM7.36668 3.83333H5.70001V2.16667H7.36668V3.83333ZM15.7 13.8333H9.03335V12.1667H10.7V10.5H9.03335V8.83333H10.7V7.16667H9.03335V5.5H15.7V13.8333ZM14.0333 7.16667H12.3667V8.83333H14.0333V7.16667ZM14.0333 10.5H12.3667V12.1667H14.0333V10.5Z"
					fill="#7F909F"
				/>
			</svg>
		</span>
	);
};
