import { change, destroy } from "redux-form";
import orderTempConstants from "../constants/orderTempConstants";
import { removePricePerBuilding } from "./priceActions";
import { clearAllBuildingState } from "./buildingActions";
import { getItemFromLocalStorage } from "../utils/local-storage.util";

/**
 * Dynamic setting of project temp state parameters
 * @param {string} stateKey - used to assign the extracted value to the appt. temp state
 * @param {*} value - value to set the temp stateKey to
 * @param {number} buildingId
 */
export const setStateTempFieldValue = (
	stateKey,
	value,
	buildingId = null,
	isEdit = false
) => {
	return (dispatch) => {
		let isValid = false;

		switch (stateKey) {
			case "numberOfBuildings":
				isValid = +value >= 1;

				dispatch({
					type: orderTempConstants.UPDATE_BUILDING,
					data: value,
				});

				break;

			case "pricePerSquarePercentage":
				dispatch({
					type: orderTempConstants.UPDATE_PRICE_PER_SQUARE_PERCENTAGE,
					data: { value },
				});

				break;

			default:
				isValid = !!value;
				break;
		}

		let data = {
			stateKey,
			value,
			isValid,
			buildingId,
			isEdit,
		};

		if (buildingId) {
			dispatch({
				type: orderTempConstants.UPDATE_BUILDING_DATA,
				data,
			});
		} else {
			dispatch({
				type: orderTempConstants.SET_STATE_FIELD_VALUE,
				data,
			});
		}
	};
};

/**
 * Clear TEMP Offer object and destory FORM
 * @param {string} form
 * @returns
 */
export const clearTempProject = ({
	form = "createOrderForm",
	canClear = true,
	canSaveAdditionalInfo = false,
	clearBuildingState = false,
	clearCompanyState = false,
}) => {
	return (dispatch) => {
		dispatch(destroy(form));
		canClear &&
			dispatch({
				type: orderTempConstants.CLEAR_TEMP_PROJECT,
				data: { canSaveAdditionalInfo },
			});
		clearBuildingState && dispatch(clearAllBuildingState());
		clearCompanyState &&
			dispatch({
				type: orderTempConstants.CLEAR_COMPANY_STATE_VALUE,
			});
	};
};

/**
 * Remove building (TEMP Offer)
 * @param {*} buildingId
 * @returns
 */
export const removeBuilding = (buildingId) => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.REMOVE_BUILDING,
			data: { buildingId },
		});

		dispatch(removePricePerBuilding(buildingId));
	};
};

/**
 * Set initial number of buildings and update init (TEMP Offer)
 * @param {*} key
 * @param {*} value
 * @returns
 */
export const setInitNumberOfBuildings = (key, value) => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.UPDATE_BUILDING,
			data: value,
		});
		dispatch({
			type: orderTempConstants.SET_INIT_NUMBER_OF_BUILDINGS,
			data: { key, value },
		});
	};
};

/**
 * Set Exports versions (TEMP OFFER)
 * @param {*} data
 * @returns
 */
export const setOfferExports = (data) => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.SET_EXPORTS,
			data: data,
		});
	};
};

/**
 * UPDATE price on create and edit offer buildings
 *
 * @param {*} data - { buildingPrice, exportsPrice }
 * @returns
 */
export const setPrice = (data) => {
	return (dispatch, getState) => {
		const state = getState();
		const { buildingPrice, exportsPrice } = data;

		const exports = state.getIn(["projectTemp", "exports"]);
		const buildings = state.getIn(["projectTemp", "buildings"]);

		buildings &&
			buildings.forEach((building) => {
				dispatch(
					change(
						"editOfferInfoForm",
						`price_${building.id}`,
						buildingPrice?.pricePerBuildingDetailed[building.id]?.buildingPrice
					)
				);
				dispatch(
					change(
						"editOfferInfoForm",
						`pricePerSquare_${building.id}`,
						buildingPrice?.pricePerBuildingDetailed[building.id]?.pricePerSquare
					)
				);
			});

		exports &&
			exports.forEach((item) => {
				dispatch(
					change(
						"editOfferInfoForm",
						`exportPrice_${item.exportId}`,
						exportsPrice?.pricePerExport[item.exportId]
					)
				);
			});

		dispatch({
			type: orderTempConstants.UPDATE_PRICE,
			data: data,
		});
	};
};

export const setBuildingPrice = (data) => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.UPDATE_BUILDING_PRICE,
			data: data,
		});
	};
};

/**
 * Set TEMP Terms And Conditions
 * @param {*} data
 * @returns
 */
export const setTempTermsAndConditions = (data) => {
	return (dispatch) => {
		dispatch({ type: orderTempConstants.SET_TEMP_TERMS_AND_CONDITIONS, data });
	};
};

/**
 * Set TEMP Additional Information
 * @param {*} data
 * @returns
 */
export const setTempAdditionalInfo = (data) => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.SET_TEMP_ADDITIONAL_INFO,
			data,
		});
	};
};

/**
 * Update export price percentage
 * @param {number} value - price percentage
 * @returns
 */
export const updateExportPricePercentage = (value) => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.UPDATE_EXPORT_PRICE_PERCENTAGE,
			data: { value },
		});
		dispatch({
			type: orderTempConstants.SET_STATE_FIELD_VALUE,
			data: { stateKey: "exportPricePercentage", isValid: true, value },
		});
	};
};

export const setCompanyTempFieldValue = (stateKey, value) => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.SET_COMPANY_STATE_FIELD_VALUE,
			data: { stateKey, value },
		});
	};
};

export const setStateFormPackageConfiguration = () => {
	return (dispatch) => {
		const data = JSON.parse(
			getItemFromLocalStorage("packageConfiguration") || "{}"
		);

		dispatch({
			type: orderTempConstants.SET_STATE_FROM_PACKAGE_CONFIGURATION,
			data,
		});
	};
};

//---------------------RESET STATE---------------------

export const resetSummaryView = () => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.RESET_SUMMARY_VIEW,
		});
	};
};

export const resetOrderView = () => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.RESET_ORDER_VIEW,
		});
	};
};

export const resetModelsView = () => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.RESET_MODELS_VIEW,
		});
	};
};

export const resetExportsView = () => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.RESET_EXPORTS_VIEW,
		});
	};
};

export const resetSummaryPriceView = () => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.RESET_SUMMARY_PRICE_VIEW,
		});
	};
};

export const resetBIMSpecificationView = () => {
	return (dispatch) => {
		dispatch({
			type: orderTempConstants.RESET_BIM_SPECIFICATION_VIEW,
		});
	};
};
