import React, { useCallback } from "react";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import BuildingInfoEdit from "../../edit/specification-view/BuildingInfoEdit";
import { canEditJob } from "../parts/canEdit";
import SectionEdit from "../../../../../layout/section/SectionEdit";
import SectionView from "../../../../../layout/section/SectionView";
import { connect } from "react-redux";
import ComponentTooltip from "../../../../../../shared/components/component-tooltip/ComponentTooltip";
import IconWrapper from "../../../../../../shared/icons/IconWrapper";
import {
	PRIORITY_USER_ROLE,
	sizeConstants,
} from "../../../../../../constants/constants";
import QuestionIcon from "../../../../../../shared/icons/misc/QuestionIcon";
import { getInputFormatIcon } from "../../../../../../utils/getTypeIcon";
import {
	renderNumberFormat,
	squareAppend,
} from "../../../../../../utils/number-formatter/NumberFormatter";
import { Link } from "react-router-dom-v5-compat";
import { checkUserPriority } from "../../../../../../utils/permission/user";

const BuildingInfo = ({ isModelHistory = false, data }) => {
	const [editView, setEditView] = React.useState(false);
	const canEdit = canEditJob();
	const rolePriority = checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD);

	const renderBuildingName = useCallback(() => {
		if (rolePriority) {
			return (
				<Link className="no-decoration" to={`/buildings/${data.buildingId}`}>
					<div className="text-overflow-ellipsis">{data.name}</div>
				</Link>
			);
		}
		return <div className="text-overflow-ellipsis">{data.name}</div>;
	}, [rolePriority, data.buildingId, data.name]);

	if (editView) {
		return (
			<SectionEdit title={localize(LocKeys.BUILDING_INFO)}>
				<BuildingInfoEdit
					isModelHistory={isModelHistory}
					onClose={() => setEditView(!editView)}
				/>
			</SectionEdit>
		);
	}

	return (
		<SectionView
			title={localize(LocKeys.BUILDING_INFO)}
			canEdit={canEdit}
			handleEdit={() => setEditView(!editView)}
		>
			<div className="border-b-1 border-n150">
				<div className="row row--6p-gutters py-m">
					<div className="col col-20">
						<h5 className="small color-n300">
							{localize(LocKeys.BUILDING_NAME)}
						</h5>
					</div>
					<div className="col col-20">
						<h5 className="small color-n300">
							{localize(LocKeys.BUILDING_TYPE)}
						</h5>
					</div>
					<div className="col col-15">
						<h5 className="small color-n300">
							{localize(LocKeys.INPUT_FORMAT)}
						</h5>
					</div>
					<div className="col col-15 justify-content-end">
						<h5 className="small color-n300">{localize(LocKeys.SIZE)}</h5>
					</div>
					<div className="col col-15 justify-content-end">
						<h5 className="small color-n300  pr-xs">
							{localize(LocKeys.LEVELS)}
						</h5>
					</div>
					<div className="col col-15 justify-content-end">
						<ComponentTooltip
							content={localize(LocKeys.UNIQUE_LEVELS_EXPLANATION)}
							direction={"left"}
						>
							<div className="d-flex align-items-start justify-content-end">
								<h5
									className={`text-overflow-ellipsis  small color-n300 mr-xs`}
								>
									{localize(LocKeys.UNIQUE_LEVELS)}
								</h5>

								<IconWrapper
									size={sizeConstants.M}
									icon={
										<QuestionIcon iconClass="d-flex icon--target-fill fill--n300" />
									}
								/>
							</div>
						</ComponentTooltip>
					</div>
				</div>
			</div>

			<div className="border-b-1 border-n150">
				<div className="row row--6p-gutters py-regular  align-items-center color-n301">
					<div className="col  col-20 align-items-center color-n500">
						<ComponentTooltip content={data.name} direction={"top"}>
							{renderBuildingName()}
						</ComponentTooltip>
					</div>
					<div className="col col-20">{data.type}</div>
					<div className="col col-15 align-items-center">
						{getInputFormatIcon(data.parentInputFormat)}
						<span className="ml-s">{data.inputFormat}</span>
					</div>
					<div className="col col-15 justify-content-end">
						<div className="d-flex align-items-center">
							{renderNumberFormat(data.size)}
							{squareAppend()}
						</div>
					</div>
					<div className="col col-15 justify-content-end">
						<div className="pr-xs">
							{data.levelsTotal ? data.levelsTotal : "-"}
						</div>
					</div>
					<div className="col col-15 justify-content-end">
						<div className="pr-xs">
							{data.uniqueLevels ? data.uniqueLevels : "-"}
						</div>
					</div>
				</div>
			</div>
		</SectionView>
	);
};

const mapStateToProps = (state) => {
	//const { step = 1 } = ownProps;

	const data = {
		buildingId: state.getIn(["job", `job_${1}`, "building", "id"]),
		name: state.getIn(["job", `job_${1}`, "building", "name"]),
		size: state.getIn(["job", `job_${1}`, "building", "size"]),
		type: state.getIn(["job", `job_${1}`, "building", "type", "type"]),
		levelsTotal: state.getIn(["job", `job_${1}`, "building", "levelsTotal"]),
		uniqueLevels: state.getIn(["job", `job_${1}`, "building", "uniqueLevels"]),
		inputFormat:
			state.getIn([
				"job",
				`job_${1}`,
				"jobInputFormats",
				0,
				"inputFormat",
				"type",
			]) || null,
		parentInputFormat:
			state.getIn([
				"job",
				`job_${1}`,
				"jobInputFormats",
				0,
				"inputFormat",
				"parent",
				"type",
			]) || null,
	};
	return {
		data,
	};
};

export default connect(mapStateToProps, {})(BuildingInfo);
