import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form/immutable";
import { getExports } from "../../../actions";
import CustomSelect from "../../../components/fields/CustomSelect";
import renderTextField from "../../../components/fields/renderTextField";
import {
	adornmentTypeConstants,
	exportsConstants,
	generalConstants,
} from "../../../constants/constants";
import localize, { LocKeys } from "../../../constants/localizations";
import { renderPrice } from "../../../components/orders/single-view/view/SpecificationView";
import FacadesIcon from "../../icons/exports/FacadesIcon";
import IFCIcon from "../../icons/exports/IFCIcon";
import PlansIcon from "../../icons/exports/PlansIcon";
import SectionIcon from "../../icons/exports/SectionIcon";
import IconWrapper from "../../icons/IconWrapper";
import PlaceholderIcon from "../../icons/misc/PlaceholderIcon";
import SelectionCheckmark from "../SelectionCheckmark";
import { tokenParams } from "../../../utils/local-storage.util";
import { isBimify } from "../../../utils/permission/user";

export const getExportImage = (type, isSelected = true) => {
	switch (type) {
		case exportsConstants.IFC:
			return (
				<IFCIcon
					iconClass={`d-flex icon--target-fill ${
						isSelected ? "fill--n500" : "fill--n300"
					}`}
				/>
			);

		case exportsConstants.FACADES:
			return (
				<FacadesIcon
					iconClass={`d-flex ${
						isSelected ? "" : "icon--target-fill fill--n300"
					}`}
				/>
			);

		case exportsConstants.PLAN_VIEWS:
			return (
				<PlansIcon
					iconClass={`d-flex ${
						isSelected ? "" : "icon--target-fill fill--n300"
					}`}
				/>
			);

		case exportsConstants.SECTIONS:
			return (
				<SectionIcon
					iconClass={`d-flex ${
						isSelected ? "" : "icon--target-fill fill--n300"
					}`}
				/>
			);

		default:
			return (
				<PlaceholderIcon
					iconClass={`d-flex ${
						isSelected ? "" : "icon--target-fill fill--n300"
					}`}
				/>
			);
	}
};

const ExportsComponent = (props) => {
	const {
		isForPublicView = false,
		showPriceInformation = false,
		hideHeader = false,
		formType = generalConstants.VIEW,
		formName,
		priceLoader = false,
		initialValues,
		type,
		hidePrice = false,
		updateSelected,
		initialSelected,
		totalExportsPrice = 0,
		totalPrice,
		numberOfBuildings,
		// Actions
		getExports,
		updateExportPricePercentage = null,
		// From state
		exports,
	} = props;

	const shouldEstimatePrice = isForPublicView
		? false
		: tokenParams()?.company?.shouldEstimatePrice;
	const [selected, setSelected] = useState(
		initialSelected ? initialSelected : []
	);

	const isEdit = isForPublicView
		? false
		: formType === generalConstants.EDIT && isBimify();
	const isSingleSelect = type === generalConstants.SINGLE;

	useEffect(() => {
		setSelected(initialSelected ? initialSelected : []);
	}, [initialSelected]);

	useEffect(() => {
		getExports();
	}, [getExports]);

	const toggleSelected = (id, isSelected, isIFC = false, firstVersion) => {
		let tempSelected = [];

		tempSelected = selected.includes(id)
			? selected.filter((selected) => selected !== id)
			: isSingleSelect
				? [id]
				: [...selected, id];

		setSelected(tempSelected);

		updateSelected &&
			updateSelected({ id, exportId: id, isSelected: !isSelected });

		if (isSelected) {
			props.dispatch(change(formName, `exportPrice_${id}`, ""));
		} else {
			if (isIFC) toggleSelectedVersion(id, firstVersion.value);
		}
	};

	const toggleSelectedVersion = (id, exportId) => {
		updateSelected && updateSelected({ id, exportId, isSelected: true });
	};

	const updatePrice = (id, price) => {
		updateSelected &&
			updateSelected({ id, isSelected: true, price, fieldKey: "price" });
	};

	const renderExportPriceInformation = useCallback(
		(price) => {
			if (showPriceInformation)
				return (
					<div className="color-n300">
						&nbsp; (+ {price}
						&nbsp; &euro;/
						{localize(LocKeys.BUILDING)?.toLowerCase()})
					</div>
				);

			return <></>;
		},
		[showPriceInformation]
	);

	return (
		<div>
			{!hideHeader && (
				<div className="border-b-1 border-n150">
					<div className="row row--6p-gutters  align-items-center pb-s pt-m">
						<div className="col col-87-5">
							<h5 className="pl-72 small color-n300">
								{localize(LocKeys.EXPORT)}
							</h5>
						</div>
						<div className="col col-12-5 justify-content-end">
							{!hidePrice && (
								<h5 className="small color-n300">{localize(LocKeys.PRICE)}</h5>
							)}
						</div>
					</div>
				</div>
			)}
			{exports &&
				exports.map((exportItem) => {
					let isSelected = selected.includes(exportItem.get("id"));
					const isIFC = exportItem.get("type") === exportsConstants.IFC;

					const options = [];
					exportItem.get("children") &&
						exportItem.get("children").map((children) => {
							return options.push({
								value: children.get("id"),
								label: children.get("type"),
							});
						});

					const fieldKey = `exportVersion_${exportItem.get("id")}`;

					return (
						<div key={exportItem.get("id")} className="border-b-1 border-n150">
							<div className="row row--6p-gutters  align-items-center justify-content-end py-m">
								<div className="col col-90 align-items-center">
									<button
										className="mr-m btn btn--stripped"
										type="button"
										onClick={() =>
											toggleSelected(
												exportItem.get("id"),
												isSelected,
												isIFC,
												options[0]
											)
										}
									>
										<SelectionCheckmark
											selected={isSelected}
											type={generalConstants.MULTI}
										/>
									</button>
									<div className="mr-m">
										<IconWrapper
											size={24}
											icon={getExportImage(exportItem.get("type"), isSelected)}
										/>
									</div>
									<div
										className={`d-flex align-items-center ${isSelected ? "" : "color-n300"}`}
									>
										{exportItem.get("type")}
										{renderExportPriceInformation(exportItem.get("price"))}
									</div>
									{isIFC && (
										<div className="ml-30 w-20">
											<Field
												name={fieldKey}
												id={fieldKey}
												initialValue={
													isSelected ? initialValues?.get(fieldKey) || "" : ""
												}
												component={CustomSelect}
												customOnChange={(value) =>
													value &&
													toggleSelectedVersion(exportItem.get("id"), value)
												}
												disabled={!isSelected}
												size="w-100"
												placeholder={localize(LocKeys.SELECT_VERSION)}
												showPlaceholder={true}
												options={options}
											/>
										</div>
									)}
								</div>

								<div className="col col-10">
									{!hidePrice ? (
										priceLoader ? (
											<div className="w-100 d-flex justify-content-end">
												<span className="loading-icon"></span>
											</div>
										) : isEdit ? (
											<Field
												name={`exportPrice_${exportItem.get("id")}`}
												id={`exportPrice_${exportItem.get("id")}`}
												component={renderTextField}
												size="w-100"
												disabled={!isSelected}
												type="number"
												adornmentType={adornmentTypeConstants.EURO}
												onKeyDown={(value) =>
													updatePrice(exportItem.get("id"), value)
												}
												min="0"
												placeholder={localize(LocKeys.EMPTY_PLACHOLDER)}
												required={true}
											/>
										) : (
											<div className="w-100 d-flex justify-content-end">
												{renderPrice(
													isSelected && shouldEstimatePrice
														? +numberOfBuildings * +exportItem.get("price")
														: null,
													"color-n300"
												)}
											</div>
										)
									) : (
										""
									)}
								</div>
							</div>
						</div>
					);
				})}
			{!hidePrice && isEdit && (
				<div className="row row--6p-gutters justify-content-end py-m">
					<div className={`col col-20 justify-content-end  align-items-center`}>
						<div className="color-n300 body-font--xs pr-6">
							{localize(LocKeys.PRICE_ADJUSTMENT_LABEL)}
						</div>
						<Field
							name={`exportPricePercentage`}
							id={`exportPricePercentage`}
							component={renderTextField}
							size="w-50"
							classField="pl-6"
							type="number"
							debounce={true}
							adornmentType={adornmentTypeConstants.PERCENTAGE}
							onKeyDown={(value) => {
								updateExportPricePercentage(value);
							}}
							min="0"
							placeholder={localize(LocKeys.EMPTY_PLACHOLDER)}
							required={false}
						/>
					</div>
				</div>
			)}
			{!hidePrice && (
				<div className="row row--6p-gutters align-items-start py-m">
					<div className={`col col-75 color-n500`}>
						<h4>{localize(LocKeys.TOTAL)}</h4>
					</div>

					<div className={`col col-25 justify-content-end`}>
						{priceLoader.loader ? (
							<span className="loading-icon"></span>
						) : (
							<h4>
								{renderPrice(
									shouldEstimatePrice
										? isEdit
											? totalPrice
											: totalExportsPrice
										: null,
									"color-n500"
								)}
							</h4>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const tempBuildings = state.getIn(["projectTemp", "buildings"]);
	let numberOfBuildings =
		tempBuildings && tempBuildings.length > 0 ? tempBuildings.length : 0;

	return {
		tempBuildings: tempBuildings,
		numberOfBuildings: +numberOfBuildings,
		projectTemp: state.getIn(["projectTemp"]),
		buildingTypes: state.getIn(["administration", "types"]),
		exports: state.getIn(["administration", "exports"]),
		totalOfferPrice: state.getIn(["price", "totalOfferPrice"]),
		totalExportsPrice: state.getIn(["price", "exportsPrice", "total"]),
		form: ownProps.formName,
	};
};

export default connect(mapStateToProps, {
	getExports,
})(
	reduxForm({
		touchOnBlur: false,
		destroyOnUnmount: false,
		enableReinitialize: true,
	})(ExportsComponent)
);
