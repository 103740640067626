import {
	PRIORITY_USER_ROLE,
	companyTypesConstants,
	userRolesConstants,
} from "../../../constants/constants";
import userPermission from "../../../constants/permission/user";
import { tokenParams } from "../../local-storage.util";

const userCan = (permission, userId) => {
	const user = tokenParams();
	const permissions = user.permissions.user;
	const canWriteUser = userPermission.CAN_WRITE_USER;

	if (user.isBimifyAdmin || user.id === userId) {
		return true;
	}

	let can;
	switch (permission) {
		case canWriteUser:
			can = permissions[canWriteUser].includes(userId);
			break;
		default:
			can = false;
			break;
	}
	return can;
};

export const isBimify = () => {
	let loggedUser = tokenParams();

	return loggedUser.company?.type === companyTypesConstants.BIMIFY;
};

export const isBimifyReseller = () => {
	let loggedUser = tokenParams();

	return (
		loggedUser.company?.type === companyTypesConstants.BIMIFY ||
		loggedUser.company?.type === companyTypesConstants.RESELLER
	);
};

export const isReseller = () => {
	let loggedUser = tokenParams();

	return loggedUser.company?.type === companyTypesConstants.RESELLER;
};

export const isClient = () => {
	let loggedUser = tokenParams();

	return loggedUser.company.type === companyTypesConstants.CLIENT;
};

export const isBimifyAdmin = () => {
	let loggedUser = tokenParams();

	return loggedUser?.isBimifyAdmin;
};

export const isLoggedUserBO = () => {
	let loggedUser = tokenParams();

	return loggedUser.role.type === userRolesConstants.BUSINESS_OWNER;
};

export const isLoggedUserPL = () => {
	let loggedUser = tokenParams();

	return loggedUser.role.type === userRolesConstants.PROJECT_LEAD;
};

export const isLoggedUserAdmin = () => {
	let loggedUser = tokenParams();

	return loggedUser.role.type === userRolesConstants.ADMIN;
};

export const isLoggedUserGuest = () => {
	let loggedUser = tokenParams();

	return loggedUser.role.type === userRolesConstants.GUEST;
};

export const isLoggedUserStudent = () => {
	let loggedUser = tokenParams();

	return loggedUser.role.type === userRolesConstants.STUDENT;
};

export const isLoggedUserNormalizer = () => {
	let loggedUser = tokenParams();

	return loggedUser?.role?.type === userRolesConstants.NORMALIZER;
};

export const isLoggedUserCustomizer = () => {
	let loggedUser = tokenParams();

	return loggedUser?.role?.type === userRolesConstants.CUSTOMIZER;
};
export const isLoggedUserQA = () => {
	let loggedUser = tokenParams();

	return loggedUser?.role?.type === userRolesConstants.QUALITY_CONTROL;
};

export const returnLoggedUserId = () => {
	return tokenParams().id;
};

export const returnLoggedUserComopanyId = () => {
	return tokenParams()?.company?.id;
};

export const checkUserPriority = (priority = PRIORITY_USER_ROLE.ADMIN) => {
	let priorityRole = 0;
	let loggedUser = tokenParams();

	switch (loggedUser?.role?.type) {
		case userRolesConstants.ADMIN:
			priorityRole = PRIORITY_USER_ROLE.ADMIN;
			break;
		case userRolesConstants.BUSINESS_OWNER:
			priorityRole = PRIORITY_USER_ROLE.BUSINESS_OWNER;
			break;
		case userRolesConstants.PROJECT_LEAD:
			priorityRole = PRIORITY_USER_ROLE.PROJECT_LEAD;
			break;
		case userRolesConstants.NORMALIZER:
			priorityRole = PRIORITY_USER_ROLE.NORMALIZER;
			break;
		case userRolesConstants.CUSTOMIZER:
			priorityRole = PRIORITY_USER_ROLE.CUSTOMIZER;
			break;
		case userRolesConstants.QUALITY_CONTROL:
			priorityRole = PRIORITY_USER_ROLE.NORMALIZER;
			break;
		case userRolesConstants.STUDENT:
			priorityRole = PRIORITY_USER_ROLE.STUDENT;
			break;
		case userRolesConstants.GUEST:
			priorityRole = PRIORITY_USER_ROLE.GUEST;
			break;
		default:
			priorityRole = 100;
			break;
	}

	return priorityRole <= priority;
};

export default userCan;
