import React from "react";

/**
 * Custom component for page layout - left (content) and righ side (chat)
 *
 * 	1. Header Layout
 *  2. Body Layout
 *  3. Footer Layout
 *
 * @param {*} children
 * @param {string} wrapperClasses - classes for contnet
 * @param {boolean} showContentLayout - true/false - default value true
 * @param {boolean} hideChat - true/false - default value false
 * @returns
 */
export const ContentLayout = ({
	children,
	wrapperClasses = "page-content",
	wrapperContentClass = "row row--6p-gutters",
	showContentLayout = true,
	hideChat = true,
}) => {
	return showContentLayout ? (
		<div className={wrapperContentClass}>
			<div
				className={`col col-100 page-content ${
					!hideChat ? "col-desktop-75 mb-24 mb-desktop-0" : ""
				}   `}
			>
				<div className={`w-100 d-flex  h-100  ${wrapperClasses}`}>
					{children}
				</div>
			</div>
			{/* {!hideChat && (
				<div className="col col-100 col-desktop-25 page-content">
					<div className="w-100 card card--2 bg-n000 d-flex flex-column  flex-auto w-100 h-100 ">
						<Chat />
					</div>
				</div>
			)} */}
		</div>
	) : (
		children
	);
};
