import React from "react";
import EditButton from "../../../../shared/components/buttons/EditButton";

/**
 * Custom section header - offer view
 * @param {*} param0
 * @returns
 */
const SectionHeader = ({
	title,
	wrapperClasses = "",
	isEdit = false,
	handleEdit = () => {},
}) => {
	return (
		<div className={`d-flex align-items-center ${wrapperClasses}`}>
			<h4 className={`color-n350`}>{title}</h4>

			{!!isEdit && (
				<EditButton wrapperClasses="ml-auto" onClick={() => handleEdit()} />
			)}
		</div>
	);
};

export default SectionHeader;
