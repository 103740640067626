import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
	getForgePublicToken,
	getModelTranslationStatus,
	updateModelThumbnail,
} from "../../../../../actions";
import { alertTypeConstants } from "../../../../../constants/constants";
import localize, { LocKeys } from "../../../../../constants/localizations";

// const { Autodesk } = window;

// const runtime = {
// 	options: null,
// 	ready: null,
// };

// function initializeViewerRuntime(options) {
// 	if (!runtime.ready) {
// 		runtime.options = { ...options };

// 		runtime.ready = new Promise((resolve) =>
// 			Autodesk.Viewing.Initializer(runtime.options, resolve)
// 		);
// 	} else {
// 		if (
// 			["accessToken", "getAccessToken", "env", "api", "language"].some(
// 				(prop) => options[prop] !== runtime.options[prop]
// 			)
// 		) {
// 			return Promise.reject(
// 				"Cannot initialize another viewer runtime with different settings."
// 			);
// 		}
// 	}
// 	return runtime.ready;
// }

/**
 * FORGE viewer - AUTODESK FORGE
 * @param {*} props
 * @returns
 */
const RevitForgeViewer = (props) => {
	const {
		forgeAuth,
		viewerUrn,
		//actions
		getForgePublicToken,
		getModelTranslationStatus,
	} = props;

	//const forgeAuth = props.forgeAuth && props.forgeAuth;
	let Autodesk = window.Autodesk;
	const myViewer = React.useRef();
	const viewer = React.useRef();
	// let options = {
	// 	env: "AutodeskProduction",
	// 	api: "derivativeV2_EU", // TODO: for models uploaded to EMEA change this option to 'derivativeV2_EU'
	// 	accessToken: getForgeToken,
	// };

	const options = useMemo(() => {
		if (forgeAuth?.access_token)
			return {
				env: "AutodeskProduction",
				api: "derivativeV2_EU", // TODO: for models uploaded to EMEA change this option to 'derivativeV2_EU'
				accessToken: forgeAuth.access_token,
			};

		return null;
	}, [forgeAuth]);
	//let documentId = "urn:" + viewerUrn;

	const get3DModel = useCallback(
		(htmlElement) => {
			if (viewerUrn && forgeAuth?.access_token) {
				getModelTranslationStatus(viewerUrn, forgeAuth?.access_token)
					.then((res) => {
						if (res.status === alertTypeConstants.SUCCESS) {
							//	updateModelThumbnail(viewerUrn, buildingId, deliverableId);
							// Create and start the viewer in that element

							try {
								viewer.current = new Autodesk.Viewing.GuiViewer3D(htmlElement);
								viewer.current.start();
								Autodesk.Viewing.Document.load(
									"urn:" + viewerUrn,
									onDocumentLoadSuccess,
									onDocumentLoadFailure
								);
							} catch (error) {
								console.log({ error });
							}
						} else if (res.status === alertTypeConstants.FAILED) {
							myViewer.current.innerHTML = ` 
					<div  class="viewer--forge__message text-center">
						<p>${localize(LocKeys.FAILED_MODEL_MESSAGE)}.</p>
					</div>
				    `;
						} else {
							myViewer.current.innerHTML = ` 
					<div  class="viewer--forge__message text-center">
						<span class="loading-icon mx-auto"></span>  
						<p>${localize(LocKeys.TRANSLATING_MODEL)}.</p>
					</div>
				    `;
							setTimeout(() => {
								get3DModel(htmlElement);
							}, 5000);
						}
					})
					.catch((err) => {
						try {
							onDocumentLoadFailure(err);
						} catch (error) {
							console.log({ error });
						}
					});
			}
		},
		[
			Autodesk.Viewing.Document,
			Autodesk.Viewing.GuiViewer3D,
			forgeAuth,
			getModelTranslationStatus,
			viewerUrn,
		]
	);

	useEffect(() => {
		getForgePublicToken();
	}, [getForgePublicToken]);

	useEffect(() => {
		if (options) {
			try {
				Autodesk.Viewing.Initializer(options || {}, function () {
					var htmlElement = myViewer.current;
					if (htmlElement) {
						get3DModel(htmlElement);
					}
				}).catch((err) => console.error(err));
			} catch (error) {
				console.log({ error });
			}
		}

		return () => {
			if (viewer.current) {
				console.log("Unloading viewer...");
				viewer.current.tearDown();
				viewer.current.finish();
				viewer.current = null;
			}
		};
	}, [viewerUrn, options, Autodesk.Viewing, get3DModel]);
	// Run this when the page is loaded

	/**
	 * Autodesk.Viewing.Document.load() success callback.
	 * Proceeds with model initialization.
	 */
	function onDocumentLoadSuccess(doc) {
		// Load the default viewable geometry into the viewer.
		// Using the doc, we have access to the root BubbleNode,
		// which references the root node of a graph that wraps each object from the Manifest JSON.

		var viewable = doc.getRoot().getDefaultGeometry();
		if (viewable) {
			viewer.current
				?.loadDocumentNode(doc, viewable)
				.then(function (result) {
					console.log("Viewable Loaded!");
				})
				.catch(function (err) {
					console.log("Viewable failed to load.");
					console.log(err);
				});
		}
	}

	/**
	 * Autodesk.Viewing.Document.load() failure callback.
	 */
	function onDocumentLoadFailure(viewerErrorCode) {
		console.error("onDocumentLoadFailure() - errorCode: " + viewerErrorCode);
		myViewer.current.innerHTML = `<p  class="viewer--forge__message" >Translation in progress... Error!</p>`;
	}

	// Get public access token for read only
	// function getForgeToken(callback) {
	// 	forgeAuth && callback(forgeAuth.access_token, forgeAuth.expires_in);
	// }

	return <div className="viewer--forge" ref={myViewer}></div>;
};

const MapStateToProps = (state) => {
	return {
		forgeAuth: state.getIn(["building", "building", "forgeAuth"]),
	};
};

export default connect(MapStateToProps, {
	getForgePublicToken,
	getModelTranslationStatus,
	updateModelThumbnail,
})(RevitForgeViewer);
