import React from "react";
import BodyLayout from "../../../../../layout/content/BodyLayout";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import SectionView from "../../../../../layout/section/SectionView";
import TypeComponent from "./body/TypeComponent";
import ExportModelComponent from "./body/ExportModelComponent";

const ModelUpdateBody = () => {
	return (
		<BodyLayout wrapperClasses="pl-48 pr-24 pb-48">
			<SectionView wrapperClasses="" title={localize(LocKeys.TYPE)} />

			<TypeComponent />

			<SectionView wrapperClasses="" title={localize(LocKeys.EXPORTS)} />

			<ExportModelComponent />
		</BodyLayout>
	);
};

export default ModelUpdateBody;
