import React, { useEffect } from "react";
import SingleSpecification from "./SingleSpecification";
import CustomAccordion from "../../../../../shared/CustomAccordion/CustomAccordion";
import { Typography } from "@mui/material";
import CustomSkeleton from "../../../../../shared/components/skeleton/CustomSkeleton";
import {
	getBuildingSpecification,
	getDisciplines,
	getJobSpecification,
} from "../../../../../actions";
import { connect } from "react-redux";
import "../../singleOrder.scss";

const SingleSpecificationAccordion = ({
	jobId = null,
	buildingId = null,
	...props
}) => {
	const {
		specification,
		//Actions
		getBuildingSpecification,
		getDisciplines,
		getJobSpecification,
	} = props;

	useEffect(() => {
		getDisciplines({});
	}, [getDisciplines]);

	useEffect(() => {
		if (buildingId) {
			getBuildingSpecification({ id: buildingId });
		}
		if (jobId) {
			getJobSpecification({ id: jobId });
		}
	}, [buildingId, jobId, getBuildingSpecification, getJobSpecification]);

	const renderTitle = (name) => {
		return <div className="py-xs">{name}</div>;
	};

	return specification ? (
		<div className="border-b-1 border-n150  single-order__specification">
			<CustomAccordion
				wrapperClasses={`accordion card  card--border--none bg-n000  `}
				titleContent={renderTitle(specification?.get("name"))}
			>
				<div className={`w-100`}>
					<SingleSpecification
						isForOffer={true}
						disciplineComponentCategoryLods={
							specification?.toJS()?.buildingDisciplineComponentCategoryLods ||
							null
						}
					/>
				</div>
			</CustomAccordion>
		</div>
	) : (
		<>
			<div className="border-b-1 border-n150  single-order__specification">
				<Typography style={{ width: "100%" }} variant="h4">
					<CustomSkeleton />
				</Typography>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		specification: state.getIn(["building", "specification"]),
	};
};

export default connect(mapStateToProps, {
	getBuildingSpecification,
	getDisciplines,
	getJobSpecification,
})(SingleSpecificationAccordion);
